import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from '../../../shared/component/navbar/navbar.component';
import { FooterNavbarComponent } from '../../../shared/component/footer-navbar/footer-navbar.component';
import { NamesectionComponent } from '../../../shared/component/nameSection/namesection.component';
import { ActivatedRoute } from '@angular/router';
import { ProgressTrackerService } from '../../../shared/services/api/progress-tracker.service';
import { ToastService } from '../../../shared/services/alert/toast.service';

/* Imports */
import am5index from '@amcharts/amcharts5/index';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import { count } from 'node:console';
import { AnyARecord } from 'node:dns';
import { Obj } from '@popperjs/core';

// pipe Imports
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HindiNumberPipe } from '../../../shared/pipe/hindi-number.pipe';
import { SpinnerComponent } from '../../../shared/component/spinner/spinner.component';
import { consumerPollProducersForChange } from '@angular/core/primitives/signals';
import { hsvToHsl } from '@amcharts/amcharts5/.internal/core/util/Utils';

interface categories {
  cat_id: number;
  catShortName: string;
  catName: string;
  // [key: string]: string | number; // Add an index signature for dynamic properties
}

@Component({
  selector: 'app-progress-tracker',
  standalone: true,
  imports: [
    CommonModule,
    NavbarComponent,
    FooterNavbarComponent,
    NamesectionComponent,
    HindiNumberPipe,
    TranslateModule,
  ],
  providers: [ProgressTrackerService],
  templateUrl: './progress-tracker.component.html',
  styleUrl: './progress-tracker.component.css',
})
export class ProgressTrackerComponent {
  private route = inject(ActivatedRoute);
  private progressService = inject(ProgressTrackerService);
  private translate = inject(TranslateService);
  private toastService = inject(ToastService);

  ClassroomDropdownExpanded = false;
  selectedClass: string | null = null;

  navbarDisplay: string = 'none';

  class_id: number = 0;
  student_id: number = 0;
  studentName: string = '';
  age: string = '';
  params: any;
  results: any;
  profilePhoto: string = '';
  isCategoriesExpanded: boolean = false;
  categoryWise: string = '';
  previousClassID: any;
  categoryData: Object[] = [];
  noResult: boolean = true;
  changeClass: boolean = false;

  classes = [];
  classWiseResults: { [key: string]: any } = {};
  classResults : Array<Object> = [];

  // chart variables
  private root: am5.Root | undefined;
  private catRoot: am5.Root | undefined;
  chart: any;
  wholeData: any;
  chartType: string = 'mainChart';

  colors: string[] = [
    '#D9B061',
    '#557373',
    '#90A869',
    '#D88B79',
    '#E589AF',
    '#8E89E5',
    '#79C7DC',
    '#A28078',
    '#99C1B9',
  ];

  categories: categories[] = [
    {
      cat_id: 15, // use 15 for preprod, and 8 for local
      catShortName: 'PD',
      catName: this.translate.instant('Physical Development'),
    },
    {
      cat_id: 16, // use 16 for preprod, and 7 for local
      catShortName: this.translate.instant('CL'),
      catName: this.translate.instant('Communication and Language'),
    },
    {
      cat_id: 17, // use 17 for preprod, and 9 for local
      catShortName: this.translate.instant('SD'),
      catName: this.translate.instant('Socio-Emotional Development'),
    },
    {
      cat_id: 18, // use 18 for preprod, and 10 for local
      catShortName: this.translate.instant('PS'),
      catName: this.translate.instant('Problem Solving'),
    },
    {
      cat_id: 19, // use 19 for preprod, and 11 for local
      catShortName: this.translate.instant('GK'),
      catName: this.translate.instant('General Knowledge'),
    },
    {
      cat_id: 20, // use 20 for preprod, and 12 for local
      catShortName: this.translate.instant('AC'),
      catName: this.translate.instant('Arts and Creativity'),
    }
  ];

  constructor() {
    this.params = this.route.snapshot.queryParams;
    this.class_id = Number(this.route.snapshot.paramMap.get('class_id'));
    console.log('current Class id is : ', this.class_id);
    this.student_id = Number(this.route.snapshot.paramMap.get('student_id'));
    this.studentName = this.params.studentName;
    this.age = this.params.age;
    this.profilePhoto = this.params.profilePhoto;

    // this.categoryWise = this.translate.instant('progressTracker.categories');

  }

  ngOnInit() {
    // console.log('ON init start !');
    this.getProgressResults();
  }

  toggleClassroomDropdown() {
    this.ClassroomDropdownExpanded = !this.ClassroomDropdownExpanded;
  }

  getProgressResults() {
    // console.log('inside getProgressResults !');
    this.progressService
      .getProgressTrackerResults(this.student_id, this.class_id)
      .subscribe((data: any) => {
        console.log('whole mainChart data ', data.data); // last record in data.data is the latest age-group result of the student.
        this.wholeData = data.data;
        this.classes = data.data.classes;
        this.classWiseResults = data.data.classWiseResults;
        let currentClass = this.classes.find(
          classroom => classroom['class_id'] === this.class_id
        );
        if (currentClass) {
          this.selectClass(currentClass['className']); // by default initializing progress tracker with the current class.
        } else {
          this.selectClass('No Class');
        }
      });
  }

  selectClass(selectedClass: string) {
    this.selectedClass = selectedClass;
    this.ClassroomDropdownExpanded = false;

    this.classResults = Object.values(this.classWiseResults[selectedClass][0]);
    let previousClass = this.classes.find(
      classroom => classroom['className'] === selectedClass
    );
    if (previousClass) this.previousClassID = previousClass['class_id'];
    console.log('this.previous class id : ', this.previousClassID);


    // translating mainChartData in respective language.
    this.classResults.forEach((obj: any) =>{
      obj.catName = this.translate.instant(obj.catName);
      obj.catShortName = this.translate.instant(obj.catShortName);
    });

    console.log("class reuslt in select class being send to createChart(): ",this.classResults);

    this.createMainChart(this.classResults);

    //reseting the categoryChart on class change
    if (this.catRoot) {
      // only disposing if previous exists.
      if (this.catRoot.dom.id === 'categoryChart') {
        console.log(' disposing previous categoryChart root');
        this.catRoot.dispose();
        this.catRoot = undefined; // making sure that the previous root is completely cleared
      }
      this.categoryWise = '';
    }
  }

  toggleDropdown() {
    this.isCategoriesExpanded = !this.isCategoriesExpanded;
  }

  categoryTracker(selectedCategoryID: number, classID: number) {
    console.log('selectedCategory', selectedCategoryID);
    console.log('Class ID in CategoryTracker is : ', classID);
    this.isCategoriesExpanded = !this.isCategoriesExpanded;
    this.chartType = 'categoryChart';

    // getting the subcategory results
    this.progressService
      .getProgressTrackerCategorywise(
        this.student_id,
        classID,
        selectedCategoryID
      )
      .subscribe((data: any) => {
        console.log('subcategory progress tracker results : ', data.data);
        this.categoryData = Object.values(data.data);
        if (this.categoryData.length !== 0) {
          // results are available
          this.noResult = false;
          let catName = this.categories.find(
            cat => cat.cat_id === selectedCategoryID
          );
          if (catName) this.categoryWise = catName.catName;
          console.log('data available !!!');


          // translating the data into respective language before sending to the graph function.
          this.categoryData.forEach((obj:any)=>{
            console.log(obj);
            obj.subCatName = this.translate.instant(obj.subCatName);
          });

          console.log("data being sent to Category Tracker : ",this.categoryData);
          this.createCategoryChart(this.categoryData);
        } else {
          console.log('NO data !!');
          this.noResult = true;
          this.toastService.showAlert(
            this.translate.instant(
              `Report not available. Please complete at least one asessment.`
            ),
            this.translate.instant('Note')
          );
        }
      });
  }

  createMainChart(chartData: Array<Object>) {
    // console.log('data in createMainChart : ', chartData);
    // console.log("Previous mainChart Root is : ",this.root);

    if (this.root) {
      // only disposing if previous exists.
      if(this.root.dom.id === 'mainChart' || this.root.dom.id === 'categoryChart'){
        this.root.dispose();
        console.log('previous Chart Disposed and made undefined!!!');
      }
    }

    console.log("root id before creating new root : ",this.root?.dom.id);
    this.root = am5.Root.new('mainChart');

    this.root.setThemes([am5themes_Animated.new(this.root)]);

    if (this.root._logo) {
      this.root._logo.dispose();
    }

    let chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: false, // graph zoom
        panY: false, // graph zoom
        wheelX: 'panX',
        wheelY: 'zoomX',
        layout: this.root.verticalLayout,
        pinchZoomX: true,
      })
    );

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xRenderer = am5xy.AxisRendererX.new(this.root, {
      minorGridEnabled: true,
    });
    xRenderer.grid.template.set('location', 0);
    xRenderer.labels.template.setAll({
      location: 0.5,
      multiLocation: 0.5,
    });

    // setting data on xAxis
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        categoryField: 'catShortName',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(this.root, {}),
        snapTooltip: true,
      })
    );

    xRenderer.labels.template.setAll({
      oversizedBehavior: 'truncate',
      textAlign: 'center',
    });

    xAxis.data.setAll(chartData);

    // testing
    xAxis.get('renderer').set('minGridDistance', 5); // Adjust the value as needed (default is 50)

    // Rotating the x axis subcat names so they don't overlap on each other
    // if (chartType !== 'mainChart') {
    //   xAxis.get('renderer').labels.template.setAll({
    //     rotation: -30,
    //     centerY: am5.p100,
    //     centerX: am5.p50,
    //   });
    // }

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        maxPrecision: 0,
        renderer: am5xy.AxisRendererY.new(this.root, {
          inversed: false,
        }),
      })
    );

    // Adding cursor
    let cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(this.root, {
        alwaysShow: false,
        xAxis: xAxis,
        // positionX: undefined,
      })
    );

    cursor.lineY.set('visible', false);
    cursor.lineX.set('focusable', false);

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

    function createSeries(root: am5.Root, name: string, color: string) {
      let series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name === 'null' ? "Term " : name, // this is the label name for the term , if no assesment period is assigned , then displaying "Term" by default.
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: name, //  this is the value of that term
          categoryXField: 'catShortName',
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: 'horizontal',
            labelText: '[bold]{name}[/]\n{catName}: {valueY}',
          }),
        })
      );

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 5,
            fill: series.get('fill'),
          }),
        });
      });

      // create hover state for series and for mainContainer, so that when series is hovered,
      // the state would be passed down to the strokes which are in mainContainer.
      series.set('setStateOnChildren', true);
      series.states.create('hover', {});

      series.mainContainer.set('setStateOnChildren', true);
      series.mainContainer.states.create('hover', {});

      series.strokes.template.states.create('hover', {
        strokeWidth: 4,
      });

      // console.log('chartData inside createseries: ',chartData);
      series.data.setAll(chartData);

      series.appear(1000);
      series.set('stroke', am5.color(color)); // line color
      series.set('fill', am5.color(color)); // point color
    } // createSeries()  -----end

    // creating series for chart
    // if (chartType === 'mainChart') {
    // console.log('type of createChart data : ', typeof chartData);

    let terms = Object.keys(chartData[0]);
    terms.splice(0, 3); // for not considering "catShortName" , "catName" and "catId" fields in term count. in case of categoryResult, not considering "subCatName" , "subCatId", "catId"
    // console.log('terms after splicing : ', terms);

    let i = 0;
    for (let term of terms) {
      // console.log('term name is : ', term);
      let color;
      if (this.colors.length === i) {
        // preventing array out of bounds error.
        color = this.colors[i - 1];
      } else {
        color = this.colors[i];
      }
      createSeries(this.root, term, color);
      i++;
    }

    // creating the series dynamically using loop
    // createSeries(this.root, seriesLabel, semester, color);

    // }
    // else {
    //   let noOfSem = Object.keys(chartData[0]).length - 2; // noOfSem means number of semesters, considering a single object. -2 is for not considering "subcatId" and "subcatName" field in semester count.
    //   console.log('number of semesters in createSeries =  ', noOfSem);

    //   // creating the series dynamically using loop
    //   for (let i = 0; i < noOfSem; i++) {
    //     let seriesLabel = `Sem ${i + 1}`;
    //     let semester = `sem${i + 1}`;
    //     let color;
    //     if (colors.length === i) {
    //       // preventing array out of bounds error.
    //       color = colors[i - 1];
    //     } else {
    //       color = colors[i];
    //     }
    //     createSeries(seriesLabel, semester, color);
    //   }
    // }

    // databar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/

    // zoom-in zoom-out scroll bar
    // chart.set(
    //   'scrollbarX',
    //   am5.Scrollbar.new(this.root, {
    //     orientation: 'horizontal',
    //     width : 260,
    //     marginBottom: 20,
    //   })
    // );

    // setting the range of result
    yAxis.setAll({
      min: 0,
      max: 100,
    });

    // legend is nothing but index for the graph
    let legend = chart.children.push(
      am5.Legend.new(this.root, {
        centerX: am5.percent(0),
        x: am5.percent(5),
        useDefaultMarker: true,
        marginTop: 10,
      })
    );

    // Make series change state when legend item is hovered
    legend.itemContainers.template.states.create('hover', {});

    // setting legends / Index
    legend.data.setAll(chart.series.values); // default index

    legend.markers.template.setAll({
      width: 25,
      height: 18,
    });
    legend.markerRectangles.template.setAll({
      cornerRadiusTL: 5, // TL = top left
      cornerRadiusTR: 5,
      cornerRadiusBL: 5, // BL = bottom left
      cornerRadiusBR: 5,
    });

    // chart.plotContainer.events.on('pointerout', function () {
    //   cursor.set('positionX', 1);
    // });

    chart.plotContainer.events.on('pointerover', function () {
      cursor.set('positionX', undefined);
    });

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);
  }

  // function to create categoryChart

  createCategoryChart(chartData: Array<Object>) {
    console.log('data in createCategoryChart : ', chartData);
    // console.log('chartType in create chart : ', chartType);

    // disposing previous root for the categoryChart
    if (this.catRoot) {
      // only disposing if previous exists.
      if (this.catRoot.dom.id === 'categoryChart') {
        console.log(' disposing previous categoryChart root');
        this.catRoot.dispose();
        this.catRoot = undefined; // making sure that the previous root is completely cleared
      }
    }

    this.catRoot = am5.Root.new('categoryChart');

    this.catRoot.setThemes([am5themes_Animated.new(this.catRoot)]);

    if (this.catRoot._logo) {
      this.catRoot._logo.dispose();
    }

    let chart = this.catRoot.container.children.push(
      am5xy.XYChart.new(this.catRoot, {
        panX: false, // graph zoom
        panY: false, // graph zoom
        wheelX: 'panX',
        wheelY: 'zoomX',
        layout: this.catRoot.verticalLayout,
        pinchZoomX: true,
      })
    );

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xRenderer = am5xy.AxisRendererX.new(this.catRoot, {
      minorGridEnabled: true,
    });
    xRenderer.grid.template.set('location', 0);
    xRenderer.labels.template.setAll({
      location: 0.5,
      multiLocation: 0.5,
    });

    // setting data on xAxis
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(this.catRoot, {
        categoryField: 'subCatName',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(this.catRoot, {}),
        snapTooltip: true,
      })
    );

    xRenderer.labels.template.setAll({
      oversizedBehavior: 'truncate',
      textAlign: 'center',
    });

    xAxis.data.setAll(chartData);

    // testing
    xAxis.get('renderer').set('minGridDistance', 5); // Adjust the value as needed (default is 50)

    // Rotating the x axis subcat names so they don't overlap on each other
    xAxis.get('renderer').labels.template.setAll({
      rotation: -30,
      centerY: am5.p100,
      centerX: am5.p50,
    });

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(this.catRoot, {
        maxPrecision: 0,
        renderer: am5xy.AxisRendererY.new(this.catRoot, {
          inversed: false,
        }),
      })
    );

    // Adding cursor
    let cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(this.catRoot, {
        alwaysShow: false,
        xAxis: xAxis,
        // positionX: undefined,
      })
    );

    cursor.lineY.set('visible', false);
    cursor.lineX.set('focusable', false);

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

    function createSeries(root: am5.Root, name: string, color: string) {
      let series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name === 'null' ? "Term " : name, // this is the label name for the term , if no assesment period is assigned , then displaying "Term" by default.
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: name, //  this is the value of that term
          categoryXField: 'subCatName',
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: 'horizontal',
            labelText: '[bold]{name}[/]\n{subCatName}: {valueY}',
          }),
        })
      );

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 5,
            fill: series.get('fill'),
          }),
        });
      });

      // create hover state for series and for mainContainer, so that when series is hovered,
      // the state would be passed down to the strokes which are in mainContainer.
      series.set('setStateOnChildren', true);
      series.states.create('hover', {});

      series.mainContainer.set('setStateOnChildren', true);
      series.mainContainer.states.create('hover', {});

      series.strokes.template.states.create('hover', {
        strokeWidth: 4,
      });

      // console.log('chartData inside createseries: ',chartData);
      series.data.setAll(chartData);

      series.appear(1000);
      series.set('stroke', am5.color(color)); // line color
      series.set('fill', am5.color(color)); // point color
    } // createSeries()  -----end

    // creating series for chart
    let terms = Object.keys(chartData[0]);
    terms.splice(0, 3); // for not considering "catShortName" , "catName" and "catId" fields in term count. in case of categoryResult, not considering "subCatName" , "subCatId", "catId"
    // console.log('terms after splicing : ', terms);

    let i = 0;
    for (let term of terms) {
      console.log('term name is : ', term);
      let color;
      if (this.colors.length === i) {
        // preventing array out of bounds error.
        color = this.colors[i - 1];
      } else {
        color = this.colors[i];
      }
      createSeries(this.catRoot, term, color);
      i++;
    }

    // databar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/

    // zoom-in zoom-out scroll bar
    // chart.set(
    //   'scrollbarX',
    //   am5.Scrollbar.new(this.catRoot, {
    //     orientation: 'horizontal',
    //     width : 260,
    //     marginBottom: 20,
    //   })
    // );

    // setting the range of result
    yAxis.setAll({
      min: 0,
      max: 100,
    });

    // legend is nothing but index for the graph
    let legend = chart.children.push(
      am5.Legend.new(this.catRoot, {
        centerX: am5.percent(0),
        x: am5.percent(5),
        useDefaultMarker: true,
        marginTop: 10,
      })
    );

    // Make series change state when legend item is hovered
    legend.itemContainers.template.states.create('hover', {});

    // setting legends / Index
    legend.data.setAll(chart.series.values); // default index

    legend.markers.template.setAll({
      width: 25,
      height: 18,
    });
    legend.markerRectangles.template.setAll({
      cornerRadiusTL: 5, // TL = top left
      cornerRadiusTR: 5,
      cornerRadiusBL: 5, // BL = bottom left
      cornerRadiusBR: 5,
    });

    // chart.plotContainer.events.on('pointerout', function () {
    //   cursor.set('positionX', 1);
    // });

    chart.plotContainer.events.on('pointerover', function () {
      cursor.set('positionX', undefined);
    });

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);
  }
}
