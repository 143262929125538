import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../../config/api/api.service';
import { PARENTS } from '../../../../config/constant';
@Injectable({
  providedIn: 'root',
})
export class ParentsService {
  private apiService = inject(ApiService);

  url: string = 'http://localhost:8080';
  token: string | null = '';

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  /**
   * This function gets all the parents details from the server
   * @param class_id
   * @returns
   */
  getParentsByClassId(class_id: number): Observable<object> {
    return this.apiService.get(
      `${PARENTS.GET_PARENT_BY_CLASS_ID.URL}/${class_id}`
    );
  }

  /**
   * Used to change the school connect status
   * @param childId
   * @param status
   * @param userId
   * @returns
   */
  sendSchoolConnect(
    childId: number,
    status: string,
    userId: number
  ): Observable<object> {
    // fires updateSchoolConnectStatus api in backend
    return this.apiService.post(`${PARENTS.PUT_UPDATE_SCHOOL_CONNECT.URL}`, {
      childId: childId,
      userId: userId,
      status: status,
    });
  }


  checkResultSharing(student_id :number , user_id :number, class_id:number, assessment_period_id:number):Observable<object>{
    // console.log("link in parent service : ",`${PARENTS.CHECK_RESULT_SHARING.URL}/${student_id}/${user_id}/${class_id}/${assessment_period_id}`)
    return this.apiService.get(
      `${PARENTS.CHECK_RESULT_SHARING.URL}/${student_id}/${user_id}/${class_id}/${assessment_period_id}`
    );   
  }

  toggleResultSharing(student_id :number , user_id :number, class_id:number, assessment_period_id:number):Observable<object>{
    return this.apiService.put(`${PARENTS.TOGGLE_RESULT_SHARING.URL}`,{
      student_id: student_id,
      user_id: user_id,
      class_id: class_id,
      assessment_period_id: assessment_period_id
    });
  }
}
