export const ADMIN = {
  GET_OVERVIEW_DATA: {
    URL: 'overview/data',
  },
  GET_CLASSROOMS: {
    URL: 'class/get-classes-by-school-id',
  },
  GET_TEACHER_LIST: {
    URL: 'teacher/get-all-teachers',
  },
  GET_STUDENT_LIST: {
    URL: 'student/get-all-students-by-schoolId',
  },
};

export const SUPERADMIN = {
  GET_PROVIDERS_LIST: {
    URL: 'admin/sp/service-provider-list',
  },
  POST_UPDATE: {
    URL: 'admin/sp/update',
  },
  GET_DOMAINS: {
    URL: 'admin/nep/domains',
  },
  GET_DOMAIN_BY_ID : {
    URL : 'admin/nep/domains'
  },
  POST_ADD_DOMAINS: {
    URL: 'admin/nep/domains',
  },
  GET_CURRICULUM_GOALS: {
    URL: 'admin/nep/curriculum-goals/domain',
  },
  POST_CURRICULUM_GOALS: {
    URL: 'admin/nep/curriculum-goals',
  },
  GET_CURRICULUM_GOALS_BY_ID : {
    URL : 'admin/nep/curriculum-goals'
  },
  GET_COMPENTENCIES : {
    URL : "admin/nep/competencies"
  },
  GET_COMPENTENCIES_BY_CG : {
    URL : "admin/nep/competencies/goal"
  },
  POST_COMPENTENCIES :{
    URL : "admin/nep/competencies"
  },
  GET_LEARNING_OUTCOMES_BY_COMPENTENCY_ID : {
    URL : 'admin/nep/learning-outcomes/competency'
  },
  LEARNING_OUTCOME : {
    URL : 'admin/nep/learning-outcomes'
  }
};
