import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../../config/api/api.service';
import { USER } from '../../../../config/constant';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private apiService = inject(ApiService);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  // for getting the teacher details for the teacher profile.
  getTeacherInfoById(teacherId: number): Observable<object> {
    return this.apiService.get(
      `${USER.GET_TEACHER_INFO_BY_ID.URL}/${teacherId}`
    );
  }

  getSummaryReportByUserId(userId: number): Observable<object> {
    return this.apiService.get(`${USER.GET_SUMMARY_REPORT.URL}/${userId}`);
  }

  // for getting the parent's current plan details for the summary-report.
  checkParentPlan(userId: number): Observable<any> {
    return this.apiService.get(`${USER.GET_CHECK_PARENT_PLAN.URL}/${userId}`);
  }
}
