<div class="sectionBlock">
  <!-- <img src="../../../../assets/img/loginBg.jpg" class="bgImage md-none" alt="backgroundImage"> -->
  <div class="lang-selected-div">
    <select [value]="selectedLanguage" (change)="onLanguageChange($event)">
      <option value="en">English</option>
      <option value="mr">मराठी</option>
      <option value="hi">हिन्दी</option>
    </select>
  </div>
  
  <div class="container d-flex  align-items-center justify-content-center flex-column">

    <div class="BMC_logo">
      <img class="boostLogo" src="../../../../assets/img/BMC_R_Logo_v2_3.png" alt="logo">
    </div>

    <div class="saapLogo ">
      <img src="../../../../assets/img/samLogo.png" alt="saapLogo">
    </div>

    <form [formGroup]="loginForm" class="loginForm" (ngSubmit)="validateForm()">
      <div class="card w-100 h-100 flex-cent p-4 shadow rounded">
        
        <div class="userTypeButtons mb-4">
          <button type="button" class="btn-role button1" 
                  [ngClass]="{'active-role': selectedRole === 'teacher'}"
                  (click)="selectRole('teacher')">
            <span>Teacher</span>
          </button>
        
          <button type="button" class="btn-role button2" 
                  [ngClass]="{'active-role': selectedRole === 'admin'}"
                  (click)="selectRole('admin')">
            <span>Admin</span>
          </button>
        </div>
        
    
        <h2 class="text-center text-uppercase mt-2">{{ loginTitle | translate }}</h2>
    
        <div class="mt-3 w-100">
          <input type="email" id="userEmail" [placeholder]="'login.email' | translate" class="form-control form-control-lg"
            formControlName="email" required />
          <div *ngIf="loginForm.controls['email'].invalid && (loginForm.controls['email'].touched || isFormSubmitted)" class="invalid-feedback">
            <span *ngIf="loginForm.controls['email'].errors?.['required']">
              {{ 'login.requiredField' | translate }}
            </span>
            <span *ngIf="loginForm.controls['email'].errors?.['email']">
              {{ 'login.invalidEmail' | translate }}
            </span>
          </div>
        </div>
    
        <div class="mt-3 w-100">
          <input type="password" id="userPassword" [placeholder]="'login.password' | translate" class="form-control form-control-lg"
            formControlName="password" required />
          <div class="show-password mt-1">
            <input type="checkbox" (click)="togglePassword()">
            <p>{{ 'login.showPassword' | translate }}</p>
          </div>
          <div *ngIf="loginForm.controls['password'].invalid && (loginForm.controls['password'].touched || isFormSubmitted)" class="invalid-feedback">
            <span *ngIf="loginForm.controls['password'].errors?.['required']">
              {{ 'login.passwordRequired' | translate }}
            </span>
            <span *ngIf="loginForm.controls['password'].errors?.['minlength']">
              {{ 'login.minimumPassword' | translate }}
            </span>
          </div>
        </div>
    
        <button class="loginBtn btn btn-primary mt-3" type="submit">{{ 'login.login' | translate }}</button>
      </div>
    </form>
    
</div>
</div>