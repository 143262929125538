<div class="table-container">
  <div class="table-wrapper">
    <table>
      <thead>
        <tr>
          <th>Sr. No</th>
          @for (column of headerNames; track $index) {
            <th>{{ column }}</th>
          }
        </tr>
      </thead>
      <tbody>
        @for (row of data; track $index) {
          <tr (click)="onRowClick(row)">
            <td>{{ addPageSize + $index + 1 }}</td>
            @for (column of columns; track $index) {
              <td>{{ row?.[column] ? row[column] : '-' }}</td>
            }
          </tr>
        }
      </tbody>
    </table>
  </div>
</div>
