import { Component, inject } from '@angular/core';
import { DomainsService } from '../../../../../utils/api/nep/domains.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  FormGroupName,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-add-domains',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './add-domains.component.html',
  styleUrl: './add-domains.component.css',
})
export class AddDomainsComponent {
  private fb = inject(FormBuilder);
  private domainService = inject(DomainsService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  domain: any = { name: '', description: '' };
  domainForm!: FormGroup;
  domainId : any = 0;
  isEditMode = false;

 

  constructor() {}

  ngOnInit(): void {
    this.domainForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      shortName: [''],
      description: [''],
    });

    this.domainId = this.route.snapshot.paramMap.get('id');
    if (this.domainId) {
      this.isEditMode = true;
      this.domainService.getDomainById(this.domainId).subscribe(data => {
        if (data) {
          this.domainForm.patchValue(data);
        }
      });
    }
  }

  submitForm(): void {
    if (this.domainForm.invalid) return;

    const formData = this.domainForm.value;

    if (this.isEditMode && this.domainId) {
      this.domainService.updateDomain(this.domainId, formData).subscribe(() => {
        this.router.navigate(['/superadmin/domains']);
      });
    } else {
      this.domainService.createDomain(formData).subscribe(() => {
        this.router.navigate(['/superadmin/domains']);
      });
    }
  }
}
