<div class="profile-container">
    <div class="tab-header">
      <div class="left-side">
        <h2 class="title">Service Provider Profile</h2>
      </div>
      <div class="right-side">
        <button class="tab-button">Schools</button>
        <button class="tab-button">Milestones</button>
      </div>
    </div>
  
    <form [formGroup]="userForm" (ngSubmit)="updateRecord()" class="form-container">
      <div class="form-grid">
        <div class="form-group" *ngFor="let field of formFields">
          <label>{{ field.label }}</label>
          <ng-container [ngSwitch]="field.type">
            <input *ngSwitchCase="'text'" type="text" formControlName="{{ field.name }}" />
            <input *ngSwitchCase="'email'" type="email" formControlName="{{ field.name }}" />
            <input *ngSwitchCase="'number'" type="number" formControlName="{{ field.name }}" />
            <textarea *ngSwitchCase="'textarea'" formControlName="{{ field.name }}"></textarea>
            <select *ngSwitchCase="'select'" formControlName="{{ field.name }}">
              <option *ngFor="let option of field.options" [value]="option.value">
                {{ option.label }}
              </option>
            </select>
          </ng-container>
        </div>
      </div>
  
      <button type="submit" [disabled]="!userForm.valid" class="submit-button">Update</button>
    </form>
  </div>
  