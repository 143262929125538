<app-navbar [navbarDisplay]="navbarDisplay"></app-navbar>

<div class="parent-container black-font flex-cent flex-col">
  <app-namesection [studentName]="studentName" [profilePhoto]="profilePhoto" [age]="age"></app-namesection>

  <div class="header-container flex-cent space-between">
    <h2 class="progress-tracker-heading">{{'progressTracker.progressTracker' | translate}}</h2>
    <div class="mainActivity professional-blue-font">
      <div class="dropdown class-dropdown" (click)="toggleClassroomDropdown()">
        <p class="dropdown-default-text">{{ selectedClass }}</p>
        <div class="dropdown-arrow">
          <img src="../../../../assets/img/dropdown.png" alt="" [ngClass]="{'active': ClassroomDropdownExpanded}">
        </div>
      </div>
      @if (ClassroomDropdownExpanded) {
      <div class="expandable-classdropdown">
        @for (classroom of classes; track i; let i = $index) {
        <div (click)="selectClass(classroom['className'] )" [ngClass]="'dropdown-item dropdown-item-' + i">
          <p class="className">{{ classroom['className'] }}</p>
        </div>
        }
      </div>
      }
    </div>
  </div>

  <div class="tracker-container black-font flex-cent flex-col">
    <h4 class="title">{{'progressTracker.overallReport' | translate}}</h4>
    <div class="chartArea">
      <div id="mainChart" class="chartdiv"></div>
    </div>
  </div>

  <!-- Select Category Heading and Dropdown -->
  <div class="header-container">
    <h2 class="select-category-heading">{{'progressTracker.selectCategory' | translate}}</h2>
    <div class="mainActivity professional-blue-font">
      <div class="dropdown select-category" (click)="toggleDropdown()">
        <p class="dropdown-default-text">{{ categoryWise || 'progressTracker.categories' | translate }}</p>
        <div class="dropdown-arrow">
          <img src="../../../../assets/img/dropdown.png" alt="" [ngClass]="{'active': isCategoriesExpanded}">
        </div>
      </div>
      @if (isCategoriesExpanded) {
      <div class="expandable-dropdown">
        @for (category of categories; track category.cat_id; let i = $index) {
          @if(previousClassID !== 0 ){
            <div (click)="categoryTracker(category.cat_id , previousClassID)" class="dropdown-item">
              <p>{{ category.catName }}</p>
            </div>
          }@else{
            <div (click)="categoryTracker(category.cat_id , class_id)" class="dropdown-item">
              <p>{{ category.catName }}</p>
            </div>
          }
        }
      </div>
      }
    </div>
  </div>
  <!-- Category Progress Tracker -->
  <!-- @if(!noResult){ -->
  <div class="tracker-container flex-cent flex-col">

    <h4 class="title">{{categoryWise}}</h4>
    <div class="chartArea">
      <div id="categoryChart" class="categoryChart"></div>
    </div>
  </div>
  <!-- } -->

</div>

<app-footer-navbar [class_id]="class_id"></app-footer-navbar>