import { Component, ElementRef, inject,
  OnInit,
  ViewChild,} from '@angular/core';
import { HindiNumberPipe } from '../../../../shared/pipe/hindi-number.pipe';
import { NgClass } from '@angular/common';
import { NavbarComponent } from '../../../../shared/component/navbar/navbar.component';
import { SpinnerComponent } from '../../../../shared/component/spinner/spinner.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResultsService } from '../../../../shared/services/api/results.service';
import { GraphService } from '../../../../../service/graph/graph.service';
import { CoreUtilityService } from '../../../core/service/core-utility.service';
import { PortfolioPdfDownloadService } from '../../../../shared/services/api/portfolio-pdf-download.service';
import { FooterNavbarComponent } from "../../../../shared/component/footer-navbar/footer-navbar.component";

@Component({
  selector: 'app-nep-progress-card',
  standalone: true,
  providers: [HindiNumberPipe],
  imports: [NgClass, NavbarComponent, SpinnerComponent, TranslateModule, HindiNumberPipe, FooterNavbarComponent],
  templateUrl: './nep-progress-card.component.html',
  styleUrl: './nep-progress-card.component.css'
})
export class NEPProgressCardComponent implements OnInit {
  standard: any;
  division: any;

  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private resultservice = inject(ResultsService);
  private translate = inject(TranslateService);
  private graphService = inject(GraphService);
  private coreUtility = inject(CoreUtilityService);
  private portfolioDownloadService = inject(PortfolioPdfDownloadService);
  private hindiNumberPipe = inject(HindiNumberPipe);

  schoolName: string = 'Global International School';
  loader: boolean = false;
  none: string = 'none';
  age: string = '3 y& 4 m';
  studentName: string = 'studentName';
  profilePhotoLink: string = 'https://bmc-space.blr1.digitaloceanspaces.com/';
  profilePhoto: string = '';
  class: string = 'Nursery A';
  teacherName: string = 'Suresh Raut';
  birthDate: string = '13/12/2002';
  parentNames: string = 'kartik';
  catname: string = 'Physical Development';
  params: any;
  dob_date: number = 10;
  dob_month: number = 5;
  dob_year: number = 2019;
  classId: number = 2;
  studentId: number = 3325;
  targetedLang: string = 'en';
  catData: any = [];
  subCatData: any = [];
  userData: any = [];
  height: number | string = '--';
  weight: number | string = '--';
  downloadInProcess: boolean = false;
  isMasked: string = '';
  imageUrl : string = 'assets/img/butterfly3.png'
  @ViewChild('imageCanvas', { static: false }) canvas!: ElementRef<HTMLCanvasElement>;

  frameImageUrl = 'assets/img/profile_photo_border.png'; // The decorative frame image
  innerImageUrl = 'assets/img/studentProfile.png'; 
  profileImage: string = '';

  constructor(...args: unknown[]);
  constructor() {

    
    this.studentId = Number(this.route.snapshot.paramMap.get('student_id'))!;
    this.classId = Number(this.route.snapshot.paramMap.get('class_id'))!;
    console.log('class Id is : ', this.classId);

    this.params = this.route.snapshot.queryParams;
    console.log('params in porfolio frontend : ', this.params);

    this.studentName = this.params.studentName;
    this.standard = this.params.standard;
    this.division = this.params.division;
    this.teacherName = this.params.teacherName;
    this.profilePhoto = this.params.profilePhoto;
    console.log('profile photo in portfolio comp : ', this.profilePhoto);
    this.dob_date = Number(this.params.dob_date);
    this.dob_month = Number(this.params.dob_month);
    this.dob_year = Number(this.params.dob_year);
  }

  ngOnInit() {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.isMasked = window.localStorage.getItem('isMasked')!;

      this.targetedLang = localStorage.getItem('targetedLang') || 'en';
      this.resultservice
      .getPortFolio(
        this.classId,
        this.studentId,
        this.dob_month,
        this.dob_date,
        this.dob_year,
        this.targetedLang
      )
        .subscribe({
          next: (res: any) => {
            this.userData = res.data.user;
            console.log('userData : ', this.userData);
            this.catData = Object.keys(res.data.records).map(
              key => res.data.records[key]
            );
            this.loader = true;
            if(this.userData.profile_image){
              this.profileImage = `https://bmc-space.blr1.cdn.digitaloceanspaces.com/${this.userData.profile_image}` 
            }

            this.studentName = this.userData.child_last_name
              ? this.userData.child_name + ' ' + this.userData.child_last_name
              : this.userData.child_name;
            this.parentNames = this.userData.user_last_name
              ? this.userData.user_first_name +
                ' ' +
                this.userData.user_last_name
              : this.userData.user_first_name;

            this.birthDate = `${this.hindiNumberPipe.transform(Number(this.userData.child_born_date))}/${this.hindiNumberPipe.transform(Number(this.userData.child_born_month))}/${this.hindiNumberPipe.transform(Number(this.userData.child_born_year))}`;

            this.age = this.coreUtility.calculateAge(
              Number(this.userData.child_born_year),
              Number(this.userData.child_born_month),
              Number(this.userData.child_born_date)
            );

            setTimeout(() => {
              for (let obj of this.catData) {
                this.loader = true;

                obj.cat_img =
                  'https://bmc-space.blr1.cdn.digitaloceanspaces.com/uploads/asscategoryimages/' +
                  `${obj.category_id}/${obj.cat_img}`;

                const elementId = obj.category_id.toString();
                const element = document.getElementById(elementId);

                if (element) {
                  let donutColor: number = 0;
                  // assigning color to the donut chart
                  if (obj.result >= 0 && obj.result <= 25) {
                    donutColor = 0xfbaf55; // light orange
                  } else if (obj.result >= 26 && obj.result <= 65) {
                    donutColor = 0xfcf969; // yellow
                  } else if (obj.result >= 66 && obj.result <= 100) {
                    donutColor = 0xa9fa68; // green
                  }
                  this.graphService.createDonutChart(
                    elementId,
                    obj.result,
                    donutColor,
                    'noCenterLabel'
                  );
                } else {
                  console.error(
                    `Could not find HTML element with id '${elementId}'`
                  );
                }
              }
            });
          },
          error: error => {
            // Handle error
            console.error('Error:', error);
          },
        });
    }
  }

  getMonth(month : any){
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    
    if (month < 1 || month > 12) {
      return "Invalid month number";
    }
    
    return months[month - 1]; 
  }

  autoResize(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  downloadPortfolioPDF() {
    this.downloadInProcess = true;
    console.log('Teacher name: ', this.teacherName);
    this.portfolioDownloadService
      .downloadPortfolio(
        this.classId,
        this.studentId,
        this.dob_month,
        this.dob_date,
        this.dob_year,
        this.targetedLang
      )
      .subscribe({
        next: (res: Blob) => {
          console.log('Received file:', res);

          // Ensure it's a valid blob
          if (!res || res.size === 0) {
            console.error('Received an empty file.');
            return;
          }

          const fileName = `${this.userData.child_name}-portfolio.pdf`;

          // Create a URL for the Blob
          const url = window.URL.createObjectURL(res);

          // Create a temporary link element to trigger download
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName;

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // Cleanup
          window.URL.revokeObjectURL(url);

          this.downloadInProcess = false;
        },
        error: error => {
          console.error('Download error:', error);

          // Try to read JSON error response
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const errorMsg = JSON.parse(reader.result as string);
              console.error('Server error message:', errorMsg);
            } catch (e) {
              console.error('Unknown error while downloading file.');
            }
          };
          reader.readAsText(error.error);

          this.downloadInProcess = false;
        },
      });
  }

  getReMark(result: number, subCatName: string) {
    if (result <= 26) {
      return `${this.studentName} ${this.translate.instant('result.lagging')} ${subCatName} ${this.translate.instant('result.development')} `;
    } else if (result >= 27 && result <= 66) {
      return `${this.studentName} ${this.translate.instant('result.encouraged')} ${subCatName} ${this.translate.instant('result.development')}`;
    } else if (result >= 67) {
      return `${this.studentName} ${this.translate.instant('result.onTrack')} ${subCatName} ${this.translate.instant('result.development')}`;
    } else {
      return '';
    }
  }

  getParse(imagObj: string) {
    return JSON.parse(imagObj);
  }


  mergeImages() {
    const canvas = this.canvas.nativeElement;
    const ctx = canvas.getContext('2d');

    if (ctx) {
      const frameImg = new Image();
      const innerImg = new Image();

      // Load Frame Image
      frameImg.src = this.frameImageUrl;
      frameImg.onload = () => {
        // Set Canvas to Frame Size
        canvas.width = frameImg.width;
        canvas.height = frameImg.height;

        // Draw the Frame
        ctx.drawImage(frameImg, 0, 0, canvas.width, canvas.height);

        // Load Inner Image
        innerImg.src = this.innerImageUrl;
        innerImg.onload = () => {
          // Calculate Aspect Ratio for Inner Image
          const padding = 50; // Adjust padding as needed
          const innerWidth = canvas.width - padding * 2;
          const innerHeight = canvas.height - padding * 2;
          
          // Center the inner image
          const x = padding;
          const y = padding;

          // Draw Inner Image on Top
          ctx.drawImage(innerImg, x, y, innerWidth, innerHeight);
        };
      };
    }
  }

  getIconForStatus(status: string): string {
    switch (status) {
      case 'YES':
        return 'assets/img/butterfly3.png';
      case 'SOMETIMES':
        return 'assets/img/pupaa.png';
      case 'NO':
        return 'assets/img/caterpillar3.png';
      default:
        return 'assets/img/caterpillar3.png';
    }
  }
}
