<div id="teacherComponent">
    <section id="componentHeader" class="d-flex align-center just-start">
        <div id="componentTitle">
          <div>TEACHERS</div>
        </div>
        <div id="teacherName">
          <span>Welcome, </span><span>{{ userName }}</span>
        </div>
     
    </section>
    <div class="pagination-controls">
        <label for="pageSize" class="rowLabel">Rows per page:</label>
        <select id="pageSize" [formControl]="pageSizeControl">
          @for (size of pageSizes; track $index) {
            <option [value]="size">{{ size }}</option>
          }
        </select>
    </div>
    
    <table-component [columns]="columnKeys" [headerNames]="tableHeaders" [data]="paginatedTeachers"></table-component>
    
      
      <!-- Pagination Controls -->
      <div class="pagination-controls">
        <button (click)="goToPage(1)" [disabled]="currentPage === 1">First</button>
        <button (click)="goToPage(currentPage - 1)" [disabled]="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button (click)="goToPage(currentPage + 1)" [disabled]="currentPage === totalPages">Next</button>
        <button (click)="goToPage(totalPages)" [disabled]="currentPage === totalPages">Last</button>
      </div>

      <button (click)="AddTeacher()">ADD Teacher</button>
</div>
