<router-outlet>
    @if(showInstallButton){
    <div class="install-banner">
        <span>Install this app for a better experience.</span>
        <div class="buttons">
            <button (click)="onInstallPwa()">Install</button>
            <span (click)="cancel()">x</span>
        </div>
    </div>
    }
</router-outlet>
<app-alert></app-alert>
<app-toast></app-toast>