import { inject, Injectable } from '@angular/core';
import { ApiService } from '../../../../config/api/api.service';
import { Observable } from 'rxjs';
import { SUPERADMIN } from '../../../../config/adminConstant';

@Injectable({
  providedIn: 'root',
})
export class CompentenciesService {
  private apiService = inject(ApiService);
  constructor() {}

  getCompentenciesBYCGId(id: any): Observable<object> {
    return this.apiService.get(`${SUPERADMIN.GET_COMPENTENCIES_BY_CG.URL}/${id}`);
  }

  getCompentencyById(id: any): Observable<object> {
    return this.apiService.get(`${SUPERADMIN.GET_COMPENTENCIES.URL}/${id}`);
  }


  updatecompentency(id : any , data :any) :Observable<object>{
    return this.apiService.put(`${SUPERADMIN.GET_COMPENTENCIES.URL}/${id}`,data);
  }


  createCompentency(cgid :any ,data : any): Observable<object> {
    data.goal_id = cgid;
    return this.apiService.post(
      `${SUPERADMIN.POST_COMPENTENCIES.URL}`,
      data
    );
  }
}
