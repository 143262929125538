<div
  class="layout-container"
  [ngClass]="{ 'sidebar-collapsed': isSidebarCollapsed, 'mobile-sidebar-open': isMobileSidebarOpen }">
  <aside class="aside-sidebar">
    <app-sidebar
      [isSidebarCollapsed]="isSidebarCollapsed"
      (changeComponent)="onComponentChange($event)"
      (sidebarToggle)="toggleSidebar()">
    </app-sidebar>
  </aside>

  <main class="main-content">
    <!-- <horizontal-bar
      (changeComponent)="onComponentChange($event)"></horizontal-bar> -->
    <!-- <router-outlet></router-outlet> -->
    <horizontal-bar 
    (changeComponent)="onComponentChange($event)"
    (clickHamburgerMenu) ="showHamburgerMenu($event)">
    </horizontal-bar>
    <!-- This router is used to switch the components-->
    <router-outlet></router-outlet>
    <!-- @switch (selectedComponent) {
      @case ('classrooms') {
        <ng-container>
          <admin-classrooms></admin-classrooms>
        </ng-container>
      }
      @case ('milestones') {
        <ng-container>
          <admin-milestone></admin-milestone>
        </ng-container>
      }
      @case ('teachers') {
        <ng-container>
          <admin-teachers></admin-teachers>
        </ng-container>
      }
      @case ('students') {
        <ng-container>
          <admin-students></admin-students>
        </ng-container>
      }
      @case ('reminders') {
        <ng-container>
          <admin-reminders></admin-reminders>
        </ng-container>
      }
      @default {
        <ng-container>
          <admin-dashboard></admin-dashboard>
        </ng-container>
      }
    } -->
  </main>
</div>
