<app-navbar
  [teacherName]="teacherName"
  [standard]="standard"
  [division]="division"
  [totalStudents]="totalStudents"
  [navbarDisplay]="'NO'"></app-navbar>
<section id="compentency" class="d-flex just-around align-center flex-col">
  <app-namesection
    [studentName]="studentName"
    [profilePhoto]="profilePhoto"
    [age]="age"></app-namesection>
  <div class="flex-cent flex-col headdings">
    <span>{{ goalName }}</span>
    <span>Compentency-wise Results</span>
  </div>

  <div id="graphDiv">
    <div id="chartId"></div>
  </div>

  <div id="remarksCompentency">
    @for (obj of compentency; track $index; let i = $index) {
      <div class="card-container">
        <div class="card-header">
          <h5>
            {{ obj.short_form }}. {{ obj.cat_name }}
            <span class="info-icon" (click)="toggleDescription(i)">ⓘ</span>
            @if(visibleIndex === i){
                <app-popup 
                [visible]="visibleIndex !== null" 
                [description]="obj.short_form + ' ' +  obj.description || ''" 
                (closePopup)="toggleDescription(visibleIndex)">
              </app-popup>
              }
          </h5>
        </div>
        <div class="teacher-remarks">
          <span>Teacher Remarks:</span>
          <p>{{ obj.remarks }}</p>
        </div>
        <div class="image-slider">
          @for (image of obj.images; track $index) {
            <img [src]="image" alt="Eating Habit Image" />
          }
        </div>
      </div>
    }
  </div>
</section>


<app-footer-navbar [class_id]="classId"></app-footer-navbar>