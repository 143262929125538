import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { HorizontalBarComponent } from '../horizontal-bar/horizontal-bar.component';

import { RouterModule, RouterOutlet } from '@angular/router';
import { MenuItem } from '../interfaces';
@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    HorizontalBarComponent,
    SidebarComponent,
    CommonModule,
    RouterOutlet,
    RouterModule
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.css',
})
export class LayoutComponent {
 
  [x: string]: any;
  selectedComponent: string = '';
  isSidebarCollapsed = false;
  schoolName: string | undefined = '';
  isMobileSidebarOpen : boolean = false;

  // menuItems : MenuItem[] = [
  //   {
  //     icon: '../../../assets/img/dashboard-24.png',
  //     label: 'Dashboard',
  //     route: '/admin/dashboard',
  //   },
  //   {
  //     icon: '../../../assets/img/classroom-teacher-24.png',
  //     label: 'Classrooms',
  //     route: '/admin/classroom',
  //   },
  //   {
  //     icon: '../../../assets/img/icons8-talent-24.png',
  //     label: 'Milestones',
  //     route: '/admin/milestone',
  //   },
  //   {
  //     icon: '../../../assets/img/classroom-teacher-24.png',
  //     label: 'Teachers',
  //     route: '/admin/teacher',
  //   },
  //   {
  //     icon: '../../../assets/img/students-24.png',
  //     label: 'Students',
  //     route: '/admin/student',
  //   },
  //   {
  //     icon: '../../../assets/img/reminder-24.png',
  //     label: 'Reminders',
  //     route: '/admin/reminder',
  //   },
  //   // {
  //   //   icon: '../../../assets/img/settings-24.png',
  //   //   label: 'Settings',
  //   // },
  // ];

  constructor() {}

  ngOnInit(){
    // this.updateComponentFromHash();
  //   if (typeof window !== 'undefined' && window.localStorage) {

  //   // Listen to hash changes
  //   // window.addEventListener('hashchange', () => {
  //   //   this.updateComponentFromHash();
  //   // });
  // }
  }

  onComponentChange(component: string) {
    this.isMobileSidebarOpen = !this.isMobileSidebarOpen;

    this.selectedComponent = component.toLocaleLowerCase();
  }
  
  // onComponentChange(component: string) {
  //   this.selectedComponent = component.toLocaleLowerCase();
  // }

  toggleSidebar() {
    this.isSidebarCollapsed = !this.isSidebarCollapsed;
  }

  showHamburgerMenu(show: boolean) : void{
    console.log("show",show);
    this.isMobileSidebarOpen = show;
  }

  // private updateComponentFromHash() {
  //   if (typeof window !== 'undefined' && window.localStorage) {
  //   const hash = window.location.hash.replace('#', '');
  //   this.selectedComponent = hash || 'dashboard'; // Default to dashboard
  //   }
  // }

  // navigateTo(component: string): void {
  //   window.location.hash = component;
  // }
}
