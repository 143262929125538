import { inject, Injectable } from '@angular/core';
import { SUPERADMIN } from '../../../../config/adminConstant';
import { ApiService } from '../../../../config/api/api.service';
import { filterData } from '../../interfaces/common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpService {
  apiService = inject(ApiService);

  constructor() {}

  getProviderList(filterData: filterData) : Observable<object> {
    return this.apiService.post(
      `${SUPERADMIN.GET_PROVIDERS_LIST.URL}`,
      filterData
    );
  }

  updateProvider(updateData : any): Observable<object> {
    return this.apiService.post(
      `${SUPERADMIN.POST_UPDATE.URL}`,
      updateData
    );
  }
}
