import { Component, inject } from '@angular/core';
import { NavbarComponent } from '../../../shared/component/navbar/navbar.component';
import { FooterNavbarComponent } from '../../../shared/component/footer-navbar/footer-navbar.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HindiNumberPipe } from '../../../shared/pipe/hindi-number.pipe';
import { ParentsService } from '../../../shared/services/api/parents.service';
import { UploadPhotosComponent } from '../../../shared/component/upload-photos/upload-photos.component';
import { PortfolioPdfDownloadService } from '../../../shared/services/api/portfolio-pdf-download.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
@Component({
  selector: 'app-studentinfo',
  standalone: true,
  imports: [
    NavbarComponent,
    FooterNavbarComponent,
    TranslateModule,
    HindiNumberPipe,
    UploadPhotosComponent,
    ReactiveFormsModule,
  ],
  providers: [ParentsService, PortfolioPdfDownloadService],
  templateUrl: './studentinfo.component.html',
  styleUrl: './studentinfo.component.css',
})
export class StudentinfoComponent {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private translate = inject(TranslateService);
  private parentService = inject(ParentsService);
  private portfolioPdfDownloadService = inject(PortfolioPdfDownloadService);
  private formbuilder = inject(FormBuilder);

  studentForm!: FormGroup;
  goBackTo: string = 'classroom';
  navbarDisplay: string = 'none';
  params: any;
  class_id: number = 0;
  child_name: string = '';
  child_last_name: string = '';
  child_id: number = 0;
  dob_date: number = 0;
  dob_month: number = 0;
  dob_year: number = 0;
  height: number = 0;
  weight: number = 0;
  fav_color: string = '';
  fav_food: string = '';
  correspondance_address: string = '';
  permanent_address: string = '';
  friends: string = '';
  things_i_like: string = '';
  fav_bird: string = '';
  fav_animal: string = '';
  fav_flower: string = '';
  fav_outdoor_game: string = '';
  fav_indoor_game: string = '';
  family_photo: string = '';
  my_photo_drawing: string = '';
  family_photo_drawing: string = '';
  profile_image: string = '';
  maxPhotoCount: number = 1;
  parent_first_name: string = '';
  parent_last_name: string = '';
  parent_email: string = '';
  parent_contact: string = '';
  user_id: number = 0;
  studentName: string = '';
  studentLastName: string = '';
  purpose: string = '';
  studentId: number = 0;
  assessment_period_id: number = 0;
  catId: number = 0;
  subcatId: number = 0;
  sessionSummaryParams: string = '';
  subcatName: string = '';
  mode: string = 'summary';
  heightValue: number = 0;
  heightUnit: string = 'cm';
  weightValue: number = 0;
  imageLink: string = 'https://bmc-space.blr1.digitaloceanspaces.com/';
  isEnableResults: boolean = false;
  resultSharingStatus = {};
  openUploadWindow: boolean = false;
  familyPhotoUrl: string = ''; // To store image preview URL
  myPhotoDrawingUrl: string = '';
  familyPhotoDrawingUrl: string = '';
  childAge: string = '';
  // testing
  language: string = '';

  constructor() {
    this.class_id = Number(this.route.snapshot.paramMap.get('class_id'));
    this.params = this.route.snapshot.queryParams;
    console.log('params recieved in studentInfo screen', this.params);
    if (typeof window !== 'undefined' && window.localStorage) {
      this.assessment_period_id = Number(
        localStorage.getItem('assessmentPeriodId')
      );
    }

    this.child_id = this.params.child_id;
    this.child_name = this.params.child_name;
    this.child_last_name = this.params.child_last_name;

    if (this.child_last_name || this.child_last_name === 'null') {
      this.child_name = this.child_name + this.child_last_name;
    }
    this.dob_date = Number(this.params.dob_date);
    this.dob_month = Number(this.params.dob_month);
    this.dob_year = Number(this.params.dob_year);
    this.profile_image = this.params.profile_image;

    if (!this.parent_contact || this.parent_contact.trim() === '') {
      this.parent_contact = '--';
    }

    console.log(
      ' born date in student information',
      this.dob_date,
      this.dob_month,
      this.dob_year,
      this.profile_image
    );
  }

  ngOnInit() {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.language = window.localStorage.getItem('targetedLang')!;
      this.route.queryParams.subscribe(params => {
        console.log('Received Query Params:', params);

        this.child_id = this.route.snapshot.queryParams['child_id'];
        this.class_id = this.route.snapshot.queryParams['class_id'];
        this.child_name = this.route.snapshot.queryParams['child_name'];
        this.profile_image = this.route.snapshot.queryParams['profile_image'];
        // this.profile_image = params['profile _image'] || '';

        console.log('Child Name:', this.child_name);
        console.log('Child ID:', this.child_id);
        console.log('Child ID:', this.child_id);
        console.log('DOB:', this.dob_year, this.dob_month, this.dob_date);
        console.log('Profile Image:', this.imageLink + this.profile_image);
      });

      this.initializeForm();
      console.log('Student Form Initialized:', this.studentForm.value);

      if (this.child_id) {
        this.fetchChildDetails();
      }
    }
  }

  initializeForm() {
    this.studentForm = this.formbuilder.group({
      dob: [''],
      height: [''],
      heightUnit: ['cm'],
      weight: [''],
      fav_color: [''],
      fav_food: [''],
      fav_animal: [''],
      fav_bird: [''],
      fav_flower: [''],
      fav_outdoor_game: [''],
      fav_indoor_game: [''],
      family_photo: [null],
      my_photo_drawing: [null],
      family_photo_drawing: [null],
    });
  }
  fetchChildDetails() {
    this.portfolioPdfDownloadService
      .getStudentDetails(this.child_id)
      .subscribe({
        next: (response: any) => {
          // console.log("API Response for Child Details:", response.data.data );
          if (!response || !response.data) {
            console.error('No data received');
            return;
          }

          if (response.data) {
            const childData = response.data.data;
            this.studentForm.patchValue({
              dob:
                this.formatDateToISO(
                  childData.dob_year,
                  childData.dob_month,
                  childData.dob_date
                ) || '',
              height: childData.height,
              weight: childData.weight,
              fav_color: childData.fav_color || '',
              fav_food: childData.fav_food || '',
              fav_animal: childData.fav_animal || '',
              fav_bird: childData.fav_bird || '',
              fav_flower: childData.fav_flower || '',
              fav_outdoor_game: childData.fav_outdoor_game || '',
              fav_indoor_game: childData.fav_indoor_game || '',
            });

            this.parent_first_name = childData.parent_first_name;
            this.parent_last_name = childData.parent_last_name;
            this.parent_email = childData.parent_email;
            this.parent_contact = childData.parent_contact;
            this.familyPhotoUrl = childData.family_photo || '';
            this.myPhotoDrawingUrl = childData.my_photo_drawing || '';
            this.familyPhotoDrawingUrl = childData.family_photo_drawing || '';

            console.log('Updated Form Values:', this.studentForm.value);
            // Call calculateAge and store in childAge property
            this.childAge = this.calculateAge(
              childData.dob_year,
              childData.dob_month,
              childData.dob_date
            );
            console.log('Child Age:', this.childAge);
          }
        },

        error: error => {
          console.error('Error fetching child data:', error);
        },
      });
  }

  formatDateToISO(year: number, month: number, date: number): string {
    if (!year || !month || !date) return '';

    // Use UTC date to avoid time zone shifts
    const formattedDate = new Date(Date.UTC(year, month - 1, date));
    return formattedDate.toISOString().split('T')[0];
  }

  updateChildDetails() {
    console.log('🚀 updateChildDetails() function triggered!');

    if (this.studentForm.invalid) return;

    console.log('Updating Child Details for child_id:', this.child_id);
    console.log('Form Data:', this.studentForm.value);
    this.portfolioPdfDownloadService
      .updateStudentDetails(this.child_id, this.studentForm.value)
      .subscribe({
        next: (response: any) => {
          console.log('Child information updated:', response);
          this.fetchChildDetails();
        },
        error: error => {
          console.error('Error updating child data:', error);
        },
      });
  }
  onFileSelected(event: Event, fieldName: string) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];

      // Generate preview URL
      const reader = new FileReader();
      reader.onload = () => {
        const imageUrl = reader.result as string;
        if (fieldName === 'family_photo') {
          this.familyPhotoUrl = imageUrl;
        } else if (fieldName === 'my_photo_drawing') {
          this.myPhotoDrawingUrl = imageUrl;
        } else if (fieldName === 'family_photo_drawing') {
          this.familyPhotoDrawingUrl = imageUrl;
        }
      };
      reader.readAsDataURL(file);

      this.studentForm.patchValue({ [fieldName]: file });
    }
  }

  uploadProfilePhoto(
    child_id: number,
    child_name: string,
    child_last_name: string
  ) {
    console.log('user pressed profile photo %');
    this.openUploadWindow = true;

    if (!child_last_name) {
      console.log('student last name is null: ', child_last_name);
      console.log('student last name is null: ', child_name);
    } else {
      child_name = child_name + ' ' + child_last_name;
    }
    // these parameters will be sent to uplaodPhotos component using @Input.
    this.purpose = 'profile';
    this.studentId = child_id;
    this.studentName = child_name;
    this.studentLastName = child_last_name;
  }

  windowStatus(Window: boolean) {
    // true: upload photos window is open, false: is closed
    this.openUploadWindow = Window;
    // console.log('Received value of window status from child:', Window);
  }

  openCamera() {
    console.log('opening camera component %%');
    this.router.navigate(['/camera']);
  }

  goBack() {
    window.history.back();
  }

  calculateAge(birthYear: number, birthMonth: number, birthDate: number) {
    // For calculaitng  the age we needed the current date and birth date we are getting that from below two lines
    const currentDate = new Date();
    const birthDateObj = new Date(birthYear, birthMonth - 1, birthDate);

    // Thses  variables are stor ing the difference of birthdate and current date
    // We will get the age with the help of these variables
    const yearsDiff = currentDate.getFullYear() - birthDateObj.getFullYear();
    const monthsDiff = currentDate.getMonth() - birthDateObj.getMonth();
    const daysDiff = currentDate.getDate() - birthDateObj.getDate();

    // for storing the age
    let age = '';

    /**
     * If the month difference is less than 0 or have same month
     * Then we need to add 12 in month differen as it shows that the current month is the birthmonth of child
     * And birth date isn't come yet
     */

    if (monthsDiff < 0 || (monthsDiff === 0 && daysDiff < 0)) {
      // as the birthday isn't come yet so wee need to subtract it with 1
      let year = yearsDiff - 1;
      age = `${this.translate.instant(`num.${year}`)}${this.translate.instant('y')} `;
      if (monthsDiff < 0) {
        age += `${this.translate.instant(`num.${12 + monthsDiff}`)}${this.translate.instant('m')}`;
      }
    } else {
      // AS mention above if the birth date is passed in the current year we just need to assign both to age vaiable
      age = `${this.translate.instant(`num.${yearsDiff}`)}${this.translate.instant('y')} `;
      if (monthsDiff > 0) {
        age += ` ${this.translate.instant(`num.${monthsDiff}`)}${this.translate.instant('m')}`;
      }
    }
    return age;
  }
}
