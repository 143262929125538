<div id="serviceProviderList">
    <!-- Header Section with Component Title and Logged-in User's Name -->
    <section id="componentHeader" class="d-flex align-center just-start">
      <div id="componentTitle">
        <div>Learning Outcomes</div>
        <!-- Displays the section title -->
      </div>
      <div id="teacherName">
        <span>Welcome, </span><span>{{ userName }}</span>
        <!-- Displays the logged-in user's name -->
      </div>
    </section>
  
    <!-- Search and Pagination Controls -->
    <div class="pagination-controls">
      <div class="search-box">
        <!-- Input field for searching service providers by name -->
        <input
          type="text"
          placeholder="Search learning outcome..."
          (input)="loadLearningOutcomes(1)" />
      </div>
  
      <!-- Dropdown for selecting the number of rows per page -->
      <label for="pageSize" class="rowLabel">Rows per page:</label>
      <select id="pageSize" [formControl]="pageSizeControl">
        @for (size of pageSizes; track $index) {
          <option [value]="size">{{ size }}</option>
          <!-- Options for page sizes -->
        }
      </select>
    </div>
  
    <!-- Table Component to Display Paginated Data -->
    <table-component
      [parentComponent] = 'componentName'
      [columns]="columnKeys"
      [headerNames]="tableHeaders"
      [data]="learningOutcome"
      [addPageSize]="currentIndex"
  
      (rowClick)="navigate($event)"
      ></table-component>
  
    <!-- Pagination Controls -->
    <div class="pagination-controls">
        <button type="button" (click)="AddLearningOutCome()">+ Add learning OutComes</button>
    </div>
  </div>
  