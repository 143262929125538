import { Component } from '@angular/core';

@Component({
  selector: 'app-add-schools',
  standalone: true,
  imports: [],
  templateUrl: './add-schools.component.html',
  styleUrl: './add-schools.component.css'
})
export class AddSchoolsComponent {

}
