import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavbarComponent } from '../../../../shared/component/navbar/navbar.component';
import { FooterNavbarComponent } from '../../../../shared/component/footer-navbar/footer-navbar.component';
import { NamesectionComponent } from '../../../../shared/component/nameSection/namesection.component';
import { UserService } from '../../../../shared/services/utility/user.service';
import { ResultsService } from '../../../../shared/services/api/results.service';
import { SpinnerComponent } from '../../../../shared/component/spinner/spinner.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HindiNumberPipe } from '../../../../shared/pipe/hindi-number.pipe';
import { firstValueFrom } from 'rxjs';
import { ToastService } from '../../../../shared/services/alert/toast.service';


// amCharts imports
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

// imports for bargraph
import * as am5xy from '@amcharts/amcharts5/xy';
import { NgClass } from '@angular/common';
import { consumerPollProducersForChange } from '@angular/core/primitives/signals';

// interface for category-wise results in summary report
interface Category {
  // for PREMIUM users only.
  cat_id: number;
  cat_name: string;
  parentChartId: string;
  teacherChartId: string;
  total: number;
  done: number;
  parentResult: number;
  teacherResult: number;
  averageResult: number;
}

@Component({
  selector: 'app-summary-report',
  standalone: true,
  imports: [
    NgClass,
    NavbarComponent,
    FooterNavbarComponent,
    NamesectionComponent,
    SpinnerComponent,
    TranslateModule,
    HindiNumberPipe
  ],
  providers: [UserService, ResultsService],
  templateUrl: './summary-report.component.html',
  styleUrl: './summary-report.component.css',
})
export class SummaryReportComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private userService = inject(UserService);
  private resultService = inject(ResultsService);
  private translate = inject(TranslateService);
  private toastService  = inject(ToastService);

  navbarDisplay: string = 'none';
  params: any;
  studentName: string = '';
  studentLastName: string = '';
  profilePhoto: string = '';
  born_date: number = 0;
  born_month: number = 0;
  born_year: number = 0;
  age: string = '0';
  parent_first_name: string = 'firstName';
  parent_last_name: string = 'lastName';
  parent_email: string = 'email';
  parent_contact: string = '';
  pieColor: number = 0x8FE5FF; // blue color default for basic users.
  userId: number = 0;
  student_id: number = 0;
  summaryReportData: any;
  premiumParentsResults: any; // use this variable for premium users.
  user_plan: string = '';
  subcatSummaryData: any;
  class_id: number = 0;
  basicResults: object = {};
  basicParentAverage: number = 0;
  basicTeacherAverage: number = 0;
  basicCollatedAverage: number = 0;
  basicTotal: number = 0;
  basicDone: number = 0;
  mode: string = ''; // there are two modes in which summary-report component may open. 'summary' and 'collated'. this variable is necessary for easy forward and backward navigation from summary-report component.

  loader: boolean  = false; // for spinner component
  parentsResults: string = ''; // for sending to subcat-collated component.
  teacherResults: {
    category_id: number;
    subcategory_id: number;
    result: number;
  }[] = [];

  basicParentAllResults: any;

  goBackTo: string = 'parent-share';

  // sessionStorage
  sessionSummaryParams: string = '';

  sessionCategoryResults: string = '';
  sessionPremiumParentResults: string = '';
  sessionUserPlan: string = '';
  sessionPremiumTeacherResults: string = '';

  sessionBasicParentAllResults: string = '';
  sessionBasicTeacherAverage: number = 0;
  sessionBasicCollatedAverage: number = 0;
  sessionFinalCollatedCatResult: string = '';
  isMasked: string = '' ; 


  constructor(...args: unknown[]);

  constructor() {
 
    this.isMasked = window.localStorage.getItem('isMasked')!;
    console.log("is masked value : ",this.isMasked);


    // this.loader = true;
    // console.log('constructor called !!');

    // in-case if user reloads the summary-report page, clearing sessionStorage to re-fire api's.
    
    if (typeof window !== 'undefined' && window.localStorage) {

    window.sessionStorage.removeItem('user_plan');
    window.sessionStorage.removeItem('CategoryResult');
    window.sessionStorage.removeItem('basicParentAllResults');
    window.sessionStorage.removeItem('basicTeacherAverage');

    }
    this.mode = this.route.snapshot.paramMap.get('mode')!;

    // if user has came back from subcat-collated component , then taking the params values from the sessionStorage
    this.sessionSummaryParams = window.sessionStorage.getItem(
      'summaryReportParams'
    )!;

    if (this.sessionSummaryParams) {
      this.params = JSON.parse(this.sessionSummaryParams);
      // console.log('params recieved from sessionStorage', this.params);
    } else {
      // by defualt taking params values from .route.snapshot.queryParams and storing in sessionStorage.
      this.params = this.route.snapshot.queryParams;
      window.sessionStorage.setItem(
        'summaryReportParams',
        JSON.stringify(this.params)
      );
    }

    this.sessionUserPlan = window.sessionStorage.getItem('user_plan')!;
    if (this.sessionUserPlan) {
      this.user_plan = this.sessionUserPlan;
      // console.log('user_plan from session in constructor: ', this.user_plan);
    }

    this.class_id = Number(this.route.snapshot.paramMap.get('class_id'))!;
    this.student_id = Number(this.params.child_id);

    this.born_date = this.params.child_born_date;
    this.born_month = this.params.child_born_month;
    this.born_year = this.params.child_born_year;
    this.age = this.calculateAge(
      this.born_year,
      this.born_month,
      this.born_date
    );
    if(this.params.child_last_name){
      this.studentLastName = 
      this.studentName = this.params.child_name + ' ' + this.params.child_last_name;
    }else{
      this.studentName = this.params.child_name;
    }
    console.log("student full name is : ",this.studentName);
    
    this.profilePhoto = this.params.profile_image;
    // console.log("profile Photo in summary report is : ",this.profilePhoto);
    this.parent_first_name = this.params.parent_name;
    this.parent_last_name = this.params.parent_last_name;
    this.parent_email = this.params.parent_email;
    this.parent_contact = this.params.parent_mobile;
    if (!this.parent_contact || this.parent_contact === 'null') {
      this.parent_contact = 'Not Available';
    }
    this.userId = Number(this.params.parent_id);
  }

  // CategoryResult is for PREMIUM users only !! .
  CategoryResult: Category[] = [
    {
      cat_id: 15,
      cat_name: 'Physical Development',
      parentChartId: 'Pchart1',
      teacherChartId: 'teacherChart1',
      total: 0,
      done: 0,
      parentResult: 0,
      teacherResult: 0,
      averageResult: 0,
    },
    {
      cat_id: 16,
      cat_name: 'Communication and Language',
      parentChartId: 'Pchart2',
      teacherChartId: 'teacherChart2',
      total: 0,
      done: 0,
      parentResult: 0,
      teacherResult: 0,
      averageResult: 0,
    },
    {
      cat_id: 17,
      cat_name: 'Socio-Emotional Development',
      parentChartId: 'Pchart3',
      teacherChartId: 'teacherChart3',
      total: 0,
      done: 0,
      parentResult: 0,
      teacherResult: 0,
      averageResult: 0,
    },
    {
      cat_id: 18,
      cat_name: 'Problem Solving',
      parentChartId: 'Pchart4',
      teacherChartId: 'teacherChart4',
      total: 0,
      done: 0,
      parentResult: 0,
      teacherResult: 0,
      averageResult: 0,
    },
    {
      cat_id: 19,
      cat_name: 'General Knowledge',
      parentChartId: 'Pchart5',
      teacherChartId: 'teacherChart5',
      total: 0,
      done: 0,
      parentResult: 0,
      teacherResult: 0,
      averageResult: 0,
    },
    {
      cat_id: 20,
      cat_name: 'Arts and Creativity',
      parentChartId: 'Pchart6',
      teacherChartId: 'teacherChart6',
      total: 0,
      done: 0,
      parentResult: 0,
      teacherResult: 0,
      averageResult: 0,
    },
  ];

  ngOnInit() {
    // console.log('onInIt called !!');
    // console.log('user_plan on initialization : ', this.user_plan);
    // console.log('userId : ', this.userId);

    if (!this.sessionUserPlan) {
      // hitting api only for first visit
      // await this.checkUserPlan();
      console.log("userId",this.userId);
      this.userService.checkParentPlan(this.userId).subscribe({
        next: (wholeData: any) => {
          if (JSON.stringify(wholeData.data.data) === '{}') {
            // Empty object received, hence basic user.
            this.user_plan = 'basic';
          } else {
            // Non-empty object received, hence premium user.
            this.user_plan = 'premium';
          }
          // Setting user_plan in sessionStorage
          // console.log("user plan is : ",this.user_plan);
          window.sessionStorage.setItem('user_plan', this.user_plan);

          this.selectTab(this.mode); // by default loading the summary report.
        },
        error: (error: string) => {
          console.log('error while fetching user_plan: ', error);
        },
      });
    }

    // console.log('user_plan before ngOnInit finishes : ', this.user_plan);
    // console.log('onInit Execution finished');
  }

  // ngAfterViewInit(){
  //   this.loader = true;
  // }

  ngOnDestroy() {
    // console.log('inside ngDestroy !!');
    // clearing session storage (except for subcatSummaryData, and subcatCollatedData) if component changes.
    window.sessionStorage.removeItem('user_plan');
    // window.sessionStorage.removeItem('premiumParentResults');
    // window.sessionStorage.removeItem('premiumTeacherResults');
    window.sessionStorage.removeItem('basicParentAllResults');
    // window.sessionStorage.removeItem('basicTeacherAverage');
  }

  async selectTab(tab: string) {
    this.mode = tab;
    // console.log('selected tab is : ', tab);

    // getting data from sessionStorage
    this.sessionUserPlan = window.sessionStorage.getItem('user_plan')!;
    this.user_plan = this.sessionUserPlan;

    this.sessionCategoryResults =
      window.sessionStorage.getItem('CategoryResult')!;

    this.sessionPremiumParentResults = window.sessionStorage.getItem(
      'premiumParentResults'
    )!;
    this.sessionPremiumTeacherResults = window.sessionStorage.getItem(
      'premiumTeacherResults'
    )!;

    this.sessionBasicParentAllResults = window.sessionStorage.getItem(
      'basicParentAllResults'
    )!;
    this.sessionBasicTeacherAverage = Number(
      window.sessionStorage.getItem('basicTeacherAverage')!
    );
    this.sessionBasicCollatedAverage = Number(
      window.sessionStorage.getItem('sessionBasicCollatedAvg')!
    );
    this.sessionFinalCollatedCatResult = window.sessionStorage.getItem(
      'finalCollatedCategoryResult'
    )!;
    // console.log(
    //   'sessionBasicCollatedAverage recieved is : ',
    //   this.sessionBasicCollatedAverage
    // );

    // console.log(
    //   'Inside SelectTab getting data \nsessionUserPlan: ',
    //   this.sessionUserPlan,
    //   '\n\nsessionSummaryParams: ',
    //   this.sessionSummaryParams,
    //   '\n\nsessionCategoryResults: ',
    //   this.sessionCategoryResults,
    //   '\n\nsessionPremiumParentResults: ',
    //   this.sessionPremiumParentResults,
    //   '\n\nsessionPremiumTeacherResults: ',
    //   this.sessionPremiumTeacherResults,
    //   '\n\nsessionBasicParentAllResults: ',
    //   this.sessionBasicParentAllResults,
    //   '\n\nsessionBasicTeacherAverage: ',
    //   this.sessionBasicTeacherAverage
    // );

    if (this.user_plan === 'basic' && tab === 'summary') {
      if (
        this.sessionBasicParentAllResults &&
        !this.sessionPremiumParentResults
      ) {
        // sessionStorage
        this.sessionGenerateParentResults(this.user_plan);
      } else {
        // firing parent api for first visit
        this.loader = false;
        await this.getSummaryReport();
        
      }
    } else if (this.user_plan === 'basic' && tab === 'collated') {
      // console.log("session basic teacher average : ",this.sessionBasicTeacherAverage);
      if (
        this.sessionBasicParentAllResults &&
        this.sessionBasicTeacherAverage
      ) {
        // session
        this.createBothPieCharts();
        // console.log('BOTH basic peicharts created from sessionStorage ');
      } else {
        // firing both api's for the first visit.
        await this.getSummaryReport();
        await this.getTeacherResults();
        
      }
    } else if (this.user_plan === 'premium' && tab === 'summary') {
      if (this.sessionCategoryResults && this.sessionPremiumParentResults) {
        // loading summary report from session
        this.sessionGenerateParentResults(this.user_plan);
        
      } else {
        // loading summary report from api call
        await this.getSummaryReport();
      }
    } else if (this.user_plan === 'premium' && tab === 'collated') {
      // console.log('inside 4 ');
      // if (this.sessionPremiumTeacherResults) {
      //   // loading collated report from session storage
      //   console.log(
      //     'sessionfinalCollated Results: ',
      //     this.sessionFinalCollatedCatResult
      //   );
      //   this.CategoryResult = JSON.parse(this.sessionFinalCollatedCatResult);
      //   this.createBothPieCharts();
      //   console.log(
      //     'PREMIUM COLLATED piecharts created from session storage with CategoryResult: ',
      //     this.CategoryResult
      //   );
      // } else {
        // firing both api's for the first visit.
        await this.getSummaryReport();
        await this.getTeacherResults();
      // }
    } else {
      alert(
        '-------------there occurred an error in fetching results--------------'
      );
    }
  }

  // bargraph colors
  determineColor(averageScore: number) {
    if (averageScore <= 20) {
      //red
      return am5.color('#FBAF55'); // light orange
    } else if (averageScore >= 66) {
      return am5.color('#A9FA68'); // green
    } else {
      return am5.color('#FCF969'); // yellow
    }
  }

  calculateAge(birthYear: number, birthMonth: number, birthDate: number) {
    // For calculaitng  the age we needed the current date and birth date we are getting that from below two lines
    const currentDate = new Date();
    const birthDateObj = new Date(birthYear, birthMonth - 1, birthDate);

    // Thses  variables are stor ing the difference of birthdate and current date
    // We will get the age with the help of thses variables
    const yearsDiff = currentDate.getFullYear() - birthDateObj.getFullYear();
    const monthsDiff = currentDate.getMonth() - birthDateObj.getMonth();
    const daysDiff = currentDate.getDate() - birthDateObj.getDate();

    // for storing the age
    let age = '';

    /**
     * If the month difference is less than 0 or have same month
     * Then we need to add 12 in month differen as it shows that the current month is the birthmonth of child
     * And birth date isn't come yet
     */

    if (monthsDiff < 0 || (monthsDiff === 0 && daysDiff < 0)) {
      // as the birthday isn't come yet so wee need to subtract it with 1
      let year = yearsDiff - 1;
      age = `${this.translate.instant(`num.${year}`)}${this.translate.instant('y')} `;
      if (monthsDiff < 0) {
        age += `${this.translate.instant(`num.${12 + monthsDiff}`)}${this.translate.instant('m')}`;
      }
    } else {
      // AS mention above if the birth date is passed in the current year we just need to assign both to age vaiable
      age = `${this.translate.instant(`num.${yearsDiff}`)}${this.translate.instant('y')} `;
      if (monthsDiff > 0) {
        age += ` ${this.translate.instant(`num.${monthsDiff}`)}${this.translate.instant('m')}`;
      }
    }
    return age;
  }

  createChart(chartData: any) {
    setTimeout(() => {
      let bargraphDom = document.getElementById('createBargraph');
      if (bargraphDom) {
        // console.log('bargraph id is found!!!!');

        // // Assign color to each data point
        // console.log('chartData recieved in bargraph : ', chartData);
        let chartDataArray = Object.values(chartData).map((dataPoint: any) => {
          return {
            ...dataPoint,
            color: this.determineColor(dataPoint.averageScore),
          };
        });

        am5.array.each(am5.registry.rootElements, function (root) {
          if (root.dom.id === 'createBargraph') {
            // console.log('root from registry for Bargraph is : ', root.dom.id);
            root.container.children.clear();
            // root.dispose();
            // console.log(
            //   'root.container.children CLEARED before creating new ROOT: '
            // );
          }
        });

        const root = am5.Root.new('createBargraph');

        if (root._logo) {
          root._logo.dispose();
        }

        let chart = root.container.children.push(
          am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: 'panX',
            wheelY: 'zoomX',
            pinchZoomX: true,
            paddingLeft: 0,
            paddingRight: 1,
          })
        );

        let cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}));
        // cursor.lineY.set("visible", false);

        let xRenderer = am5xy.AxisRendererX.new(root, {
          minGridDistance: 30,
        });

        let xAxis = chart.xAxes.push(
          am5xy.CategoryAxis.new(root, {
            maxDeviation: 0.3,
            categoryField: 'cat_name',
            renderer: xRenderer,
          })
        );

        xRenderer.labels.template.setAll({
          oversizedBehavior: 'truncate',
          textAlign: 'center',
        });

        xAxis.onPrivate('cellWidth', cellWidth => {
          xRenderer.labels.template.set('maxWidth', cellWidth);
        });

        let yRenderer = am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1,
        });

        let yAxis = chart.yAxes.push(
          am5xy.ValueAxis.new(root, {
            min: 0,
            max: 100,
            renderer: yRenderer,
          })
        );

        let series = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            name: 'Series 1',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'averageScore',
            sequencedInterpolation: true,
            categoryXField: 'cat_name',
            tooltip: am5.Tooltip.new(root, {
              labelText: '{cat_name} {valueY}',
            }),
          })
        );

        series.bullets.push(() => {
          return am5.Bullet.new(root, {
            locationX: 0.5,
            locationY: 1,
            sprite: am5.Circle.new(root, {
              radius: 15,
              stroke: am5.color('#000'),
              strokeWidth: 1,
              fill: am5.color('#fff'),
              centerX: am5.percent(50),
              centerY: am5.percent(50),
            }),
          });
        });

        series.bullets.push(() => {
          return am5.Bullet.new(root, {
            locationX: 0.5,
            locationY: 1.0,
            stacked: 'up',
            sprite: am5.Label.new(root, {
              text: '{valueY}',
              centerX: am5.percent(50),
              centerY: am5.percent(50),
              textAlign: 'center',
              populateText: true,
            }),
          });
        });

        series.columns.template.adapters.add(
          'fill',
          (fill: any, target: any) => {
            const dataItem = target.dataItem?.dataContext as {
              color?: { _hex: string };
            };
            if (dataItem?.color && dataItem.color._hex) {
              const hexColor = Number(dataItem.color._hex);
              const red = (hexColor >> 16) & 255;
              const green = (hexColor >> 8) & 255;
              const blue = hexColor & 255;
              return am5.color(`rgb(${red}, ${green}, ${blue})`);
            }
            return undefined;
          }
        );

        series.columns.template.setAll({
          cornerRadiusTL: 8,
          cornerRadiusTR: 8,
          cornerRadiusBR: 8,
          cornerRadiusBL: 8,
          strokeOpacity: 0,
          width: 18,
        });

        xAxis.data.setAll(chartDataArray);
        series.data.setAll(chartDataArray);

        series.appear(1000);
        chart.appear(1000, 100);
      } else {
        // console.log('bargraph id NOT Found $$$');
      }
    }, 0); // delay by 0 means it will wait until dom is fully loaded.
  }

  createPieChart(chartId: string, result: number, color: number) {
    // console.log('create pei called *** ');
    setTimeout(() => {
      let pieChartDom = document.getElementById(chartId);
      // console.log('pie chart dom : ',pieChartDom);
      if (pieChartDom) {
        // console.log('piechart id is found!!!!: ', chartId);

        // console.log('chart fired : ', chartId, ' result is : ', result);

        // am5.array.each(am5.registry.rootElements, function (root) {
        //   if (root.dom.id === chartId) {
        //     console.log('root from registry Piechart is : ', root.dom.id);
        //     root.container.children.clear();
        //     root.dispose();
        //     console.log(
        //       'root.container.children for Piechart CLEARED before creating new root '
        //     );
        //   } 
        // });

        const root = am5.Root.new(chartId);

        const chart = root.container.children.push(
          am5percent.PieChart.new(root, {
            // layout: root.verticalLayout,
          })
        );

        root.setThemes([am5themes_Animated.new(root)]);

        // pieChart data
        const data = [
          {
            status: 'Done',
            value: result,
            sliceSettings: {
              fill: am5.color(color), // score color
              stroke: am5.color(0x000000),
            },
          },
          {
            country: 'NotDone',
            value: 100 - result,
            sliceSettings: {
              fill: am5.color(0xffffff), // white
              stroke: am5.color(0x000000),
            },
          },
        ];

        // Create series
        const series = chart.series.push(
          am5percent.PieSeries.new(root, {
            name: 'Series',
            valueField: 'value',
            categoryField: 'status',
          })
        );

        // adding colours
        series.slices.template.setAll({
          templateField: 'sliceSettings',
          showTooltipOn: 'click',
        });

        // hiding labels
        series.labels.template.setAll({
          maxWidth: 1,
          oversizedBehavior: 'hide',
        });
        // series.labels.template.setAll("hide");

        series.ticks.template.set('visible', false);

        // annimations
        series.animate({
          key: 'startAngle',
          to: result,
          duration: 3000,
          easing: am5.ease.yoyo(am5.ease.linear),
        });

        series.data.setAll(data);
        series.appear();
        chart.appear();
      } else {
        // console.log("Piechart id NOT Found $$$: ', chartId");
      }
    },0); // delay by 0 means it will wait until dom is fully loaded.
  }

  sessionGenerateParentResults(user: string) {
    // this method draws graphs using data from sessionStorage
    // console.log('parents results api NOT called !!');

    if (user === 'basic') {
      this.basicParentAllResults = JSON.parse(
        this.sessionBasicParentAllResults
      );
      // console.log('parsed basicParentAllResults: ', this.basicParentAllResults);

      this.basicResults = this.basicParentAllResults.result;
      this.basicParentAverage = this.basicParentAllResults.averageScore;
      this.basicTotal = this.basicParentAllResults.total;
      this.basicDone = this.basicParentAllResults.done;

      setTimeout(() => {
        this.createPieChart(
          'basicParentPie',
          this.basicParentAverage,
          0xB5EEFF
        ); // hex for blue color for basic pieChart
      }, 20);
    } else {
      // premium
      this.premiumParentsResults = JSON.parse(this.sessionPremiumParentResults);
      // console.log('premiumParentResults : ', this.premiumParentsResults);

      // creating bargraph
      setTimeout(() => {
        // setTimeOut was neccessary to use because html element was loading later than createChart function. resulting in html element id not found error
        this.createChart(this.premiumParentsResults);
        // creating piecharts
        let i = 0;
        for (let obj of this.CategoryResult) {
          obj.parentResult = this.premiumParentsResults[i].averageScore;
          obj.total = this.premiumParentsResults[i].total;
          obj.done = this.premiumParentsResults[i].done;
          this.setPieColor(obj.parentResult);
          this.createPieChart(
            obj.parentChartId,
            obj.parentResult,
            this.pieColor
          );
          i++;
        }
      }, 20);

      this.loader = true;
    }

    // console.log('parents results api NOT called !! EXECUTED FULLY !!');
  }

  async getSummaryReport() {
    // console.log('inside getSummaryReport !!');
    // console.log('mode is : ', this.mode);
    // console.log('user_plan is : ', this.user_plan);
    if (!this.sessionCategoryResults && !this.sessionPremiumParentResults) {
      // firing api
      this.userService
        // userId 2600 is a basic user for testing. 3399 also
        // userId 2032 is a premium user who has not answered any qwestions.
        // userId 2064 and 2065 are proper premium users.
        .getSummaryReportByUserId(this.userId)
        .subscribe((data: any) => {
          // console.log('parents results api called ##' , data);

          this.summaryReportData = Object.values(data.data);
          // console.log('all data from summary report ', this.summaryReportData);
          // //checking if profile is basic or not
          // if(this.summaryReportData[0].cat_name === 'Basic Profile' && this.summaryReportData[0].averageScore!=0 && this.summaryReportData[0].result.length !=0 ) this.basic = true;

          if (this.user_plan === 'basic') {
            // ** BASIC users code .

            this.basicResults = this.summaryReportData[0].result;
            this.basicParentAverage = this.summaryReportData[0].averageScore;
            this.basicTotal = this.summaryReportData[0].total;
            this.basicDone = this.summaryReportData[0].done;

            // console.log(
            //   'basic Parent all Results: ',
            //   this.summaryReportData[0]
            // );

            // storing the values in sessionStorage
            window.sessionStorage.setItem(
              'basicParentAllResults',
              JSON.stringify(this.summaryReportData[0])
            );
            // console.log('basic Parent average is : ', this.basicParentAverage);

            this.createPieChart(
              'basicParentPie',
              this.basicParentAverage,
              0xB5EEFF
            ); // hex for blue color for basic pieChart
          }
          // *** PREMIUM USERS CODE

          if (this.user_plan === 'premium') {
            this.premiumParentsResults = this.summaryReportData.filter(
              (obj: any) => obj.cat_name !== 'Basic Profile'
            ); // excluding the Basic Profile and only taking the Categories.

            // console.log(
            //   'filter output of parents Results is : ',
            //   this.premiumParentsResults
            // );

            // storing parents results in session storage
            window.sessionStorage.setItem(
              'premiumParentResults',
              JSON.stringify(this.premiumParentsResults)
            );

            // creating bargraph
            if (this.mode === 'summary') {
              this.createChart(this.premiumParentsResults);
            }

            let i = 0;
            for (let obj of this.CategoryResult) {
              obj.parentResult = this.premiumParentsResults[i].averageScore;
              obj.total = this.premiumParentsResults[i].total;
              obj.done = this.premiumParentsResults[i].done;
              this.setPieColor(obj.parentResult);
              this.createPieChart(
                obj.parentChartId,
                obj.parentResult,
                this.pieColor
              );
              i++;
            }

            // storing the value of CategoryResult in sessionStorage for later use
            // console.log(
            //   'categoryResult after firing parents results api : ',
            //   this.CategoryResult
            // );
            window.sessionStorage.setItem(
              'CategoryResult',
              JSON.stringify(this.CategoryResult)
            );
          }
          this.loader = true;
        });
    }else{
      this.sessionGenerateParentResults(this.user_plan);
    }

    
  }

  async getTeacherResults() {
    let screen = 'summary-report'; // necessary to handle the situation where teacher's results are absolutely not present.
    this.resultService
      .getCompletedMilestones(
        this.class_id,
        this.student_id,
        this.born_month,
        this.born_date,
        this.born_year,
        screen
      )
      .subscribe((data: any) => {
        // console.log('teacher results api called ##');
        // console.log('all results from teacher api : ', data.data);
        //removing milestone_ids field from each object to avoid unneccessary payload.
        this.teacherResults = data.data.milestone.map((obj: { [key: string]: any }) => {
          const shallowObj = { ...obj };
          delete shallowObj['milestone_ids'];
          return shallowObj;
        });
        // console.log(
        //   'printing mapped data of getTeacherResults',
        //   this.teacherResults
        // );
        // adding teacher results in the sessionStorage
        window.sessionStorage.setItem(
          'premiumTeacherResults',
          JSON.stringify(this.teacherResults)
        );

        let avg = 0;
        let avgSum = 0;
        let completed_cat = 0;

        // logic to compute average of all subcategories
        for (const obj of this.CategoryResult) {
          const cat_id = obj.cat_id;
          let sum = 0;
          let no_of_subcat = 0;
          let result = 0;

          for (const i of data.data) {
            if (cat_id == i.category_id) {
              sum += i.result;
              no_of_subcat++;
            }
          }

          // rounding off the average to avoid decimals.
          result = Math.round((sum / (no_of_subcat * 100)) * 100);
         
          // console.log("type of result in summary report : ",result);

          if (isNaN(result)) {
            result = 0;
          }
          obj.teacherResult = result;

          // calculating average result of all attempted categories
          if (obj.teacherResult > 0) {
            avgSum += obj.teacherResult;
            completed_cat++;
          }
          // console.log("sum value inside average for loop is : ",avgSum);
          // console.log("completed_cat value inside average for loop is : ",completed_cat);
          avg = avgSum / completed_cat;
        } // end of main for loop
        // console.log('avg', avg);

        // console.log(
        //   'final CategoryResult inside getTeacherResults() is : ',
        //   this.CategoryResult
        // );
        // computing averages of all categories
        for (let obj of this.CategoryResult) {
          if (obj.teacherResult === 0 && obj.parentResult === 0) {
            // both sides results pending so , average is 0
            obj.averageResult = 0;
          } else if (obj.teacherResult === 0 && obj.parentResult !== 0) {
            // teacher side result pending
            obj.averageResult = obj.parentResult;
          } else if (obj.parentResult === 0 && obj.teacherResult !== 0) {
            // parent side result pending
            obj.averageResult = obj.teacherResult;
          } else {
            // both sides results present.
            obj.averageResult = Math.round(
              (obj.parentResult + obj.teacherResult) / 2
            );
          }
        }

        // setting results in session storage for navigating within tabs
        window.sessionStorage.setItem(
          'CategoryResult',
          JSON.stringify(this.CategoryResult)
        );

        // setting results in session storage for navigating within subcat-collated component and summary-report component
        window.sessionStorage.setItem(
          'finalCollatedCategoryResult',
          JSON.stringify(this.CategoryResult)
        );

        if (this.user_plan === 'basic') {
          // calculating Average of all 6 categories for basic profile for collated results
          // console.log('inside collated basic')
          avgSum = 0;
          for (let obj of this.CategoryResult) {
            avgSum += obj.teacherResult;
          }
          this.basicTeacherAverage = Math.round(avgSum / 6);

          // storing basicTeacher average in session storage
          window.sessionStorage.setItem(
            'basicTeacherAverage',
            JSON.stringify(this.basicTeacherAverage)
          );

          // console.log("calculated basicTeacher average : ",this.basicTeacherAverage );  
          this.createPieChart(
            'basicTeacherPie',
            this.basicTeacherAverage,
            0xB5EEFF
          );
          // console.log(
          //   'basicParent average is : ',
          //   this.basicParentAverage,
          //   ' and basicTeacher average : ',
          //   this.basicTeacherAverage
          // );

          // console.log("session basic collated average : ",this.sessionBasicCollatedAverage);
          if (this.sessionBasicCollatedAverage != 0) {
            // no need to compute if we already have it in session.
            this.basicCollatedAverage = this.sessionBasicCollatedAverage;
          } else {
            // computing basic collated average
            if (
              this.basicParentAverage === 0 &&
              this.basicTeacherAverage === 0
            ) {
              // both sides results pending
              this.basicCollatedAverage = 0;
            } else if (
              this.basicParentAverage !== 0 &&
              this.basicTeacherAverage === 0
            ) {
              // teacher side result not present
              this.basicCollatedAverage = this.basicParentAverage;
            } else if (
              this.basicTeacherAverage !== 0 &&
              this.basicParentAverage === 0
            ) {
              // parent side result not present
              this.basicCollatedAverage = this.basicTeacherAverage;
            } else {
              // both side results present so taking average of both.
              this.basicCollatedAverage = Math.round(
                (this.basicParentAverage + this.basicTeacherAverage) / 2
              );
            }

            // console.log("basicCollated Average : ",this.basicCollatedAverage);
            // storing basicCollatedAverage in sessionStorage
            window.sessionStorage.setItem(
              'sessionBasicCollatedAvg',
              JSON.stringify(this.basicCollatedAverage)
            );
          }
        } else {
          // for premium

          this.createBothPieCharts();
        }
      });
  }

  // this function creates piecharts for teacher's results in collated results page.
  createBothPieCharts() {
    // console.log(
    //   'inside Create both pie charts CategoryResult : ',
    //   this.CategoryResult,
    //   ' user_plan is : ',
    //   this.user_plan,
    //   ' mode selected is : ',
    //   this.mode
    // );

    if (this.user_plan === 'basic') {
      setTimeout(() => {
        this.createPieChart(
          'basicParentPie',
          this.basicParentAverage,
          0x8FE5FF
        ); // hex for blue color for basic pieChart
        this.createPieChart(
          'basicTeacherPie',
          this.basicTeacherAverage,
          0x8FE5FF
        );
      }, 20);
    } else {
      // premium
      setTimeout(() => {
        for (let obj of this.CategoryResult) {
          if (this.sessionPremiumParentResults) {
            // creating parent pie charts from session data.
            this.setPieColor(obj.parentResult);
            this.createPieChart(
              obj.parentChartId,
              obj.parentResult,
              this.pieColor
            );
          }

          this.setPieColor(obj.teacherResult);
          this.createPieChart(
            obj.teacherChartId,
            obj.teacherResult,
            this.pieColor
          );
        }
      }, 20);
    }
  }

  setPieColor(score: number) {
    if (score <= 25) {
      this.pieColor = 0xFBAF55; // orange color
    } else if (score >= 26 && score <= 65) {
      this.pieColor = 0xFCF969; // yellow color
    } else if (score >= 66) {
      this.pieColor = 0xA9FA68; // green color
    }
  }

  goToSubcatCollated(
    catName: string,
    catId: number, // 0 catID means basic
    average: number,
    parentResult: number,
    teacherResult: number
  ) {

    // checking if both parent's and teacher's category is completed.

    if ( parentResult === 0 && teacherResult ===0) {
      this.toastService.showAlert(
        this.translate.instant('summaryReport.resNotAvailable'),
        this.translate.instant('Note')
      )
      return;
    } else if ( parentResult !== 0 && teacherResult === 0 ) {
      this.toastService.showAlert(
        this.translate.instant('summaryReport.teacherResPending'),
        this.translate.instant('Note')
      )
      return;
    } else if( parentResult === 0 && teacherResult !== 0){
      this.toastService.showAlert(
        this.translate.instant('summaryReport.parentResPending'),
        this.translate.instant('Note')
      )
      return;
    }

    if (catName === 'Basic') {
      // Basic profile

      // console.log(
      //   'going to basic collated report ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^'
      // );

      let basicParentSubcatData = this.summaryReportData[0].subCategories;
      let basicParentResultData = this.summaryReportData[0].result;

      let basicAndPremiumMapped_ids: {
        basicSubcatId: number;
        premiumMappedSubcatId: number;
        subcat_name: string;
      }[] = [];
      let teacherbasicResults: {
        premiumMappedSubcatId: number;
        score: number;
      }[] = [];

      for (let parentSubcat in basicParentSubcatData) {
        // console.log(
        //   'basicSubcat id :',
        //   parentSubcat,
        //   ' data is : ',
        //   basicParentSubcatData[parentSubcat]
        // );
        let basicSubcatId = Number(parentSubcat);
        basicAndPremiumMapped_ids.push({
          basicSubcatId: basicSubcatId,
          premiumMappedSubcatId:
            basicParentSubcatData[parentSubcat].premiumSubcatMapped,
          subcat_name: basicParentSubcatData[parentSubcat].subcat_name,
        });

        // setting teacher Basic Results
        for (let teacherObj of this.teacherResults) {
          if (
            basicParentSubcatData[parentSubcat].premiumSubcatMapped ===
            teacherObj.subcategory_id
          ) {
            teacherbasicResults.push({
              premiumMappedSubcatId:
                basicParentSubcatData[parentSubcat].premiumSubcatMapped,
              score: teacherObj.result,
            });
          }
        }
      }

      let subcatresults = {
        catName: catName,
        catId: catId,
        premiumMappedSubcat_id: JSON.stringify(basicAndPremiumMapped_ids),
        parentsResults: JSON.stringify(basicParentResultData),
        teacherResults: JSON.stringify(teacherbasicResults),
        average: average,
      };
      // console.log("ready to send basic data : ",subcatresults);

      this.router.navigate(['/subcat-collated', this.class_id], {
        queryParams: subcatresults,
      });
    } else {
      // premium

      // console.log(
      //   'categoryResult in goto Subcatcollated : ',
      //   this.CategoryResult
      // );
      

      let teacherSubcatResults: { subcatId: number; score: number }[] = [];

      // setting the parent subcat-results
      for (let obj of this.premiumParentsResults) {
        if (obj.cat_name === catName) {
          // console.log('parents subcat result: ', obj.result);
          this.parentsResults = JSON.stringify(obj.result);
        }
      }

      this.sessionPremiumTeacherResults = window.sessionStorage.getItem(
        'premiumTeacherResults'
      )!;
      // console.log(
      //   'sessionteacher results : ',
      //   this.sessionPremiumTeacherResults
      // );

      if (this.sessionPremiumTeacherResults) {
        this.teacherResults = JSON.parse(this.sessionPremiumTeacherResults);
        // console.log(
        //   'this.teacherResults set from session Storage : ',
        //   this.teacherResults
        // );
      }
      // setting the teacher subcat-results
      for (let obj of this.teacherResults) {
        if (catId === obj.category_id) {
          let result = { subcatId: 0, score: 0 };
          result.subcatId = obj.subcategory_id;
          result.score = obj.result;
          teacherSubcatResults.push(result);
        }
      }

      // console.log('teacher subcat results : ', teacherSubcatResults);
      let subcatresults = {
        catName: catName,
        catId: catId,
        premiumMappedSubcat_id: 'null',
        parentsResults: this.parentsResults,
        teacherResults: JSON.stringify(teacherSubcatResults),
        average: average,
      };
      // console.log('mode while going to collated: ', this.mode);
      // console.log('class id while going to subcatCollated: ', this.class_id);

      // console.log('data being sent to subcat-collated : ', subcatresults);

      this.router.navigate(['/subcat-collated', this.class_id], {
        queryParams: subcatresults,
      });
    }
  }

  goToSubcatSummary(cat_name: string, result: number) {
    // ** for PREMIUM
    if (result !== 0 && this.user_plan === 'premium') {
      for (let i = 0; i < this.CategoryResult.length; i++) {
        if (cat_name === this.premiumParentsResults[i].cat_name) {
          this.subcatSummaryData = this.premiumParentsResults[i];
          break;
        }
      }
      // console.log('passing object for premium is : ', this.subcatSummaryData);
      // using sessionStorage because subcatSummaryData is difficult to send through querryParams.
      window.sessionStorage.setItem(
        'subcatSummaryData',
        JSON.stringify(this.subcatSummaryData)
      );
      this.router.navigate(['/subcat-summary', this.class_id]);
    }

    // ** for BASIC
    if (this.user_plan === 'basic' && this.basicParentAverage !== 0) {
      this.subcatSummaryData = this.summaryReportData[0];
      // console.log('printing subcatSummaryData for basic  : ', this.subcatSummaryData);
      window.sessionStorage.setItem(
        'subcatSummaryData',
        JSON.stringify(this.subcatSummaryData)
      );
      this.router.navigate(['/subcat-summary', this.class_id]);
    }
  }
}
