import { inject, Injectable } from '@angular/core';
import { SUPERADMIN } from '../../../../config/adminConstant';
import { ApiService } from '../../../../config/api/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DomainsService {
  private apiService = inject(ApiService);

  constructor() {}

  getDomainsList(): Observable<object> {
    return this.apiService.get(`${SUPERADMIN.GET_DOMAINS.URL}`);
  }

  getDomainById(id: any): Observable<object> {
    return this.apiService.get(`${SUPERADMIN.GET_DOMAIN_BY_ID.URL}`);
  }

  createDomain(domain: any): Observable<object> {
    return this.apiService.post(`${SUPERADMIN.POST_ADD_DOMAINS.URL}`, domain);
  }

  updateDomain(id: any, domain: any) {
    return this.apiService.put(
      `${SUPERADMIN.POST_ADD_DOMAINS.URL}/{id}`,
      domain
    );
  }
}
