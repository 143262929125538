import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppComponent } from '../../../app.component';
import { FooterNavbarComponent } from '../../../shared/component/footer-navbar/footer-navbar.component';
import { NamesectionComponent } from '../../../shared/component/nameSection/namesection.component';
import { NavbarComponent } from '../../../shared/component/navbar/navbar.component';
import { SpinnerComponent } from '../../../shared/component/spinner/spinner.component';
import { HindiNumberPipe } from '../../../shared/pipe/hindi-number.pipe';
import { MilestoneService } from '../../../shared/services/api/milestone.service';
import { PopupComponent } from '../../../../shared/alerts/popup/popup.component';
import { Observable } from 'rxjs';
import { StudentData } from '../../../../state/student/student.model';
import { selectStudentObject } from '../../../../state/student/student.selector';

interface subCat {
  [x: string]: any;
  category_id: string;
  subcategory_id: number;
  cat_name: string;
  completion: string;
  created_on?: string;
  cat_name_hi?: string;
  description?: string;
}

@Component({
  selector: 'app-competencies',
  standalone: true,
  imports: [
    NavbarComponent,
    FormsModule,
    CommonModule,
    SpinnerComponent,
    FooterNavbarComponent,
    NamesectionComponent,
    TranslateModule,
    HindiNumberPipe,
    PopupComponent,
  ],
  templateUrl: './competencies.component.html',
  styleUrl: './competencies.component.css',
})
export class CompetenciesComponent {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private milestoneService = inject<MilestoneService>(MilestoneService);
  private store = inject(Store);
  private translate = inject(TranslateService);

  targetedLang: string = 'en'; // This variable is used to check the targeted lang

  params: any;
  schoolName: string = 'Global International School';
  classId: number = 1;
  cat_name: string = 'Physical Development';
  studentId: number = 1;
  studentData: any;
  domainId: number = 8;
  subCategories: subCat[] | null = [];
  status: string | null = '';
  dobYear: number = 2020;
  dobDate: number = 1;
  dobMonth: number = 1;
  age: string = '2 yrs.';
  studentName: string = 'Shyam';
  standard: string = 'Nursery';
  division: string = 'A';
  totalStudents: number = 20;
  teacherName: string = 'Mr. John Doe';
  loader: boolean = false;
  structureType: string = 'bmc';
  cgId: any;

  // for calculating piechart results
  milestones_data: any;
  peichartResult: number = 0;

  // for colorcoding of the milestones on the classroom screen
  categoryStatus: string = 'notYet';

  // studentObject$: Observable<StudentState>;

  goBackTo: string = 'compentencies';
  langProperty: string = '';
  visibleIndex: number | null = null;

  studentObject$: Observable<StudentData>;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  constructor() {
    this.studentObject$ = this.store.pipe(select(selectStudentObject));
    this.studentObject$.subscribe({
      next: (studentData: StudentData) => {
        this.studentData = studentData;
        // if (studentData) {
        this.dobDate = Number(studentData.dobDate);
        this.dobMonth = Number(studentData.dobMonth);
        this.dobYear = Number(studentData.dobYear);
        this.studentId = studentData.studentId;
        this.classId = studentData.classId;

        this.age = studentData.age;
        this.studentName = studentData.studentName;
        this.standard = studentData.standard;
        this.division = studentData.division;
        this.totalStudents = studentData.totalStudents;
        this.teacherName = studentData.teacherName;
        // this.short_cat_name = studentData.shortCatName;
        // }
      },
      error: err => {
        console.error('Error:', err);
      },
      complete: () => {
        console.log('Subscription completed');
      },
    });
  }

  ngOnInit() {
    this.domainId = Number(this.route.snapshot.paramMap.get('domainId'))!;
    console.log('cat_id in compentency', this.domainId);

    this.cgId = Number(this.route.snapshot.paramMap.get('cgId'))!;
    console.log('cg id in comp com', this.cgId);
    this.cat_name = this.route.snapshot.paramMap.get('cg_name')!;
    // this.cat_name = this.translate.instant(this.cat_name);
    this.studentId = Number(this.route.snapshot.paramMap.get('student_id'))!;
    this.classId = Number(this.route.snapshot.paramMap.get('class_id'))!;
    this.structureType = this.route.snapshot.paramMap.get('structure')!;

    if (typeof window !== 'undefined' && window.localStorage) {
      this.targetedLang = localStorage.getItem('targetedLang') || 'en';
      this.langProperty = `cat_name_${this.targetedLang}`;
      this.milestoneService
        .getCompentencies(
          this.cgId,
          this.classId,
          this.studentId,
          this.targetedLang
        )
        .subscribe({
          next: (res: any) => {
            console.log('subcategories in subcat: ', res.data);
            this.subCategories = res.data;
            // this.structureType = res.data;
            this.loader = true;
          },
          error: error => {
            // Handle error
            console.error('Error:', error);
          },
        });
    }
  }

  toggleDescription(index: number) {
    this.visibleIndex = this.visibleIndex === index ? null : index;
  }

  dateConverstion(d: string): string {
    const date = new Date(d);
    return date.toLocaleDateString('en-GB');
  }

  goToMilestones(subcat: any, type: string) {
    console.log('lang', this.langProperty);
    let subCatName = subcat['short_form'] + '. ' + subcat['name'];
    this.router.navigate([
      '/milestone',
      this.classId,
      this.studentId,
      this.domainId,
      this.cat_name,
      this.cgId,
      subCatName,
      type,
      subcat.id,
      this.structureType,
    ]);
  }
}
