import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SpService } from '../../../../utils/api/sp/sp.service';

@Component({
  selector: 'app-provider',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './provider.component.html',
  styleUrl: './provider.component.css',
})
export class ProviderComponent {
  private fb = inject(FormBuilder);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private SpService = inject(SpService);

  params: any;
  userForm: FormGroup;
  formFields = [
    {
      name: 'user_first_name',
      label: 'Service Provider Name',
      type: 'text',
    },
    { name: 'user_email', label: 'Email', type: 'email' },
    { name: 'user_mobile', label: 'Mobile', type: 'text' },
    { name: 'user_landline', label: 'Landline', type: 'text' },
    { name: 'user_location', label: 'Location', type: 'text' },
    { name: 'user_address', label: 'Address', type: 'textarea' },
    {
      name: 'milestone_type',
      label: 'Milestone Type',
      type: 'select',
      options: [
        { value: 1, label: 'BMC' },
        { value: 2, label: 'NCF/NEF' },
        { value: 3, label: 'CUSTMISE STRUCTURE' },
        { value: 4, label: 'HYBRID BMC-NEF/NCF' },
      ],
    },
  ];

  constructor() {
    this.params = this.route.snapshot.queryParams;
    this.params = JSON.parse(this.params.param);
    console.log('params', this.params);
    // this.formFields = this.params;

    this.userForm = this.fb.group({
      user_first_name: [this.params.user_first_name, Validators.required],
      user_email: [
        this.params.user_email,
        [Validators.required, Validators.email],
      ],
      user_mobile: [this.params.user_mobile, Validators.required],
      user_landline: [''],
      user_location: [this.params.user_location],
      user_address: [this.params.user_address],
      milestone_type: [this.params.milestone_type, Validators.required],
      // note: [this.params.note],
    });
  }

  updateRecord(): void {
    if (this.userForm.valid) {
      let data = {
        updatedData: this.userForm.value,
        providerId: this.params.user_id,
      };
      this.SpService.updateProvider(data).subscribe({
        next: (res: any) => {
          console.log('called res');
        },
      });
      console.log('Form Data:', this.userForm.value);
    }
  }
}
