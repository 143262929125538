import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from '../../../shared/component/navbar/navbar.component';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { UserService } from '../../../shared/services/utility/user.service';
import { MilestoneService } from '../../../shared/services/api/milestone.service';
import { FormsModule } from '@angular/forms';
import { SpinnerComponent } from '../../../shared/component/spinner/spinner.component';
import { FooterNavbarComponent } from '../../../shared/component/footer-navbar/footer-navbar.component';
import { NamesectionComponent } from '../../../shared/component/nameSection/namesection.component';
import { AppComponent } from '../../../app.component';

import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectStudentObject } from '../../../../state/student/student.selector';
import { StudentData } from '../../../../state/student/student.model';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HindiNumberPipe } from '../../../shared/pipe/hindi-number.pipe';
import { PopupComponent } from '../../../../shared/alerts/popup/popup.component';
interface subCat {
  [x: string]: any;
  category_id: string;
  subcategory_id: number;
  cat_name: string;
  completion: string;
  created_on?: string;
  cat_name_hi?: string;
  description?: string;
}

@Component({
  selector: 'app-subcat',
  standalone: true,
  imports: [
    NavbarComponent,
    FormsModule,
    CommonModule,
    SpinnerComponent,
    FooterNavbarComponent,
    NamesectionComponent,
    TranslateModule,
    HindiNumberPipe,
    PopupComponent,
  ],
  providers: [UserService, MilestoneService],
  templateUrl: './subcat.component.html',
  styleUrl: './subcat.component.css',
})
export class SubcatComponent {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private milestoneService = inject<MilestoneService>(MilestoneService);
  private store = inject(Store);
  private translate = inject(TranslateService);

  targetedLang: string = 'en'; // This variable is used to check the targeted lang

  params: any;
  schoolName: string = 'Global International School';
  classId: number = 1;
  cat_name: string = 'Physical Development';
  studentId: number = 1;
  studentData: StudentData | {} = {}
  cat_id: number = 8;
  subCategories: subCat[] | null = [];
  status: string | null = '';
  dobYear: number = 2020;
  dobDate: number = 1;
  dobMonth: number = 1;
  age: string = '2 yrs.';
  studentName: string = 'Shyam';
  standard: string = 'Nursery';
  division: string = 'A';
  totalStudents: number = 20;
  teacherName: string = 'Mr. John Doe';
  loader: boolean = false;
  structureType: string = 'bmc';
  short_cat_name: string = '';
  // for calculating piechart results
  milestones_data: any;
  peichartResult: number = 0;

  // for colorcoding of the milestones on the classroom screen
  categoryStatus: string = 'notYet';

  studentObject$: Observable<StudentData>;

  goBackTo: string = 'classroom';
  langProperty: string = '';
  isDescriptionVisible: boolean = false;
  visibleIndex: number | null = null;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  constructor() {
    this.studentObject$ = this.store.pipe(select(selectStudentObject));
    this.studentObject$.subscribe({
      next: (studentData: StudentData) => {
           this.studentData = studentData;
        // if (studentData) {
          this.dobDate = Number(studentData.dobDate);
          this.dobMonth = Number(studentData.dobMonth);
          this.dobYear = Number(studentData.dobYear);
          this.studentId = studentData.studentId;
          this.classId = studentData.classId;
          this.cat_id = studentData.catId;
          this.cat_name = studentData.catName;

          this.age = studentData.age;
          this.studentName = studentData.studentName;
          this.standard = studentData.standard;
          this.division = studentData.division;
          this.totalStudents = studentData.totalStudents;
          this.teacherName = studentData.teacherName;
          this.short_cat_name = studentData.shortCatName;
        // }
      },
      error: err => {
        console.error('Error:', err);
      },
      complete: () => {
        console.log('Subscription completed');
      },
    });
  }

  ngOnInit() {
    this.cat_name = this.translate.instant(this.cat_name);


    if (typeof window !== 'undefined' && window.localStorage) {
      this.targetedLang = localStorage.getItem('targetedLang') || 'en';
      this.langProperty = `cat_name_${this.targetedLang}`;
      this.milestoneService
        .getMilestoneByCategory(
          this.cat_id,
          this.classId,
          this.studentId,
          this.dobMonth,
          this.dobDate,
          this.dobYear,
          this.targetedLang
        )
        .subscribe({
          next: (res: any) => {
            this.subCategories = res.data.milestones;
            this.structureType = res.data.type;
            this.loader = true;
          },
          error: error => {
            // Handle error
            console.error('Error:', error);
          },
        });
    }
  }

  toggleDescription(index: number) {
    this.visibleIndex = this.visibleIndex === index ? null : index;
  }

  dateConverstion(d: string): string {
    const date = new Date(d);
    return date.toLocaleDateString('en-GB');
  }

  goToMilestones(subcat: any, type: string) {
    let subCat_name =
      this.structureType === 'nep'
        ? subcat['name']
        : subcat.hasOwnProperty(this.langProperty)
          ? subcat[this.langProperty]
          : subcat.cat_name;

    if (this.structureType === 'nep') {
      subCat_name =
        this.short_cat_name +
        ' - ' +
        subcat['short_form'] +
        '. ' +
        subcat['name'];

      this.router.navigate(
        [
          '/compentencies',
          this.classId,
          this.studentId,
          this.cat_id,
          this.cat_name,
          subcat.id,
          subCat_name,
          this.structureType,
        ]
      );
    } else {
      this.router.navigate(
        [
          '/milestone',
          this.classId,
          this.studentId,
          this.cat_id,
          this.cat_name,
          subcat.subcategory_id,
          subCat_name,
          type,
          0,
          this.structureType,
        ]
      );
    }
  }
}
