import { Component, inject } from '@angular/core';
import { TableComponent } from '../../../../components/table/table.component';
import { TeacherService } from '../../../../utils/api/users/teacher.service';
import { error } from 'console';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { selectUserObject } from '../../../../../state/user/user.selector';
import { Router } from '@angular/router';

@Component({
  selector: 'admin-teachers',
  standalone: true,
  imports: [TableComponent, ReactiveFormsModule, CommonModule, FormsModule],
  templateUrl: './teachers.component.html',
  styleUrl: './teachers.component.css',
})
export class TeachersComponent {
  teacherService = inject(TeacherService);
  store = inject(Store);
  router = inject(Router);

  pageSizeControl = new FormControl(10); // Default value
  paginatedTeachers: any[] = [];
  currentPage: number = 1;
  pageSize: number = 10;
  totalPages: number = 0;
  pageSizes: number[] = [5, 10, 25, 50];

  currentIndex : number = (this.currentPage - 1) * this.pageSize;
  tableHeaders: string[] = [
    'teacher name',
    'class room',
    'teacher gender',
    'teacher code',
    'teacher mobile',
    'teacher email',
    'Active/Deactive',
  ];

  columnKeys: string[] = [
    'teacher_name',
    'class_room',
    'teacher_gender',
    'teacher_code',
    'teacher_mobile',
    'teacher_email',
    'is_active',
  ];
  schoolId: number = 2;
  userName: string = 'Shyam DEMO';
  userObject$: any;

  constructor() {}

  ngOnInit(): void {
    this.userObject$ = this.store.select(selectUserObject);
    
        this.userObject$.subscribe({
          next: (data: { firstName: string; lastName: string , school_id :any }) => {
            this.userName = data.firstName + ' ' + data.lastName;
            this.schoolId = Number(data.school_id);

          },
          error: (err: any) => {
            console.error('Error:', err);
          },
          complete: () => {
            console.log('Subscription completed');
          },
        });
    this.pageSizeControl.valueChanges.subscribe(size => {
      this.pageSize = size || 5;
      this.loadTeacherData(this.currentPage);
    });

    this.loadTeacherData(this.currentPage);
  }

  loadTeacherData(pageNo: number): void {
    if (typeof window !== 'undefined' && window.localStorage) {

    this.teacherService
      .getTeacherList(this.schoolId, pageNo, this.pageSize)
      .subscribe({
        next: (response: any) => {
          console.log("records",response.data.records);
          this.currentIndex = (this.currentPage - 1) * this.pageSize;
          this.paginatedTeachers = response.data.records || [];
          this.totalPages = Math.ceil(response.data.total / this.pageSize);
        },
        error: error => {
          console.error('Error fetching teacher list:', error);
        },
      });
    }
  }

  goToPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.loadTeacherData(page);
    }
  }

  AddTeacher() :void {
    this.router.navigate(['/admin/teacher/add'],{
      queryParams : {
        isEditMode : false,
      }
    })
  }

  editTeacherData() :void{
    this.router.navigate(['/teacher/edit',],{
      queryParams : {
        isEditMode : false,
      }
    })
  }
}
