@if (loader) {
  <div class="spinner">
    <app-navbar
      [teacherName]="teacherName"
      [schoolNavBar]="true"
      [standard]="standard"
      [division]="division"
      [totalStudents]="totalStudents"
      [class_id]="classId"
      [goBackTo]="goBackTo"
      [studentData]="params"></app-navbar>
    <section
      class="black-font d-flex align-items-center justify-content-center flex-column">
      <app-namesection
        [studentName]="studentName"
        [age]="age"></app-namesection>
      <span class="domainName fw-bold text-center">{{ cat_name }}</span>
      <div class="container">
        <span class="catName fw-bold text-center">{{
          'Compentencies' | translate
        }}</span>

        @for (subcat of subCategories; track subcat; let i = $index) {
          <div class="card-container">
            <div
              class="card subcat black-font"
              [attr.role]="'none'"
              [ngClass]="{ completedCard: subcat.completion === 'completed' }">
              <span class="subcatName">
                {{
                 subcat['short_form']
                }}.
                {{ subcat['name']}}
                <span class="i-symbol" (click)="toggleDescription(i)">ⓘ</span>
                @if (visibleIndex === i) {
                  <app-popup 
                  [visible]="visibleIndex !== null" 
                  [description]="subcat['short_form'] + '. ' + subcat.description || ''" 
                  (closePopup)="toggleDescription(visibleIndex)">
                </app-popup>
                }
              </span>
              @if (subcat.completion === 'completed') {
                <div>
                  <div class="completed">
                    <img
                      class="done_icon"
                      src="../../../../assets/img/done.png"
                      alt="completed" />
                    <img
                      class="edit_icon"
                      src="../../../../assets/img/edit_icon.png"
                      alt="edit"
                      (click)="goToMilestones(subcat, 'edit')"
                      role="none" />
                  </div>
                </div>
              } @else {
                <!-- <button class="completeNowBtn btn-warning">Complete now </button> -->
                <div class="notCompleted me-2">
                  <img
                    class="completeNow_icon"
                    (click)="goToMilestones(subcat, 'add')"
                    src="../../../../assets/img/complete_now.png"
                    alt="completeNow" />
                </div>
              }
              <ng-template #notCompleted>
                <div class="notCompleted me-2">
                  <img
                    class="completeNow_icon"
                    src="../../../../assets/img/complete_now.png"
                    alt="completeNow" />
                </div>
              </ng-template>
            </div>
          </div>
        }
      </div>
    </section>
    <app-footer-navbar [class_id]="classId"></app-footer-navbar>
  </div>
} @else {
  <app-spinner></app-spinner>
}
