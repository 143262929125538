export const AUTHENTICATE = {
  POST_LOGIN: {
    URL: 'authenticate/login',
  },
  GET_MASKING_STATUS : {
    URL : 'authenticate/get-masking-status'
  }
};

export const CLASS = {
  POST_CLASS_BY_TEACHER_ID: {
    URL: 'class/get-class-by-teacher-id',
  },
  GET_CLASS_BY_CLASS_ID: {
    URL: 'class/get-class-by-class-id',
  }
};

export const IMAGE = {
  POST_PRESIGNED: {
    URL: 'image/presigned',
  },
  POST_INSERT_IMAGE: {
    URL: 'image/insert-image-path',
  },
  GET_IMAGES: {
    URL: 'image/saved-images',
  },
};

export const USER = {
    GET_TEACHER_INFO_BY_ID :{
      URL : 'teacher/teacher-info-by-id'
    },
    GET_SUMMARY_REPORT :{
      URL : 'result/summary-report'
    },
    GET_CHECK_PARENT_PLAN :{
      URL : 'parents/check-parent-plan'
    }
}

export const MILESTONE = {
  POST_MILESTONES_SUBCATEGORY: {
    URL: 'milestone/milestones-sub-category',
  },
  POST_MILESTONES_CATEGORY: {
    URL: 'milestone/milestones-category',
  },
  POST_SAVE_MILESTONE: {
    URL: 'milestone/save-milestone',
  },
  GET_SAVED_MILESTONE: {
    URL: 'milestone/get-saved-milestone',
  },
  PUT_UPDATE_SAVED_MILESTONE: {
    URL: 'milestone/update-saved-milestone',
  },
  GET_COMPENTENCIES : {
   URL : 'milestone/compentencies'
  }
};

export const PARENTS = {
    GET_PARENT_BY_CLASS_ID :{
     URL : 'parents/get-parents-by-classId'
    },
    PUT_UPDATE_SCHOOL_CONNECT : {
        URL : 'parents/school-connect-request'
    },
    CHECK_RESULT_SHARING:{
      URL: 'parents/check-results-sharing-status'
    },
    TOGGLE_RESULT_SHARING:{
      URL: 'parents/toggle-result-sharing'
    }
}

export const PORTFOLIO = {
    GET_TEMPLATE : {
        URL : 'template/portfolio_template'
    }
}

export const TRACKER = {
    GET_TRACKER : {
        URL : 'tracker/get-progress-report-results'
    },
    GET_TRACKER_CATEGORYWISE: {
      URL: 'tracker/get-progress-report-subcategorywise'
    }
}

export const RESULT = {
    GET_PORTFOLIO : {
        URL : 'result/portfolio'
    },
    GET_OVERALL_CLASS_RESULT : {
        URL :'result/get_class_overall_result'
    },
    GET_COMPLETED_MILESTONE : {
        URL : 'result/completed-milestones'
    },
    POST_SUBCAT_RESULT :{
        URL : 'result/subcat-result'
    }

}

export const NEPRESULTS = {
     GET_NEP_CURRICULUM_RESULT : {
      URL : 'nep-results/get-curriculum-goal-results'
     },
     GET_NEP_COMPENTENCY_RESULT : {
      URL : 'nep-results/get-compentency-results'
     }
}

export const TEACHER = {
  PUT_CHILD_DETAILS : {
    URL : 'student/putstudents'
  },
  GET_CHILD_DETAILS : {
    URL : 'student/getstudents'
  }
}