import {
  HttpErrorResponse,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { Route } from '@angular/router';


export const httpErrorInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        // Optionally, clear any stored tokens
        localStorage.removeItem('authToken');

        // Redirect to auth page, optionally with a return URL
        const returnUrl = encodeURIComponent(window.location.pathname);
        // this.route.navigate(['/auth'], { queryParams: { returnUrl } });
      }
      return throwError(() => error);
    })
  );
};
