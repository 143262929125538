@if (loader) {
  <div class="spinner">
    <app-navbar
      [teacherName]="teacherName"
      [schoolNavBar]="true"
      [standard]="standard"
      [division]="division"
      [totalStudents]="totalStudents"
      [class_id]="classId"
      [goBackTo]="goBackTo"></app-navbar>
    <section
      class="black-font d-flex align-items-center justify-content-center flex-column">
      <app-namesection
        [studentName]="studentName"
        [age]="age"></app-namesection>
      <span class="catName fw-bold text-center">{{ cat_name }}</span>
      <div class="container">
        @if (structureType === 'nep') {
          <span class="catName fw-bold text-center">{{
            'CurricularGoals' | translate
          }}</span>
        }

        @for (subcat of subCategories; track subcat; let i = $index) {
          <div class="card-container">
            <div
              class="card subcat black-font"
              [attr.role]="'none'"
              [ngClass]="{
                completedCard:
                  subcat.completion === 'completed' ||
                  subcat.completion === 'Yes'
              }">
              <span class="subcatName">
                {{
                  structureType === 'nep'
                    ? subcat['short_form']
                    : (i + 1 | hindiNumber)
                }}.
                {{
                  structureType === 'nep'
                    ? subcat['name']
                    : subcat.hasOwnProperty(langProperty)
                      ? subcat[langProperty]
                      : subcat.cat_name
                }}
                @if( structureType === 'nep'){
                  <span class="i-symbol" (click)="toggleDescription(i)">ⓘ</span>
                }
                @if (visibleIndex === i) {
                  <app-popup 
                  [visible]="visibleIndex !== null" 
                  [description]="subcat['short_form'] + '. ' + subcat.description || ''" 
                  (closePopup)="toggleDescription(visibleIndex)">
                </app-popup>
                }
              </span>

              @if (
                subcat.completion === 'completed' || subcat.completion === 'Yes'
              ) {
                <div>
                  <div class="completed">
                    <img
                      class="done_icon"
                      src="../../../../assets/img/done.png"
                      alt="completed" />

                    <img
                      class="edit_icon"
                      src="../../../../assets/img/edit_icon.png"
                      alt="edit"
                      (click)="goToMilestones(subcat, 'edit')"
                      role="none" />
                  </div>
                </div>
              } @else {
                <!-- <button class="completeNowBtn btn-warning">Complete now </button> -->
                @if (structureType === 'nep') {
                  @if (subcat.completion === 'pending') {
                    <button
                      class="completeNowBtn btn-warning"
                      (click)="goToMilestones(subcat, 'add')">
                      In Progress
                    </button>
                  } @else {
                    <button class="completeNowBtn btn-warning"
                    (click)="goToMilestones(subcat, 'add')"
                    >Start</button>
                  }
                } @else {
                  <div class="notCompleted me-2">
                    <img
                      class="completeNow_icon"
                      src="../../../../assets/img/complete_now.png"
                      (click)="goToMilestones(subcat, 'add')"
                      alt="completeNow" />
                  </div>
                }
              }

              <ng-template #notCompleted>
                <div class="notCompleted me-2">
                  <img
                    class="completeNow_icon"
                    src="../../../../assets/img/complete_now.png"
                    alt="completeNow" />
                </div>
              </ng-template>
            </div>
          </div>
        }
      </div>
    </section>
    <app-footer-navbar [class_id]="classId"></app-footer-navbar>
  </div>
} @else {
  <app-spinner></app-spinner>
}
