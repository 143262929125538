import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, Observable, throwError, map } from 'rxjs';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUrl = environment.apiUrl;
  token: string | null = '';
  assessmentPeriodId: number | null = null;

  constructor(private http: HttpClient) {}

  private setHeaders(customHeaders?: { [key: string]: string }): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if(typeof window !== 'undefined' && window.localStorage && localStorage.getItem('token')){

      this.token = localStorage.getItem('token');
     
      headers = headers.set('Authorization','Bearer ' + this.token);
      this.assessmentPeriodId = Number(localStorage.getItem('assessmentPeriodId')) || null;
    }
    console.log("customHeaders in setHeaders", customHeaders);

    if (customHeaders) {
      Object.entries(customHeaders).forEach(([key, value]) => {
        headers = headers.set(key, value);
      });
    }

    console.log("headers",headers);
    return headers;
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'An unknown error occurred';
    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Client-side error: ${error.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Server-side error: ${error.status} - ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }

  get<T>(endpoint: string, customHeaders?: { [key: string]: string } , params?: Record<string, any>,): Observable<T> {

    return this.http
      .get<T>(`${this.baseUrl}/${endpoint}`, {
        headers: this.setHeaders(customHeaders),
        params,
      })
      .pipe(catchError(this.handleError));
  }

  post<T, U>(endpoint: string, body: U, customHeaders?: { [key: string]: string }): Observable<T> {
   
    return this.http
      .post<T>(`${this.baseUrl}/${endpoint}`, body, {
        headers: this.setHeaders(customHeaders),
      })
      .pipe(catchError(this.handleError));
  }

  put<T, U>(endpoint: string, body: U, customHeaders?: { [key: string]: string }): Observable<T> {
    return this.http
      .put<T>(`${this.baseUrl}/${endpoint}`, body, {
        headers: this.setHeaders(customHeaders),
      })
      .pipe(catchError(this.handleError));
  }

  delete<T>(endpoint: string, customHeaders?: { [key: string]: string }): Observable<T> {
    return this.http
      .delete<T>(`${this.baseUrl}/${endpoint}`, {
        headers: this.setHeaders(customHeaders),
      })
      .pipe(catchError(this.handleError));
  }

  downloadFile(endpoint: string, params?: Record<string, any>, customHeaders?: { [key: string]: string }) {
    return this.http.get(`${this.baseUrl}/${endpoint}`, {
      headers: this.setHeaders(customHeaders),
      params,
      responseType: 'blob' as 'json' // Treat response as a file (binary)
    });
  }
}
