import { Component } from '@angular/core';

@Component({
  selector: 'app-edit-milestone',
  standalone: true,
  imports: [],
  templateUrl: './edit-milestone.component.html',
  styleUrl: './edit-milestone.component.css'
})
export class EditMilestoneComponent {

}
