import { Routes } from '@angular/router';
import { LoginpageComponent } from './main/authenticate/loginpage/loginpage.component';
import { ClassRoomComponent } from './main/core/class-room/class-room.component';
import { SubcatComponent } from './main/core/subcat/subcat.component';
import { MilestoneComponent } from './main/core/milestone/milestone.component';
import { SpinnerComponent } from './shared/component/spinner/spinner.component';
import { PageNotFoundComponent } from './shared/component/page-not-found/page-not-found.component';
import { AuthGuard } from './shared/services/utility/auth-guard.service';
import { ResultComponent } from './main/results/result/result.component';
import { NavbarComponent } from './shared/component/navbar/navbar.component';
import { SubcatResultsComponent } from './main/results/subcat-results/subcat-results.component';
import { DashboardComponent } from './main/core/dashboard/dashboard.component';
import { PortfolioComponent } from './main/core/portfolio/portfolio.component';
import { TeacherprofileComponent } from './main/core/teacherprofile/teacherprofile.component';
import { ConnectWithParentsComponent } from './main/connectWithParents/connect-with-parents/connect-with-parents.component';
import { StatsResultsComponent } from './main/core/stats-results/stats-results.component';
import { InviteConfirmComponent } from './main/connectWithParents/invite-confirm/invite-confirm.component';
import { ParentShareComponent } from './main/connectWithParents/parent-share/parent-share.component';
import { SummaryReportComponent } from './main/results/summary-report/summary-report/summary-report.component';
import { SubcatSummaryComponent } from './main/results/summary-report/subcat-summary/subcat-summary.component';
import { CameraComponent } from './main/core/camera/camera.component';
import { SubcatCollatedComponent } from './main/results/summary-report/subcat-collated/subcat-collated.component';
import { ProgressTrackerComponent } from './main/results/progress-tracker/progress-tracker.component';

import { AdminDashboardComponent } from '../admin/pages/admin/dashboard/dashboard.component';
import { LayoutComponent } from '../admin/layout/admin-layout/layout.component';
import { ClassroomsComponent } from '../admin/pages/admin/classrooms/classrooms.component';
import { TeachersComponent } from '../admin/pages/admin/teacher/teachers/teachers.component';
import { StudetnsComponent } from '../admin/pages/admin/studetns/studetns.component';
import { RemindersComponent } from '../admin/pages/admin/reminders/reminders.component';
import { SuperAdminComponent } from '../admin/layout/super-admin/super-admin.component';
import { SuperDashboardComponent } from '../admin/pages/super-admin/super-dashboard/super-dashboard.component';
import { ListMilestoneComponent } from '../admin/pages/super-admin/milestone/list-milestone/list-milestone.component';
import { AddMilestoneComponent } from '../admin/pages/super-admin/milestone/add-milestone/add-milestone.component';
import { EditMilestoneComponent } from '../admin/pages/super-admin/milestone/edit-milestone/edit-milestone.component';
import { ListProviderComponent } from '../admin/pages/super-admin/service-providers/list-provider/list-provider.component';
import { AddProviderComponent } from '../admin/pages/super-admin/service-providers/add-provider/add-provider.component';
import { AddSchoolsComponent } from '../admin/pages/super-admin/service-providers/schools/add-schools/add-schools.component';
import { ListSchoolsComponent } from '../admin/pages/super-admin/service-providers/schools/list-schools/list-schools.component';
import { ListUsersComponent } from '../admin/pages/super-admin/service-providers/schools/users/list-users/list-users.component';
import { AddUsersComponent } from '../admin/pages/super-admin/service-providers/schools/users/add-users/add-users.component';
import { ListClassroomsComponent } from '../admin/pages/super-admin/service-providers/schools/classroom/list-classrooms/list-classrooms.component';
import { AddClassroomsComponent } from '../admin/pages/super-admin/service-providers/schools/classroom/add-classrooms/add-classrooms.component';
import { DetailsClassroomsComponent } from '../admin/pages/super-admin/service-providers/schools/classroom/details-classrooms/details-classrooms.component';
import { ProviderComponent } from '../admin/pages/super-admin/service-providers/provider/provider.component';
import { SchoolComponent } from '../admin/pages/super-admin/service-providers/schools/school/school.component';
import { ListLearningOutcomesComponent } from '../admin/pages/super-admin/nep/learning-outcomes/list-learning-outcomes/list-learning-outcomes.component';
import { NEPProgressCardComponent } from './main/results/NEP-progress-card/nep-progress-card/nep-progress-card.component';
import { StudentinfoComponent } from './main/core/studentinfo/studentinfo.component';

// Domains Import

import { ListDomainsComponent } from '../admin/pages/super-admin/nep/domains/list-domains/list-domains.component';
import { AddDomainsComponent } from '../admin/pages/super-admin/nep/domains/add-domains/add-domains.component';
import { CurriculumGoalsComponent } from '../admin/pages/super-admin/nep/curriculum-goals/curriculum-goals/curriculum-goals.component';
import { ListCompentenciesComponent } from '../admin/pages/super-admin/nep/competencies/list-compentencies/list-compentencies.component';
import { AddCompentenciesComponent } from '../admin/pages/super-admin/nep/competencies/add-compentencies/add-compentencies.component';
import { AddCurriculumGoalsComponent } from '../admin/pages/super-admin/nep/curriculum-goals/add-curriculum-goals/add-curriculum-goals.component';
import { AddLearningOutcomesComponent } from '../admin/pages/super-admin/nep/learning-outcomes/add-learning-outcomes/add-learning-outcomes.component';
import { CompetenciesComponent } from './main/core/competencies/competencies.component';
import { CurriculumResultComponent } from './main/results/nep/curriculum-result/curriculum-result.component';
import { CompentencyResultComponent } from './main/results/nep/compentency-result/compentency-result.component';
import { AdminMilestoneComponent } from '../admin/pages/admin/milestone/milestone.component';
import { AddEditPageComponent } from '../admin/pages/admin/teacher/add-edit-page/add-edit-page.component';

export const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'login', component: LoginpageComponent },
  {
    path: 'admin',
    component: LayoutComponent,
    children: [
      { path: 'dashboard', component: AdminDashboardComponent },
      { path: 'classroom', component: ClassroomsComponent },
      { path: 'milestone', component: AdminMilestoneComponent },
      {
        path: 'teacher',
        component: TeachersComponent,
      },
      {
        path: 'teacher/add',
        component: AddEditPageComponent,
      },
      {
        path: 'teacher/edit/:id',
        component: AddEditPageComponent,
      },

      { path: 'student', component: StudetnsComponent },
      { path: 'reminder', component: RemindersComponent },
    ],
  },
  {
    path: 'superadmin',
    component: LayoutComponent, // Separate Layout
    children: [
      { path: 'dashboard', component: SuperDashboardComponent },

      // Milestones
      { path: 'milestone', component: ListMilestoneComponent },
      { path: 'milestone/add/:id', component: AddMilestoneComponent },
      { path: 'milestone/edit/:id', component: EditMilestoneComponent },

      // Domains
      { path: 'domains', component: ListDomainsComponent },
      { path: 'domains/add', component: AddDomainsComponent },

      // Curriculum Goals
      {
        path: 'domains/curriculum_goals/add/:domainId',
        component: AddCurriculumGoalsComponent,
      },
      {
        path: 'domains/curriculum_goals/:domain_id',
        component: CurriculumGoalsComponent,
      },

      //Curriculum Goals
      {
        path: 'domains/curriculum_goals/compentencies/add/:cgid',
        component: AddCompentenciesComponent,
      },
      {
        path: 'domains/curriculum_goals/compentencies/:cgid',
        component: ListCompentenciesComponent,
      },

      // Learning Outcomes
      {
        path: 'domains/curriculum_goals/compentencies/learning-outcomes/add/:id',
        component: AddLearningOutcomesComponent,
      },
      {
        path: 'domains/curriculum_goals/compentencies/learning-outcomes/:id',
        component: ListLearningOutcomesComponent,
      },

      // Service Providers
      { path: 'service-providers', component: ListProviderComponent },
      { path: 'service-providers/add', component: AddProviderComponent },
      { path: 'service-providers/details', component: ProviderComponent },

      // Schools
      { path: 'schools', component: ListSchoolsComponent },
      { path: 'schools/add', component: AddSchoolsComponent },
      { path: 'schools/details/:id', component: SchoolComponent },

      // Users
      { path: 'users', component: ListUsersComponent },
      { path: 'users/add', component: AddUsersComponent },

      // Classrooms
      { path: 'classrooms', component: ListClassroomsComponent },
      { path: 'classrooms/add', component: AddClassroomsComponent },
      { path: 'classrooms/details/:id', component: DetailsClassroomsComponent },
    ],
  },
  {
    path: 'class-room/:class_id/:teacher_name',
    component: ClassRoomComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sub-categories/:class_id',
    component: SubcatComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'compentencies/:class_id/:student_id/:domainId/:domain_name/:cgId/:cg_name/:structure',
    component: CompetenciesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'milestone/:class_id/:student_id/:categoryId/:cat_name/:sub_id/:subcat_name/:type/:competencyId/:structure',
    component: MilestoneComponent,
    canActivate: [AuthGuard],
  },
  { path: 'spinner', component: SpinnerComponent },
  {
    path: 'result/:class_id/:student_id',
    component: ResultComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'curriculum-result/:class_id/:student_id/:domain_id/:domain_name',
    component: CurriculumResultComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'compentency-results/:class_id/:student_id/:domain_id/:domain_name/:goal_id/:goal_name',
    component: CompentencyResultComponent,
    canActivate: [AuthGuard],
  },
  { path: 'navbar', component: NavbarComponent },
  {
    path: 'subcat-results/:class_id/:student_id/:cat_id',
    component: SubcatResultsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'portfolio/:class_id/:student_id',
    component: PortfolioComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'teacherprofile',
    component: TeacherprofileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'connect-with-parents/:class_id',
    component: ConnectWithParentsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'stats-results',
    component: StatsResultsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'invite-confirm',
    component: InviteConfirmComponent,
    canActivate: [AuthGuard],
  },
  { path: 'parent-share/:class_id', component: ParentShareComponent },
  { path: 'summary-report/:class_id/:mode', component: SummaryReportComponent },
  { path: 'subcat-summary/:class_id', component: SubcatSummaryComponent },
  { path: 'camera', component: CameraComponent },
  { path: 'subcat-collated/:class_id', component: SubcatCollatedComponent },
  {
    path: 'progress-tracker/:class_id/:student_id',
    component: ProgressTrackerComponent,
  },
  {
    path: 'nep-progress-card/:class_id/:student_id',
    component: NEPProgressCardComponent,
  },
  { path: 'app-studentinfo', component: StudentinfoComponent },
  { path: '**', component: PageNotFoundComponent },
];
