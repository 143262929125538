import { Component } from '@angular/core';

@Component({
  selector: 'app-add-milestone',
  standalone: true,
  imports: [],
  templateUrl: './add-milestone.component.html',
  styleUrl: './add-milestone.component.css'
})
export class AddMilestoneComponent {

}
