import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../../config/api/api.service';
import { CLASS } from '../../../../config/constant';
@Injectable({
  providedIn: 'root',
})
export class classRoomService {
  private apiService = inject(ApiService);

  assessmentPeriodId: Number | null = null;
  userObject : any;
  schoolId : string = '0';
  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.assessmentPeriodId =
        Number(localStorage.getItem('assessmentPeriodId')) || null;
      this.userObject = localStorage.getItem('user');

      if (this.userObject) {
        this.userObject = JSON.parse(this.userObject);
        this.schoolId = this.userObject.school_id.toString();
        console.log('userObject', this.userObject);
      }
    }
  }

  /**
   * This function is used to take the all classess from the perticular teacher 
   * this function retrive all info of class and assessment period info
   * @param teacherId 
   * @returns assessment_period_id , class_id 
   * 
   */
  getClassRoomByTeacherID(teacherId: number): Observable<object> {
    console.log("this.schoolId ",this.schoolId );
    return this.apiService.post(
      `${CLASS.POST_CLASS_BY_TEACHER_ID.URL}`,
      { teacher_id: teacherId },
      { 'school-id' : this.schoolId }
    );
  }

  /**
   * This function is used to take the all class room students from the perticular class 
   * This api fire on the classroom component
   * @param classID 
   * @returns students data 
   * 
   */
  getClassById(classID: number) {
    return this.apiService.get(
      `${CLASS.GET_CLASS_BY_CLASS_ID.URL}/${classID}/${this.assessmentPeriodId}`,
      { 'school-id' : this.schoolId }
    );
  }

}

