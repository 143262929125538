import { Component, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-popup',
  standalone: true,
  imports: [],
  templateUrl: './popup.component.html',
  styleUrl: './popup.component.css',
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: 0, transform: 'scale(0.9)' })),
      transition(':enter', [
        animate('200ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ opacity: 0, transform: 'scale(0.9)' })),
      ]),
    ]),
  ],
})
export class PopupComponent {
  @Input() visible: boolean = false;
  @Input() description: string = '';
  @Output() closePopup = new EventEmitter<void>();


  constructor(){
    console.log("visible",this.visible);
    console.log("description",this.description);
    console.log("visible",this.visible);

  }
  close() {
    this.closePopup.emit();
  }
}
