import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Components Import
import { NavbarComponent } from '../../../shared/component/navbar/navbar.component';
import { FooterNavbarComponent } from '../../../shared/component/footer-navbar/footer-navbar.component';
import { NamesectionComponent } from '../../../shared/component/nameSection/namesection.component';
import { ViewImagesComponent } from '../../../shared/component/view-images/view-images.component';

// Service Imports
import { MilestoneService } from '../../../shared/services/api/milestone.service';
import { GraphService } from '../../../../service/graph/graph.service';
import { ResultsService } from '../../../shared/services/api/results.service';
import { ViewImagesService } from '../../../shared/services/utility/view-images.service';

// Modules and Lib imports
import { TranslateModule, TranslateService } from '@ngx-translate/core';

interface params {
  studentName: string;
  age: string;
  standard: string;
  division: string;
  totalStudents: number;
  teacherName: string;
  cat_name: string;
  dob_month: number;
  dob_date: number;
  dob_year: number,
  profilePhoto: string
}

@Component({
  selector: 'app-subcat-results',
  standalone: true,
  imports: [
    NavbarComponent,
    FooterNavbarComponent,
    NamesectionComponent,
    TranslateModule,
    ViewImagesComponent
  ],
  providers: [ResultsService, MilestoneService, ViewImagesService],
  templateUrl: './subcat-results.component.html',
  styleUrl: './subcat-results.component.css',
})
export class SubcatResultsComponent {
  private _resultservice = inject(ResultsService);
  private milestoneservice = inject(MilestoneService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private translate = inject(TranslateService);
  private graphService = inject(GraphService);
  private viewImageService = inject(ViewImagesService);

  schoolName: string = 'Global International School';
  teacherName: string = 'Mr. John Doe';
  standard: string = 'L.K.G';
  division: string = 'B';
  totalStudents: number = 0;
  student_id: number = 217;
  cat_id: number = 8;
  class_id: number = 1;
  studentName: string = 'studentName';
  profilePhoto: string = '';
  age: string = '2.8';
  cat_name: string = 'Development';
  loader: boolean = false;
  dob_date: number = 1;
  dob_month: number = 1;
  dob_year: number = 1;

  params: params = {
    studentName: '',
    standard: '',
    division: '',
    totalStudents: 0,
    teacherName: '',
    cat_name: '',
    age: '',
    dob_date: 0,
    dob_month: 0,
    dob_year: 0,
    profilePhoto: ''
  };
  content: boolean = false;

  subcatData: any[] = [];

  defaultRemark: string = 'No Remark';
  photoLink: string = 'https://bmc-space.blr1.cdn.digitaloceanspaces.com/';
  targetedLang: string = 'en';
  showImage: boolean = false;
  fullScreenImage: string = '';
  

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    
  }

  ngOnInit() {
    this.student_id = Number(this.route.snapshot.paramMap.get('student_id'))!;
    this.class_id = Number(this.route.snapshot.paramMap.get('class_id'))!;
    this.cat_id = Number(this.route.snapshot.paramMap.get('cat_id'))!;
    this.params = this.route.snapshot.queryParams as params;

    this.studentName = this.params.studentName;
    this.standard = this.params.standard;
    this.division = this.params.division;
    this.totalStudents = this.params.totalStudents;
    this.teacherName = this.params.teacherName;
    this.cat_name = this.params.cat_name;
    this.age = this.params.age;
    this.profilePhoto = this.params.profilePhoto;

    this.dob_date= Number(this.params.dob_date);
    this.dob_month = Number(this.params.dob_month);
    this.dob_year = Number(this.params.dob_year);
    
    if (typeof window !== 'undefined' && window.localStorage) {
      this.getResults();
    }
  }

  async getResults() {
    this.targetedLang = localStorage.getItem('targetedLang') || 'en';
    this._resultservice
      .getSubcatResult(
        this.student_id,
        this.class_id,
        this.cat_id,
        this.dob_month,
        this.dob_date,
        this.dob_year,
        this.targetedLang
      )
      .subscribe((data: any) => {
        if (data.data.length == 0) {
          this.content = true;
        } else {
          this.subcatData = data.data;
          // console.log('all data : ',this.subcatData);
          for (let obj of this.subcatData) {
            let photos: object = {};
            if (obj.images) {
              photos = JSON.parse(obj.images);
              obj.images = photos;
            }
          }

          this.graphService.createBarChart(data.data);
          // this.createChart(data.data);
        }
      });
  }

  checkImageState(image: string) {
    return image && image !== 'null';
  }

  goBack() {
    window.history.back();
  }

  viewImage( index: number ,images: string[] ) {
    this.showImage = true;
    // this.fullScreenImage = image;
    // console.log('user pressed view image $$', images , " its index is : ",index);
    // console.log('type of images array ',typeof(images));
    this.viewImageService.viewFullScreenImage(index, images , this.showImage);
  }

  // downloadImage(imageUrl: string, event: Event) {
  //   event.stopPropagation(); // Prevent closing the modal when clicking download
  //   console.log('user pressed download button ^^^', imageUrl);

  //   if (!confirm('Download this image?')) {
  //     return;
  //   } else {
  //     const isIOS = /iPad|iPhone|mac|iPod/.test(navigator.userAgent);

  //     if (isIOS) {
  //       // For iOS, open in a new tab
  //       const newTab = window.open('', '_blank');
  //       if (newTab) {
  //         newTab.document.body.innerHTML = `<img src="${imageUrl}" alt="Captured Image" style="width:auto; height:80vh;">`;
  //       }
  //     } else {
  //       // For other devices, use Blob to trigger download
  //       const byteString = atob(imageUrl.split(',')[1]);
  //       const mimeString = imageUrl
  //         .split(',')[0]
  //         .split(':')[1]
  //         .split(';')[0];

  //       const ab = new ArrayBuffer(byteString.length);
  //       const ia = new Uint8Array(ab);
  //       for (let i = 0; i < byteString.length; i++) {
  //         ia[i] = byteString.charCodeAt(i);
  //       }

  //       const blob = new Blob([ab], { type: mimeString });
  //       const link = document.createElement('a');
  //       link.href = window.URL.createObjectURL(blob);
  //       link.download = `capture-${new Date().toISOString()}.jpg`;
  //       link.click();
  //     }
  //   }
  // }
}
