<div class="container">
    <h2>{{ isEditMode ? 'Edit' : 'Add' }} Curriculum Goals</h2>
  
    <form [formGroup]="cgForm" (ngSubmit)="submitForm()">
      <div>
        <label>Name:</label>
        <input type="text" formControlName="name" />
        @if (cgForm.get('name')?.invalid && cgForm.get('name')?.touched) {
          <div>
            @if (cgForm.get('name')?.errors?.['required']) {
              <small>Name is required</small>
            }
            @if (cgForm.get('name')?.errors?.['minlength']) {
              <small>Minimum 3 characters</small>
            }
          </div>
        }
      </div>
  
      <div>
        <label>Short Name:</label>
        <input type="text" formControlName="short_form" />
      </div>
  
      <div>
        <label>Description:</label>
        <textarea formControlName="description"></textarea>
      </div>
  
      <button type="submit" [disabled]="cgForm.invalid">Save</button>
    </form>
  </div>
  