import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../../config/api/api.service';
import { TRACKER } from '../../../../config/constant';

@Injectable({
  providedIn: 'root',
})
export class ProgressTrackerService {
  private apiService = inject(ApiService);

  constructor() {}

  getProgressTrackerResults(student_id: number , class_id: number): Observable<Object> {
    return this.apiService.get(`${TRACKER.GET_TRACKER.URL}/${student_id}/${class_id}`);
  }

  getProgressTrackerCategorywise(student_id: number, class_id: number , cat_id: number):Observable<Object>{
    return this.apiService.get(`${TRACKER.GET_TRACKER_CATEGORYWISE.URL}/${student_id}/${class_id}/${cat_id}`);
  }
}
