<div id="serviceProviderList">
  <!-- Header Section with Component Title and Logged-in User's Name -->
  <section id="componentHeader" class="d-flex align-center just-start">
    <div id="componentTitle">
      <div>Service Providers</div>
      <!-- Displays the section title -->
    </div>
    <div id="teacherName">
      <span>Welcome, </span><span>{{ userName }}</span>
      <!-- Displays the logged-in user's name -->
    </div>
  </section>

  <!-- Search and Pagination Controls -->
  <div class="pagination-controls">
    <div class="search-box">
      <!-- Input field for searching service providers by name -->
      <input
        type="text"
        placeholder="Search provider name..."
        [(ngModel)]="filterData.filter_data.spname"
        (input)="loadServiceproviderData(1)" />
    </div>

    <!-- Dropdown for selecting the number of rows per page -->
    <label for="pageSize" class="rowLabel">Rows per page:</label>
    <select id="pageSize" [formControl]="pageSizeControl">
      @for (size of pageSizes; track $index) {
        <option [value]="size">{{ size }}</option>
        <!-- Options for page sizes -->
      }
    </select>
  </div>

  <!-- Table Component to Display Paginated Data -->
  <table-component
    [parentComponent] = 'componentName'
    [columns]="columnKeys"
    [headerNames]="tableHeaders"
    [data]="paginatedStudents"
    [addPageSize]="currentIndex"

    (rowClick)="navigate($event)"
    ></table-component>

  <!-- Pagination Controls -->
  <div class="pagination-controls">
    <!-- Button to navigate to the first page -->
    <button (click)="goToPage(1)" [disabled]="currentPage === 1">First</button>

    <!-- Button to navigate to the previous page -->
    <button (click)="goToPage(currentPage - 1)" [disabled]="currentPage === 1">
      Previous
    </button>

    <!-- Displays the current page number and total pages -->
    <span>Page {{ currentPage }} of {{ totalPages }}</span>

    <!-- Button to navigate to the next page -->
    <button
      (click)="goToPage(currentPage + 1)"
      [disabled]="currentPage === totalPages">
      Next
    </button>

    <!-- Button to navigate to the last page -->
    <button
      (click)="goToPage(totalPages)"
      [disabled]="currentPage === totalPages">
      Last
    </button>
  </div>
</div>
