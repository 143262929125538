<section id="horizontalBar">
  <div id="navStartDiv">
    <span class="schoolTitle">{{ schoolName }}</span>
  </div>

  <div id="navEnd">
    <img
      src="../../../assets/img/profile.png"
      alt="Profile Icon"
      id="profileIcon"
      (click)="toggleProfileMenu()" />
    @if (showProfileMenu) {
      <ul id="profileMenu">
        <li (click)="navigateTo('profile')">My Profile</li>
        <li (click)="logout()">Log Out</li>
      </ul>
    }
  </div>

  <div id="HambergerMenu" (click)="toggleHamburgerMenu()">
    <img
      [src]="
        showHamburgerMenu
          ? '../../../assets/img/close.svg'
          : '../../../assets/img/menu.png'
      "
      alt="Menu Icon" />
  </div>
</section>
