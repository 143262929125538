import { Injectable, inject } from '@angular/core';
import { ApiService } from '../../../../config/api/api.service';
import { NEPRESULTS, RESULT } from '../../../../config/constant';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ResultsService {
  private apiService = inject(ApiService);

  assessmentPeriodId: Number | null = null;
  userObject: any;
  schoolId: any;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  constructor() {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.assessmentPeriodId =
        Number(localStorage.getItem('assessmentPeriodId')) || null;

      this.userObject = localStorage.getItem('user');

      if (this.userObject) {
        this.userObject = JSON.parse(this.userObject);

        this.schoolId = this.userObject.school_id.toString();
        console.log('userObject', this.userObject);
      }
    }
  }

  getSubcatResult(
    studentId: number,
    classId: number,
    catId: number,
    dobMonth: number,
    dobDate: number,
    dobYear: number,
    targetedLang: string
  ): Observable<object> {
    return this.apiService.post(`${RESULT.POST_SUBCAT_RESULT.URL}`, {
      studentId: studentId,
      classId: classId,
      catId: catId,
      targetedLang: targetedLang,
      dobMonth: dobMonth,
      dobDate: dobDate,
      dobYear: dobYear,
      assessmentPeriodId: this.assessmentPeriodId,
    },{ 'school-id': this.schoolId });
  }

  // API for getting completed milstones and thier results

  getCompletedMilestones(
    classId: number,
    studentId: number,
    dobMonth: number,
    dobDay: number,
    dobYear: number,
    screen: string
  ): Observable<object> {
    return this.apiService.get(
      `${RESULT.GET_COMPLETED_MILESTONE.URL}/${classId}/${studentId}/${dobMonth}/${dobDay}/${dobYear}/${this.assessmentPeriodId}`,
      { 'school-id': this.schoolId }
    );
  }

  // for piechart on the dashboard
  getClassOverallResult(classId: number): Observable<object> {
    return this.apiService.get(
      `${RESULT.GET_OVERALL_CLASS_RESULT.URL}/${classId}`
    );
  }

  getPortFolio(
    classId: number,
    childID: number,
    dobMonth: number,
    dobDay: number,
    dobYear: number,
    targetedLang: string
  ): Observable<object> {
    return this.apiService.get(
      `${RESULT.GET_PORTFOLIO.URL}/${classId}/${childID}/${dobMonth}/${dobDay}/${dobYear}/${targetedLang}/${this.assessmentPeriodId}`,
      { 'school-id': this.schoolId }
    );
  }

  getNepCurriculumGoalResults (
    classId : number,
    childId : number,
    domainId : number
  ) : Observable<object> {
    return this.apiService.get(
      `${NEPRESULTS.GET_NEP_CURRICULUM_RESULT.URL}/${classId}/${childId}/${domainId}/${this.assessmentPeriodId}`    
    );
  }

  getNepCompentencyResults (
    domainId : number,
    goalId : number,
    studentId : number,
    classId :number
  ) : Observable<object> {
    return this.apiService.get(
      `${NEPRESULTS.GET_NEP_COMPENTENCY_RESULT.URL}/${domainId}/${goalId}/${studentId}/${classId}/${this.assessmentPeriodId}`    
    );
  }
}
