import { createAction, props } from '@ngrx/store';
import { student } from '../../app/shared/interfaces/common';
import { StudentData } from './student.model';
export const setStudentObject = createAction(
    '[classroom] Student info',
    props<{ studentObject : student }>() 
);



export const loadStudentData = createAction('[Student] Load Student Data', props<{ data: StudentData }>());
export const updateStudentData = createAction('[Student] Update Student Data', props<{ data: Partial<StudentData> }>());

// Action to reset or clear the global object
export const resetStudentObject = createAction('[Student Object] Reset Student Object');

