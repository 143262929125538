// global-object.reducer.ts
import { createReducer, on } from '@ngrx/store';
import { initialState, StudentState } from './student.model';
import * as StudentActions from './student.actions';
import { StudentData } from './student.model';

export const studentReducer = createReducer(
  initialState,
  on(StudentActions.loadStudentData, (state, { data }) => ({
    ...state,
    studentData: data,
  })),
  on(StudentActions.updateStudentData, (state, { data }) => ({
    ...state,
    studentData: {
      ...(state.studentData as StudentData),
      ...data,
    } as StudentData,
  }))
);


