import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CurriculumGoalsService } from '../../../../../utils/api/nep/curriculum-goals.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add-curriculum-goals',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './add-curriculum-goals.component.html',
  styleUrl: './add-curriculum-goals.component.css'
})
export class AddCurriculumGoalsComponent {
 private fb = inject(FormBuilder);
  private cgService = inject(CurriculumGoalsService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);

  cgForm!: FormGroup;
  domainId : any = 0;
  isEditMode = false;
  cgid :any;
 

  constructor() {}

  ngOnInit(): void {
    this.cgForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      short_form: [''],
      description: [''],
    });

    this.domainId = this.route.snapshot.paramMap.get('domainId');
    this.cgid = this.route.snapshot.paramMap.get('cgid');
    if (this.cgid) {
      this.isEditMode = true;
      this.cgService.getCurriculumById(this.cgid).subscribe(data => {
        if (data) {
          this.cgForm.patchValue(data);
        }
      });
    }
  }

  submitForm(): void {
    if (this.cgForm.invalid) return;

    const formData = this.cgForm.value;

    if (this.isEditMode && this.cgid) {
      this.cgService.updateCurriculumGoal(this.cgid, formData).subscribe(() => {
        this.router.navigate(['/superadmin/domains/curriculum_goals',this.domainId]);
      });
    } else {
      this.cgService.createCurriculumGoal(this.domainId , formData).subscribe(() => {
        this.router.navigate(['/superadmin/domains/curriculum_goals',this.domainId]);
      });
    }
  }
}
