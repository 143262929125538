import { Component } from '@angular/core';

@Component({
  selector: 'app-details-classrooms',
  standalone: true,
  imports: [],
  templateUrl: './details-classrooms.component.html',
  styleUrl: './details-classrooms.component.css'
})
export class DetailsClassroomsComponent {

}
