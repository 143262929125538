import { Component, inject } from '@angular/core';
import { NavbarComponent } from '../../../shared/component/navbar/navbar.component';
import { FooterNavbarComponent } from '../../../shared/component/footer-navbar/footer-navbar.component';
import { CommonModule } from '@angular/common';

import { classRoomService } from '../../../shared/services/api/class-room.service';
import { ParentsService } from '../../../shared/services/api/parents.service';
import { RouterLink } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerComponent } from '../../../shared/component/spinner/spinner.component';


import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-connect-with-parents',
  standalone: true,
  imports: [CommonModule, NavbarComponent, SpinnerComponent, FooterNavbarComponent , TranslateModule],
  providers: [classRoomService, ParentsService],
  templateUrl: './connect-with-parents.component.html',
  styleUrl: './connect-with-parents.component.css',
})
export class ConnectWithParentsComponent {
  private classroomService = inject(classRoomService);
  private parentService = inject(ParentsService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private translate = inject(TranslateService);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    // necessary to clear session storage before going inside reports section of any student.
    if (typeof window !== 'undefined' && window.localStorage) {

    window.sessionStorage.clear();
    }
   }

  userData: any;
  teacherName: string = 'teacherName';
  teacher_id: number = 1;
  class_id: number = 1;
  navbarDisplay : string = "none";
  profileImageLink: string = 'https://bmc-space.blr1.digitaloceanspaces.com/'; 
  totalStudents: number = 0;
  loader: boolean = false;

  child_parent_details: any;
  mode : string = "summary";
  goBackTo: string = 'dashboard'

  ngOnInit() {
    this.class_id = Number(this.route.snapshot.paramMap.get('class_id'))!;

    if (typeof window !== 'undefined' && window.localStorage) {
      this.userData = window.localStorage.getItem('user');
      this.userData = JSON.parse(this.userData); 


      this.teacherName = this.userData.teacher_name;
      this.teacher_id = this.userData.teacher_id;
    }

    this.getParentDetails();

  }

  onInviteClick(childId: number, schoolConnectStatus: string , parentId : number): void {
  
    if (schoolConnectStatus === 'CONNECTED') {
      console.log("child id is : ",childId);
      for (let i in this.child_parent_details) {
        if (this.child_parent_details[i].parent_id === parentId) {
         
          // Clear previous data
          window.sessionStorage.removeItem('summaryReportParams');
          
          // Navigate to parent-share with class_id as route param and parent details as query params
          this.router.navigate(['parent-share', this.class_id], {
          // this.router.navigate(['summary-report', this.class_id, this.mode], {
            queryParams: this.child_parent_details[i]
          });
           break;
        }
      }
      return;
    }
    else {
      this.sentInvite(childId, "INVITED",parentId);
    }

  }

  goToConfirmScreen(childId : number) {
      const parentDetails = this.child_parent_details.find((parent:any)  => parent.child_id === childId);
  
      if (parentDetails && typeof window !== 'undefined') {
        window.sessionStorage.setItem('parent_details', JSON.stringify(parentDetails));
        window.sessionStorage.setItem('class_id',JSON.stringify(this.class_id));
      }
  
      this.router.navigate(['/invite-confirm']);
    
  }

  // Method to get the button class based on the status
  getButtonClass(schoolConnectStatus: string): string {
    if (schoolConnectStatus === 'CONNECTED') {
      return 'greenbg';
    } else if (schoolConnectStatus === 'INVITED' ) {
      return 'yellowbg';
    } else if (schoolConnectStatus ==='ACCEPTED'){
      return 'orangebg'
    }
    
   return 'redbg'; // red color will be applied for all 'INVITE +' buttons
  }

  getParentDetails() {
    // calling getClassById api for getting student list in classroom by class_id
    // Api call for getting the students in class room
    this.parentService.getParentsByClassId(this.class_id).subscribe({
      next: (data: any) => {
        this.loader = true;

        this.child_parent_details = data.data;
        console.log(" this.child_parent_details in cwp", this.child_parent_details);


    },
      error: (error: string) => {
        console.error('Error:', error);
      },
    });
  }
  
  isInviteDisabled(schoolConnectStatus: string): boolean {
    return schoolConnectStatus === 'INVITED';
  }

  // Method to get the button text based on the status
  getButtonText(schoolConnectStatus: string): string {
    return schoolConnectStatus ? schoolConnectStatus : 'INVITE +';
  }


  sentInvite(child_id: number, status: string , parent_id : number): void {

    this.parentService.sendSchoolConnect(child_id, status , parent_id).subscribe({
      next: (data: any) => {

        if (data.status == 200) {
          this.getParentDetails();
        } else {
          alert("something went Wrong");
        }
      },
      error: (error: string) => {
        console.error('Error:', error);
      },
    });
  }

  goBack() {
    this.router.navigate(['/dashboard']);
  }
}