import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthResponse } from '../../interfaces/api';
import { AUTHENTICATE } from '../../../../config/constant';
import { ApiService } from '../../../../config/api/api.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private apiService = inject(ApiService);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  constructor() { }

  AuthenticateUser(email: string, password: string, admin: boolean): Observable<AuthResponse> {
    return this.apiService.post(
      `${AUTHENTICATE.POST_LOGIN.URL}`,
      { email: email, password: password, admin: admin }
    );
  }

  getMaskingStatus(school_id: number): Observable<object> {
    console.log("school id in service : ",school_id)
    return this.apiService.get(
      `${AUTHENTICATE.GET_MASKING_STATUS.URL}/${school_id}`
    );
  }
}
