import { Component, inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-edit-page',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './add-edit-page.component.html',
  styleUrl: './add-edit-page.component.css'
})
export class AddEditPageComponent {
  private fb = inject(FormBuilder); 
  private route = inject(ActivatedRoute);

  teacherData : any =  {
    "teacher_name": "Mrs. Renuka Patil",
    "teacher_gender": "Female",
    "teacher_code": "tearcher-56",
    "teacher_mobile": "9638521470",
    "teacher_email": "renuka@gmail.com",
    "password": "c8dd5805b1dd73c0553e0331468ee85daec0181250a442e2686fcd3c72f29ef7",
    "standard": "Nursery",
    "division": "A",
    "is_active": "Active",
    "class_room": "Nursery - A"
}
  teacherForm: FormGroup;
  isEditMode = false;
  teacherId : number = 0

  constructor() {
    this.teacherForm = this.fb.group({
      teacher_name: ['', Validators.required],
      teacher_gender: ['', Validators.required],
      teacher_code: ['', Validators.required],
      teacher_mobile: ['', Validators.required],
      teacher_email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      standard: ['', Validators.required],
      division: ['', Validators.required],
      class_room: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    console.log("running");
    this.route.paramMap.subscribe((params) => {
      this.teacherId = Number(params.get('id'));
      this.isEditMode = !!this.teacherId;
      console.log(this.isEditMode ? 'Edit Mode' : 'Add Mode', this.teacherId);
    });

    if (this.teacherData) {
      this.isEditMode = true;
      this.teacherForm.patchValue(this.teacherData);
    }
  }

  onSubmit(): void {
    if (this.teacherForm.valid) {
      if (this.isEditMode) {
        console.log('Updating teacher:', this.teacherForm.value);
      } else {
        console.log('Adding teacher:', this.teacherForm.value);
      }
    } else {
      console.log('Form is invalid');
    }
  }
}
