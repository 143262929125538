import { AfterViewInit, Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectUserObject } from '../../../../state/user/user.selector';
import { ClassCardComponent } from '../../../components/class-card/class-card.component';
import { GraphService } from '../../../../service/graph/graph.service';
import { DashboardService } from '../../../utils/api/dashboard.service';
import { utilityService } from '../../../utils/common/common.service';

@Component({
  selector: 'admin-classrooms',
  standalone: true,
  imports: [],
  templateUrl: './classrooms.component.html',
  styleUrl: './classrooms.component.css',
})
export class ClassroomsComponent implements AfterViewInit {
  graphService = inject(GraphService);
  store = inject(Store);
  dahboardService = inject(DashboardService);
  utils = inject(utilityService);
  
  userName: string = '';
  userObject$: any;
  schoolId: number = 2;
  chartIDs : string[] = [];
  classes: any = [];
  imageAlt: string = 'NA';
  completed : number = 0;
  pending : number = 0;
  inProgress : number = 0;
  standard: any;
  division: any;

  ngOnInit() {
    this.userObject$ = this.store.select(selectUserObject);

    this.userObject$.subscribe({
      next: (data: { firstName: string; lastName: string , school_id : any}) => {
        console.log('userData', data);
        this.userName = data.firstName + ' ' + data.lastName;
        this.schoolId = Number(data.school_id);
      },
      error: (err: any) => {
        console.error('Error:', err);
      },
      complete: () => {
        console.log('Subscription completed');
      },
    });
  }

  ngAfterViewInit() {
    /** Declare here as we know that the this lifecycle method init agfter view init  */
    if (typeof window !== 'undefined' && window.localStorage) {

    this.dahboardService.getClassRooms(this.schoolId, 1).subscribe({
      next: (data: any) => {
        this.classes = data.data.classes;
        this.completed = data.data.percentage.completed;
        this.pending = data.data.percentage.pending;
        this.inProgress = data.data.percentage.notStarted;
        const highestResultObj = this.classes.reduce((max : any, obj : any) => obj.overAllResult > max.overAllResult ? obj : max, this.classes[0]);

        this.standard = highestResultObj.standard;
        this.division = highestResultObj.division;
        ;
        console.log("highestResultObj",highestResultObj);
        try {
          /** Used the timeout so that this function should be fired at last movement of lifyecycle */
          setTimeout(() => {
            for (const item of this.classes) {
              let donutColor: number = 0;

              if (item.overAllResult >= 0 && item.overAllResult <= 25) {
                donutColor = 0xfbaf55;
              } else if (item.overAllResult >= 26 && item.overAllResult <= 65) {
                donutColor = 0xfcf969;
              } else if (
                item.overAllResult >= 66 &&
                item.overAllResult <= 100
              ) {
                donutColor = 0xa9fa68;
              } else {
                console.warn(
                  `Invalid overAllResult value for class ${item.class_id}:`,
                  item.overAllResult
                );
                continue;
              }

              let chartId = `classCharts${item.class_id}`;
              const chartElement = document.getElementById(chartId);

              if (chartElement) {
                this.chartIDs.push(chartId);
                this.graphService.createDonutChart(
                  chartId,
                  item.overAllResult,
                  donutColor,
                  "centerLogo"
                );
              } else {
                console.error(
                  `Element not found for id: chartsNot${item.class_id}`,
                  chartId
                );
              }
            }
          });
        } catch (error) {
          console.log('err', error);
        }
      },
      error: (error: string) => {
        console.error('Error:', error);
      },
    });

  }
  }

  onFilterSelect(event: Event): void {
    this.graphService.removeGraphs();
    const selectedValue = (event.target as HTMLSelectElement).value;
    console.log('Selected Value:', selectedValue);
    this.dahboardService.getClassRooms(this.schoolId, 1).subscribe({
      next: (data: any) => {
        this.classes = data.data.classes;
        try {
          /** Used the timeout so that this function should be fired at last movement of lifyecycle */
          setTimeout(() => {
            for (const item of this.classes) {
              let donutColor: number = 0;

              if (item.overAllResult >= 0 && item.overAllResult <= 25) {
                donutColor = 0xfbaf55;
              } else if (item.overAllResult >= 26 && item.overAllResult <= 65) {
                donutColor = 0xfcf969;
              } else if (
                item.overAllResult >= 66 &&
                item.overAllResult <= 100
              ) {
                donutColor = 0xa9fa68;
              } else {
                console.warn(
                  `Invalid overAllResult value for class ${item.class_id}:`,
                  item.overAllResult
                );
                continue;
              }

              let chartId = `classCharts${item.class_id}`;
              const chartElement = document.getElementById(chartId);

              if (chartElement) {
                this.chartIDs.push(chartId);
                this.graphService.createDonutChart(
                  chartId,
                  item.overAllResult,
                  donutColor,
                  "centerLogo"
                );
              } else {
                console.error(
                  `Element not found for id: chartsNot${item.class_id}`,
                  chartId
                );
              }
            }
          });
        } catch (error) {
          console.log('err', error);
        }
      },
      error: (error: string) => {
        console.error('Error:', error);
      },
    });
  }

  imageAltFunction(teacherName : string ) : string{
   return this.utils.getInitials(teacherName);
  }
}

