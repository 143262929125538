import { Component } from '@angular/core';

@Component({
  selector: 'admin-reminders',
  standalone: true,
  imports: [],
  templateUrl: './reminders.component.html',
  styleUrl: './reminders.component.css'
})
export class RemindersComponent {

}
