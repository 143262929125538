import { Component, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

/** Service And Component Imports */
import { SpService } from '../../../../utils/api/sp/sp.service';
import { TableComponent } from '../../../../components/table/table.component';

/** Store Imports */
import { Store } from '@ngrx/store';
import { selectUserObject } from '../../../../../state/user/user.selector';

/** Interface Imports */
import { filterData } from '../../../../utils/interfaces/common';

@Component({
  selector: 'app-list-provider',
  standalone: true,
  imports: [TableComponent, ReactiveFormsModule, CommonModule, FormsModule],
  templateUrl: './list-provider.component.html',
  styleUrl: './list-provider.component.css',
})
export class ListProviderComponent {
  /** Dependency Injection */
  private SpService = inject(SpService); // Injecting service for fetching provider data
  private store = inject(Store); // Injecting NgRx store for state management
  private router = inject(Router);

  /** Form Control for Page Size Selection */
  pageSizeControl = new FormControl(10);

  /** Pagination & Table Data */
  paginatedStudents: any[] = []; // Stores the paginated student data
  currentPage: number = 1; // Current page number
  pageSize: number = 10; // Number of records per page
  totalPages: number = 0; // Total number of pages
  pageSizes: number[] = [5, 10, 25, 50]; // Available options for page size selection

  /** Calculated Index for Data Display */
  currentIndex: number = (this.currentPage - 1) * this.pageSize;

  /** Table Headers and Column Mapping */
  tableHeaders: string[] = ['Provider Name', 'Mobile', 'Milestone', 'Plan'];
  columnKeys: string[] = ['user_first_name', 'user_mobile', 'milestone_type', 'plan'];
  componentName : string = "sp"

  /** User & School Information */
  schoolId: number = 2; // Default school ID
  userName: string = ''; // Logged-in user’s name
  userObject$: any; // Observable for user data from NgRx store

  /** Filter Criteria for API Request */
  filterData: filterData = {
    page: 1,
    spcount: true,
    filter_data: {
      spname: '',
      location: '',
      category: 0,
      subcategory: 0,
    },
  };

  constructor() {}

  ngOnInit(): void {
    /** Fetch user details from the NgRx store */
    this.userObject$ = this.store.select(selectUserObject);

    /** Subscribe to user data and update local variables */
    this.userObject$.subscribe({
      next: (data: { firstName: string; lastName: string; school_id: any }) => {
        console.log('User Data:', data);
        this.userName = data.firstName + ' ' + data.lastName; // Set user full name
        this.schoolId = Number(data.school_id); // Convert school ID to a number
      },
      error: (err: any) => {
        console.error('Error fetching user data:', err);
      },
      complete: () => {
        console.log('User data subscription completed.');
      },
    });

    /** Handle page size changes */
    this.pageSizeControl.valueChanges.subscribe((size) => {
      this.pageSize = size || 5; // Default to 5 if size is not set
      this.loadServiceproviderData(this.currentPage); // Reload data with new page size
    });

    /** Load initial service provider data */
    this.loadServiceproviderData(this.currentPage);
  }

  /**
   * Get the row clicked data from the table componet
   * and navigate to perticular component
   */
  navigate(data : object){
    console.log("data",data);
   this.router.navigate(['/superadmin/service-providers/details'], { queryParams : { param : JSON.stringify(data)} });
  }

  /**
   * Fetches the list of service providers from the API
   * @param pageNo - The page number to fetch
   */
  loadServiceproviderData(pageNo: number): void {
    if (typeof window !== 'undefined' && window.localStorage) {
      // Update filter criteria
      this.filterData.page = pageNo;
      this.filterData.limit = this.pageSize;

      // Call API to fetch service providers
      this.SpService.getProviderList(this.filterData).subscribe({
        next: (response: any) => {
          this.currentIndex = (this.currentPage - 1) * this.pageSize; // Update index for display
          console.log('API Response:', response);
          this.paginatedStudents = response.data.data || []; // Store fetched data
          this.totalPages = Math.ceil(response.data.spcount / this.pageSize); // Calculate total pages
        },
        error: (error) => {
          console.error('Error fetching service provider list:', error);
        },
      });
    }
  }

  /**
   * Handles pagination navigation
   * @param page - The page number to navigate to
   */
  goToPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page; // Update current page
      this.loadServiceproviderData(page); // Fetch new page data
    }
  }
}
