@if (loader) {
<div class="spinner">
  <app-navbar [teacherName]="teacherName" [standard]="standard" [division]="division" [totalStudents]="totalStudents"
    [goBackTo]="goBackTo"></app-navbar>
  @if(openUploadWindow){
      <app-upload-photos
        [openUploadWindow]="openUploadWindow"
        [maxPhotoCount]="maxPhotoCount"
        [studentName]="studentName"
        [subcat_name]="subcatName"
        [studentId]="studentId"
        [subcatId]="subcatId"
        [catId]="catId"
        [dob_date]="dob_date"
        [dob_month]="dob_month"
        [dob_year]="dob_year"
        [purpose]="purpose"
        (closeUploadWindow)="windowStatus($event)"></app-upload-photos>
  }
  <section class=" studentsList black-font d-flex align-items-center justify-content-center mt-8 flex-column "
    (scroll)="onScroll()">
    <!-- <div class="d-flex align-items-center flex-wrap justify-content-between w-75 mb-1">
      <p>{{ standard }} - {{division}}</p>
      <p>TOTAL Students - {{ totalStudents }}</p>
    </div> -->
    @for (student of students; track student; let i = $index) {
    <div class="studentCard">
      <div class="cardHeader border-bottom-0  d-flex align-center just-between border-success" role="presentation"
        [ngClass]="{'cardHeader':i%2===0 , 'cardHeader1': i%2!==0 }">
        <!-- profile photo -->
         @if(student.profile_image){
          <div class="photo d-flex flex-col align-end " (click)="uploadProfilePhoto(student.id, student.child_name, student.child_last_name)">
            <img class="profilePhoto" [src]="imageLink + student.profile_image" alt="">
            <div class="uploadPhoto flex-cent">+</div>
          </div>
         } @else{
          <div class="photo d-flex flex-col align-end " (click)="uploadProfilePhoto(student.id, student.child_name, student.child_last_name)">
            <img class="profilePhoto" src="../../../../assets/img/defaultProfilePhoto.png" alt="">
            <div class="uploadPhoto flex-cent">+</div>
          </div>
         }
        

        <!-- Student name and age-->
        <div class="nameAge flex-cent flex-col"  (click)="studentinfo(student)" role="presentation">
          <h5 class="sName">{{ (i + 1) | hindiNumber }}. {{student.child_name}}
            {{student.child_last_name}}</h5>
          <h6 class="studentAge ">{{calculateAge(student.child_born_year, student.child_born_month,
            student.child_born_date)}}</h6>
        </div>

        <button class="resultButton flex-cent"
          (click)="goToResult(student.id, student.child_name , student.child_last_name , calculateAge(student.child_born_year, student.child_born_month, student.child_born_date) , student.child_born_month, student.child_born_date, student.child_born_year , student.profile_image)">
          <!-- Displaying resultbutton  -->
          <img id="resultIcon" src="../../../../assets/img/ResultIcon.png" alt="img">
        </button>
      </div>
      <div class="text-success">
        <ul class="shortCatName-section">
          @for (cat of student.results; track cat) {
          <li role="presentation"
            (click)="goToSubcats(cat.cat_id, cat.cat_name, student.id, student.child_name, student.child_last_name, student.child_born_year, student.child_born_month, student.child_born_date, calculateAge(student.child_born_year, student.child_born_month, student.child_born_date ), cat.short_cat_name)"
            class="circular_cat d-flex align-center just-center rounded-circle me-2 col-sm-6 pb-0  shadow bg-body text-dark text-center"
            [ngClass]="{'pending': cat.completion === 'pending', 'completed': cat.completion === 'completed'}">
            {{ cat.short_cat_name | translate}}
          </li>
          }
        </ul>
      </div>
    </div>
    }
  </section>
  <app-footer-navbar [class_id]="class_id"></app-footer-navbar>
</div>
} @else {
<app-spinner></app-spinner>
}