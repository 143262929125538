<div id="sideBarNav"
 class="sidebar"
 [ngClass]="{ 'sidebar-collapsed': isSidebarCollapsed }">
  <div class="sidebar-header">
    <a href="/admin" class="sidebar-logo">
      @if (isSidebarCollapsed) {
        <img src="../../../assets/icons/icon-72x72.png" alt="" />
      } @else {
        <img src="../../../assets/img/samLogo(2).png" alt="" />
      }
    </a>
  </div>

  <button
    class="sidebar-toggle-btn"
    [ngClass]="{ collapsed: isSidebarCollapsed }"
    (click)="toggleSidebar()">
    @if (isSidebarCollapsed) {
      <img
        class="arrowImg"
        src="../../../assets/img/chevron-right-24.png"
        alt=""
        srcset="" />
    } @else {
      <img
        class="arrowImgReverse"
        src="../../../assets/img/chevron-right-24.png"
        alt=""
        srcset="" />
    }
  </button>

  <div class="sidebar-menu">
    <ul>
      @for (item of filteredMenuItems; track $index) {
        <li
          class="sidebar-menu-item"
          (click)="selectComponent(item.label)"
          
          [ngClass]="{ active: item.label === selectedRoute }">
          <a [routerLink]="item.route">
            <img class="icon" [src]="item.icon" alt="" />
            <span class="sidebar-item-text">{{ item.label }}</span>
            <!-- @if (item.label === selectedRoute) {
              <img
                class="arrowImg"
                src="../../../assets/img/chevron-right-24.png"
                alt=""
                srcset="" />
            } -->
          </a>
         
        </li>
      }
    </ul>
  </div>

   <div class="logOut-Div">
    <img src="../../../assets/img/log-out.png" alt="" srcset="">
    <button (click)="logout()"> Log Out</button>
   </div>
</div>
