import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GraphService } from '../../../../../service/graph/graph.service';
// import { params } from '../../../../shared/interfaces/common';
import { ResultsService } from '../../../../shared/services/api/results.service';
import { NavbarComponent } from '../../../../shared/component/navbar/navbar.component';
import { NamesectionComponent } from '../../../../shared/component/nameSection/namesection.component';
import { CommonModule } from '@angular/common';
import { FooterNavbarComponent } from "../../../../shared/component/footer-navbar/footer-navbar.component";
import { PopupComponent } from "../../../../../shared/alerts/popup/popup.component";

interface params {
  studentName: string;
  age: string;
  standard: string;
  division: string;
  totalStudents: number;
  teacherName: string;
  cat_name: string;
  dob_month: number;
  dob_date: number;
  dob_year: number;
  profilePhoto: string;
}
@Component({
  selector: 'curriculum-result',
  standalone: true,
  imports: [
    NavbarComponent,
    TranslateModule,
    NamesectionComponent,
    CommonModule,
    FooterNavbarComponent,
    PopupComponent
],
  templateUrl: './curriculum-result.component.html',
  styleUrl: './curriculum-result.component.css',
})
export class CurriculumResultComponent implements OnInit {
  private nepResultService = inject(ResultsService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private graphService = inject(GraphService);
  private translate = inject(TranslateService);

  schoolName: string = 'Global International School';
  teacherName: string = 'Mr. John Doe';
  standard: string = 'L.K.G';
  division: string = 'B';
  totalStudents: number = 0;
  student_id: number = 217;
  domainId: number = 8;
  class_id: number = 1;
  studentName: string = 'studentName';
  profilePhoto: string = '';
  age: string = '2.8';
  cat_name: string = 'Development';
  loader: boolean = false;
  dob_date: number = 1;
  dob_month: number = 1;
  dob_year: number = 1;

  params: params = {
    studentName: '',
    standard: '',
    division: '',
    totalStudents: 0,
    teacherName: '',
    cat_name: '',
    age: '',
    dob_date: 0,
    dob_month: 0,
    dob_year: 0,
    profilePhoto: '',
  };
  content: boolean = false;

  subcatData: any[] = [];

  targetedLang: string = 'en';
  domainName: string = '';
  curriculum: any;

  isDescriptionVisible : boolean = false;
  visibleIndex: number | null = null;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  ngOnInit() {
    this.student_id = Number(this.route.snapshot.paramMap.get('student_id'))!;
    this.class_id = Number(this.route.snapshot.paramMap.get('class_id'))!;
    this.domainId = Number(this.route.snapshot.paramMap.get('domain_id'))!;
    this.domainName = this.route.snapshot.paramMap.get('domain_name')!;
    this.params = this.route.snapshot.queryParams as params;

    this.studentName = this.params.studentName;
    this.standard = this.params.standard;
    this.division = this.params.division;
    this.totalStudents = this.params.totalStudents;
    this.teacherName = this.params.teacherName;
    this.cat_name = this.params.cat_name;
    this.age = this.params.age;
    this.profilePhoto = this.params.profilePhoto;

    this.dob_date = Number(this.params.dob_date);
    this.dob_month = Number(this.params.dob_month);
    this.dob_year = Number(this.params.dob_year);

    if (typeof window !== 'undefined' && window.localStorage) {
      this.getResults();
    }
  }

  async getResults() {
    this.targetedLang = localStorage.getItem('targetedLang') || 'en';
    this.nepResultService
      .getNepCurriculumGoalResults(this.student_id, this.class_id, this.domainId)
      .subscribe((data: any) => {
        if (data.data.length == 0) {
          this.content = true;
        } else {
          this.curriculum = data.data;
          console.log('all data : ', this.curriculum);

          this.curriculum.forEach((item: any) => {
            console.log('');
            item.total = Math.round(Number(item.total) / Number(item.count));
          });
          console.log('after map : ', this.curriculum);

          // for (let obj of this.curriculum) {
          //   let photos: object = {};
          //   if (obj.images) {
          //     photos = JSON.parse(obj.images);
          //     obj.images = photos;
          //   }
          // }

          // this.graphService.createBarChart(data.data);
          // this.createChart(data.data);
        }
      });
  }

  goBack() {
    window.history.back();
  }

  toggleDescription(index: number) {
    this.visibleIndex = this.visibleIndex === index ? null : index;
  }

  getScoreLabel(score: any): string {
    if (score <= 25) return 'Emerging';
    if (score >= 26 && score <= 65) return 'Developing';
    if (score >= 66 && score <= 89) return 'Proficient';
    return 'Mastery';
  }

  getProgressColor(total: any): string {
    if (total <= 25) return '#FBAF55';
    if (total >= 26 && total <= 65) return '#FCF969';
    return '#A9FA68';
  }

  goTo(goalId :any , goalName : any) {
    this.router.navigate([
      'compentency-results',
      this.class_id,
      this.student_id,
      this.domainId,
      this.domainName,
      goalId,
      goalName
    ],{queryParams : this.params });
  }
}
