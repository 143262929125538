import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CurriculumGoalsService } from '../../../../../utils/api/nep/curriculum-goals.service';
import { TableComponent } from '../../../../../components/table/table.component';
import { selectUserObject } from '../../../../../../state/user/user.selector';

@Component({
  selector: 'app-curriculum-goals',
  standalone: true,
  imports: [TableComponent, ReactiveFormsModule],
  templateUrl: './curriculum-goals.component.html',
  styleUrl: './curriculum-goals.component.css',
})
export class CurriculumGoalsComponent {
  private store = inject(Store); // Injecting NgRx store for state management
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private curriculumService = inject(CurriculumGoalsService);

  /** Form Control for Page Size Selection */
  pageSizeControl = new FormControl(10);
  domainId: any;

  /** Pagination & Table Data */
  curriculumGoals: any[] = []; // Stores the paginated student data
  currentPage: number = 1; // Current page number
  pageSize: number = 10; // Number of records per page
  totalPages: number = 0; // Total number of pages
  pageSizes: number[] = [5, 10, 25, 50]; // Available options for page size selection

  /** Calculated Index for Data Display */
  currentIndex: number = (this.currentPage - 1) * this.pageSize;

  /** Table Headers and Column Mapping */
  tableHeaders: string[] = ['Curriculum Goal', 'Description', 'Short Name'];
  columnKeys: string[] = ['name', 'description', 'short_form'];
  componentName: string = 'sp';

  /** User & School Information */
  schoolId: number = 2; // Default school ID
  userName: string = ''; // Logged-in user’s name
  userObject$: any; // Observable for user data from NgRx store

  ngOnInit() {
    this.domainId = this.route.snapshot.paramMap.get('domain_id');
    this.userObject$ = this.store.select(selectUserObject);

    /** Subscribe to user data and update local variables */
    this.userObject$.subscribe({
      next: (data: { firstName: string; lastName: string; school_id: any }) => {
        console.log('User Data:', data);
        this.userName = data.firstName + ' ' + data.lastName; // Set user full name
      },
      error: (err: any) => {
        console.error('Error fetching user data:', err);
      },
      complete: () => {
        console.log('User data subscription completed.');
      },
    });
    this.loadCurriculumGoals();
  }

  loadCurriculumGoals(): void {
    if (typeof window !== 'undefined' && window.localStorage) {
      // Update filter criteria
      // Call API to fetch service providers
      this.curriculumService
        .getCurriculumGoalsBYDomainId(this.domainId)
        .subscribe({
          next: (response: any) => {
            console.log('response', response);
            this.curriculumGoals = response.data;
          },
          error: error => {
            console.error('Error fetching service provider list:', error);
          },
        });
    }
  }

  navigate(data: any) {
    this.router.navigate([
      '/superadmin/domains/curriculum_goals/compentencies',
      data.id,
    ]);
  }

  addCurriculumGoals() {
    this.router.navigate([
      '/superadmin/domains/curriculum_goals/add',
      this.domainId,
    ]);
  }
}
