<app-navbar
  [teacherName]="teacherName"
  [standard]="standard"
  [division]="division"
  [totalStudents]="totalStudents"
  [navbarDisplay]="'NO'"></app-navbar>
<section id="curriculumGoals" class="d-flex just-around align-center flex-col">
  <app-namesection
    [studentName]="studentName"
    [profilePhoto]="profilePhoto"
    [age]="age"></app-namesection>
    <div class="flex-cent flex-col headdings">
        <span>{{ domainName | translate }}</span>
        <span>Curriculum Goals-wise Results</span>
      </div>

  <div class="topSection black-font d-flex just-around align-center flex-col">
   
      @for (obj of curriculum; track $index; let i = $index) {
        <div class="progress-container" (click)="goTo(obj.id , obj.name)">
            <!-- Upper Section (Label & Score) -->
            <div class="upper-section d-flex just-between align-center">
              <span class="name">
                <span class="short-form">{{obj['short_form']}}</span>
                <span>{{ obj.name }}</span>
            </span>
              <span class="infoButton" (click)="toggleDescription(i)">ⓘ</span>
              @if(visibleIndex === i){
                <app-popup 
                [visible]="visibleIndex !== null" 
                [description]="obj.short_form + obj.description || ''" 
                (closePopup)="toggleDescription(visibleIndex)">
              </app-popup>
              }
              
            </div>
          
            <!-- Lower Section (Progress Bar) -->
             <div class="progress-div flex-cent"
             [ngClass]="{
                'red': obj['total'] <= 25,
                'yellow': obj['total'] >= 26 && obj['total'] <= 65,
                'green': obj['total'] >= 66 && obj['total'] <= 90,
              }">
                <div class="progress-wrapper">
                    <div class="progress-bar" [ngStyle]="{ 'width': obj.total + '%', 'background-color': getProgressColor(obj.total) }"></div>
                  </div>
                  <span class="percentage"> {{ obj.total }}%</span>

             </div>

           
          </div>
        
      }
  </div>
</section>

<app-footer-navbar [class_id]="class_id"></app-footer-navbar>