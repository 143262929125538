import { ActionReducer, INIT, UPDATE } from '@ngrx/store';

const SYNCED_KEYS = ['userObject', 'studentObject'];

export function sessionStorageSyncMetaReducer<T>(
  reducer: ActionReducer<T>
): ActionReducer<T> {
  return (state: T | undefined, action): T => {
    let hydratedState: Partial<T> = { ...state };

    if (typeof window !== 'undefined' && window.localStorage) {
      if (action.type === INIT || action.type === UPDATE) {
        SYNCED_KEYS.forEach(key => {
          const storedState = sessionStorage.getItem(key);
          if (storedState) {
            try {
              hydratedState[key as keyof T] = JSON.parse(storedState);
            } catch {
              hydratedState[key as keyof T] = state?.[key as keyof T];
            }
          }
        });
        return hydratedState as T;
      }

      const nextState = reducer(state, action);

      // Save each synced state to sessionStorage
      SYNCED_KEYS.forEach(key => {
        if (nextState[key as keyof T] !== undefined) {
          sessionStorage.setItem(
            key,
            JSON.stringify(nextState[key as keyof T])
          );
        }
      });

      return nextState;
    }
    return reducer(state, action);
  };
}
