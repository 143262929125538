<app-navbar [navbarDisplay]="navbarDisplay" [class_id]="class_id" [goBackTo]="goBackTo"></app-navbar>
@if(openUploadWindow){
    <app-upload-photos
      [openUploadWindow]="openUploadWindow"
      [maxPhotoCount]="maxPhotoCount"
      [studentName]="studentName"
      [subcat_name]="subcatName"
      [studentId]="studentId"
      [subcatId]="subcatId"
      [catId]="catId"
      [dob_date]="dob_date"
      [dob_month]="dob_month"
      [dob_year]="dob_year"
      [purpose]="purpose"
      (closeUploadWindow)="windowStatus($event)"></app-upload-photos>
}
<div class="parent-container flex-cent flex-col">

    <div class="header-card d-flex align-center just-between">
        <!-- Profile Image with Click Event -->
        <div class="profile-photo d-flex align-end just-center"
            (click)="uploadProfilePhoto(child_id, child_name, child_last_name)">
            <img [src]="profile_image ? (imageLink + profile_image) : '../../../../assets/img/defaultProfilePhoto.png'"
                alt="Profile Photo">
                
            <div class="uploadPhoto flex-cent">+</div>

        </div>

        <!-- Name and Age Section -->
        <div class="details flex-cent flex-col">
            <div class="nameAge d-flex just-around">
                <h3 class="name">{{child_name}}</h3>
                <h5 class="age">{{calculateAge(dob_year, dob_month, dob_date)}}</h5>
            </div>
        </div>
    </div>
      
    <!-- Parent Details in a Separate White Box -->
    <div class="parent-details-container">
        <div class="parent-details">
            <h6><strong>Parents:</strong>    {{ (parent_first_name ? parent_first_name : ' ') + ' ' + (parent_last_name ? parent_last_name : ' ') }}</h6>
            <h6><strong>Email:</strong>{{ parent_email ? parent_email : '--' }}</h6>
            <h6><strong>Contact No.:</strong>{{ parent_contact ? parent_contact : '--' }}</h6>
        </div>
      </div>
      

    <form [formGroup]="studentForm" class="formCss" (ngSubmit)="updateChildDetails()">
            <h2>Student Details</h2>

            <label>Birthday:</label>
            <input type="date" formControlName="dob" required><br>  

            <div class="height-weight-container">
                <div class="form-group">
                    <label for="height">Height:</label>
                    <input type="text" id="height" formControlName="height" />
                    <!-- <select id="heightUnit" formControlName="heightUnit" (change)="convertHeight()">
                        <option value="cm">Cm</option>
                        <option value="ft">Ft</option>
                    </select> -->
                </div>
                <div class="form-group">    
                    <label for="weight">Weight:</label>
                    <input type="text" id="weight" formControlName="weight">
                    <span class="unit">Kg</span>
                </div>
            </div>
            

            <h4>Student Favourites</h4>
            <label>Colour:</label>
            <input type="text" formControlName="fav_color">
            <label>Food:</label>
            <input type="text" formControlName="fav_food">
            <label>Animal:</label>
            <input type="text" formControlName="fav_animal">
            <label>Bird:</label>
            <input type="text" formControlName="fav_bird">
            <label>Flower:</label>
            <input type="text" formControlName="fav_flower">
            <label>Outdoor Game:</label>
            <input type="text" formControlName="fav_outdoor_game">
            <label>Indoor Game:</label>
            <input type="text" formControlName="fav_indoor_game">

            <h4>Glimpses of {{child_name}}</h4>
            <div class="photo-upload-wrapper">
                <label class="upload-photo-label" 
                       (click)="uploadProfilePhoto(child_id, child_name, child_last_name)">
                    Photo: <div class="photo-input">
                    <div class="uploadPhoto flex-cent" >+</div>
                </div>
                </label>
            </div>
            
            
            
            <label>Family Photo:</label>
            <input type="file" (change)="onFileSelected($event, 'family_photo')">
            @if (familyPhotoUrl) {
              <img [src]="familyPhotoUrl" alt="Family Photo" width="100">
            }
            
            <label>Drawing of Himself:</label>
            <input type="file" (change)="onFileSelected($event, 'my_photo_drawing')">
            @if (myPhotoDrawingUrl) {
              <img [src]="myPhotoDrawingUrl" alt="Drawing of Himself" width="100">
            }
            
            <label>Drawing of His Family:</label>
            <input type="file" (change)="onFileSelected($event, 'family_photo_drawing')">
            @if (familyPhotoDrawingUrl) {
              <img [src]="familyPhotoDrawingUrl" alt="Family Drawing" width="100">
            }
            
            <div class="button-group">
                <button type="cancel" (click)="goBack()">Cancel</button>
                <button type="submit">Save</button>
            </div>
        </form>
        
    </div>

    <!-- <app-footer-navbar></app-footer-navbar> -->