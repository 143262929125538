<div class="container">
  <h2>{{ isEditMode ? 'Edit' : 'Add' }} Domain</h2>

  <form [formGroup]="domainForm" (ngSubmit)="submitForm()">
    <div>
      <label>Name:</label>
      <input type="text" formControlName="name" />
      @if (domainForm.get('name')?.invalid && domainForm.get('name')?.touched) {
        <div>
          @if (domainForm.get('name')?.errors?.['required']) {
            <small>Name is required</small>
          }
          @if (domainForm.get('name')?.errors?.['minlength']) {
            <small>Minimum 3 characters</small>
          }
        </div>
      }
    </div>

    <div>
      <label>Short Name:</label>
      <input type="text" formControlName="shortName" />
    </div>

    <div>
      <label>Description:</label>
      <textarea formControlName="description"></textarea>
    </div>

    <button type="submit" [disabled]="domainForm.invalid">Save</button>
  </form>
</div>
