import { Injectable } from '@angular/core';
import { Roles } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly ROLE_KEY = 'role';
  private currentRole: Roles = Roles.Admin;

  constructor() {
    if (typeof window !== 'undefined' && window.localStorage) {
      const storedRole = sessionStorage.getItem(this.ROLE_KEY);
      this.currentRole = storedRole ? (storedRole as Roles) : Roles.Admin;
    }
  }

  

  setRole(role: Roles) {
    console.log("role",role);
    this.currentRole = role;
    sessionStorage.setItem(this.ROLE_KEY, role);
  }

  getRole(): Roles {
    return this.currentRole;
  }
}
