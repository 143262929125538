import { Component, OnInit, inject } from '@angular/core';
import { NavbarComponent } from '../../../shared/component/navbar/navbar.component';
import { NgClass } from '@angular/common';
import { classRoomService } from '../../../shared/services/api/class-room.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerComponent } from '../../../shared/component/spinner/spinner.component';
import { FooterNavbarComponent } from '../../../shared/component/footer-navbar/footer-navbar.component';
import { MilestoneService } from '../../../shared/services/api/milestone.service';
import { UploadPhotosComponent } from '../../../shared/component/upload-photos/upload-photos.component';
import { ResultsService } from '../../../shared/services/api/results.service';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HindiNumberPipe } from '../../../shared/pipe/hindi-number.pipe';
import { ToastService } from '../../../shared/services/alert/toast.service';
import { loadStudentData, setStudentObject } from '../../../../state/student/student.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-class-room',
  standalone: true,
  providers: [classRoomService, MilestoneService, ResultsService],
  templateUrl: './class-room.component.html',
  styleUrl: './class-room.component.css',
  imports: [
    NgClass,
    NavbarComponent,
    SpinnerComponent,
    FooterNavbarComponent,
    UploadPhotosComponent,
    TranslateModule,
    HindiNumberPipe,
  ],
})
export class ClassRoomComponent implements OnInit {
  private _classRoomService = inject(classRoomService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private translate = inject(TranslateService);
  private resultService = inject(ResultsService);
  private toastService = inject(ToastService);
  private store = inject(Store)

  schoolName: string = 'SchoolName';
  teacherName: string = '';
  studentName: string = '';
  studentLastName: string = '';
  result: any = [];
  students: any = [];
  totalStudents: number = 20;
  class_id: number = 1;
  loader: boolean = false;
  standard: string = 'Classroom';
  division: string = 'A';

  page = 1;
  loading = false;
  private readonly childsPerListLimit = 5;

  backToDash: boolean = true;

  openUploadWindow: boolean = false;
  maxPhotoCount: number = 1; // limit for uploading profile photo. only one photo can be uploaded
  dob_date: number = 0;
  dob_month: number = 0;
  dob_year: number = 0;
  catId: number = 0;
  subcatId: number = 0;
  studentId: number = 0;
  subcatName: string = '';
  purpose: string = '';
  imageLink: string = 'https://bmc-space.blr1.digitaloceanspaces.com/';
  
  //`childProfile/${studentID}/${obj.fileName}`

  goBackTo: string = 'dashboard';

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  // schoollogo :string;
  constructor() {}

  ngOnInit() {
    this.class_id = Number(this.route.snapshot.paramMap.get('class_id'))!;
    this.teacherName = String(this.route.snapshot.paramMap.get('teacher_name'));

    this.loadChildDetails();
  }

  loadChildDetails() {
    if (this.loading) return;
    this.loading = true;

    if (typeof window !== 'undefined' && window.localStorage) {
      this._classRoomService.getClassById(this.class_id).subscribe({
        next: data => {
          this.result = data;

          this.result.data.students.forEach((element: any) => {
            element.results = JSON.parse(element.results);
          });

          this.students.push(...this.result.data.students);
          this.page++;


          this.schoolName = this.result.data.school_name;
          this.class_id = this.result.data.class_id;
          this.standard = this.result.data.standard;
          this.division = this.result.data.division;
          this.totalStudents = this.students.length;

          // loading student details
          // console.log('this.students after pushing :', this.students);

          this.loader = true;
        },
        error: (error: string) => {
          console.error('Error:', error);
        },
      });
    }
  }

  onScroll(): void {
    const container = document.querySelector('.studentsList') as HTMLElement;
    if (
      container.scrollTop + container.clientHeight >=
      container.scrollHeight
    ) {
      this.loadChildDetails();
    }
  }

  uploadProfilePhoto(
    studentId: number,
    studentName: string,
    studentLastName: string
  ) {
    // console.log('user pressed profile photo %');
    this.openUploadWindow = true;

    if (!studentLastName) {
      // console.log("student last name is null: ", studentLastName);
    } else {
      studentName = studentName + ' ' + studentLastName;
    }
    // these parameters will be sent to uplaodPhotos component using @Input.
    this.purpose = 'profile';
    this.studentId = studentId;
    this.studentName = studentName;
    this.studentLastName = studentLastName;
  }

  windowStatus(Window: boolean) {
    // true: upload photos window is open, false: is closed
    this.openUploadWindow = Window;
    // console.log('Received value of window status from child:', Window);
  }

  studentinfo(studentData: any): void {
    console.log("Navigating with Student Data:", studentData , studentData.child_born_date , studentData.dob_month); // Log studentData here

  
    this.router.navigate(['/app-studentinfo'], {
      queryParams: {
        class_id : this.class_id,
        child_id: studentData.id,
        child_name: studentData.child_name,
        child_last_name: studentData.child_last_name,
        dob_date: studentData.child_born_date,
        dob_month: studentData.child_born_month,
        dob_year: studentData.child_born_year,
        profile_image: studentData.profile_image
      }
    });
  }
  
  goToResult(
    student_id: number,
    studentName: string,
    studentLastName: string,
    child_age: string,
    dobMonth: number,
    dobDate: number,
    dobYear: number,
    profileImage: string
  ): void {
    let screen = 'classroom';

    this.resultService
      .getCompletedMilestones(
        this.class_id,
        student_id,
        dobMonth,
        dobDate,
        dobYear,
        screen
      )
      .subscribe((data: any) => {
        if (data.data.length === 0) {
          this.toastService.showAlert(
            this.translate.instant('result.emptyRemark'),
            this.translate.instant('Note')
          );
        } else {
          // atleast one or more than one results present
          if (studentLastName) {
            studentName = studentName + ' ' + studentLastName;
          }

          this.router.navigate(['/result', this.class_id, student_id], {
            queryParams: {
              studentName: studentName,
              age: child_age,
              profilePhoto: profileImage,
              schoolName: this.schoolName,
              totalStudents: this.totalStudents,
              standard: this.standard,
              division: this.division,
              teacherName: this.teacherName,
              dob_month: dobMonth,
              dob_date: dobDate,
              dob_year: dobYear,
            },
          });
        }
      });
  }

  goToSubcats(
    categoryId: number,
    cat_name: string,
    student_id: number,
    studentName: string,
    studentLastName: string,
    child_born_year: number,
    child_born_month: number,
    child_born_date: number,
    child_age: string,
    short_cat_name : string
  ): void {
    if (!studentLastName) {
      // console.log("student last name is null: ", studentLastName);
    } else {
      studentName = studentName + ' ' + studentLastName;
    }

    const studentData = {
      studentName: studentName,
      age: child_age,
      catId : categoryId,
      catName : cat_name,
      classId: Number(this.class_id),
      studentId: student_id,
      dobYear: child_born_year,
      dobMonth: child_born_month,
      dobDate: child_born_date,
      schoolName: this.schoolName,
      totalStudents: this.totalStudents,
      standard: this.standard,
      division: this.division,
      teacherName: this.teacherName,
      shortCatName : short_cat_name,
    };

    this.store.dispatch(loadStudentData({ data : studentData }));

    this.router.navigate(
      ['/sub-categories', this.class_id]
    );
  }

  /**
   * For  calculating the age we needed these 3 parameters
   * @param birthYear
   * @param birthMonth
   * @param birthDate
   * @returns
   */

  calculateAge(birthYear: number, birthMonth: number, birthDate: number) {
    // For calculaitng  the age we needed the current date and birth date we are getting that from below two lines
    const currentDate = new Date();
    const birthDateObj = new Date(birthYear, birthMonth - 1, birthDate);

    // Thses  variables are stor ing the difference of birthdate and current date
    // We will get the age with the help of thses variables
    const yearsDiff = currentDate.getFullYear() - birthDateObj.getFullYear();
    const monthsDiff = currentDate.getMonth() - birthDateObj.getMonth();
    const daysDiff = currentDate.getDate() - birthDateObj.getDate();

    // for storing the age
    let age = '';

    /**
     * If the month difference is less than 0 or have same month
     * Then we need to add 12 in month differen as it shows that the current month is the birthmonth of child
     * And birth date isn't come yet
     */

    if (monthsDiff < 0 || (monthsDiff === 0 && daysDiff < 0)) {
      // as the birthday isn't come yet so wee need to subtract it with 1
      let year = yearsDiff - 1;
      age = `${this.translate.instant(`num.${year}`)}${this.translate.instant('y')} `;
      if (monthsDiff < 0) {
        age += `${this.translate.instant(`num.${12 + monthsDiff}`)}${this.translate.instant('m')}`;
      }
    } else {
      // AS mention above if the birth date is passed in the current year we just need to assign both to age vaiable
      age = `${this.translate.instant(`num.${yearsDiff}`)}${this.translate.instant('y')} `;
      if (monthsDiff > 0) {
        age += ` ${this.translate.instant(`num.${monthsDiff}`)}${this.translate.instant('m')}`;
      }
    }
    return age;
  }
}
