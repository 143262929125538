<div class="container">
  <h2>Add Learning Outcome</h2>

  <form  [formGroup]="learningOutcomeForm" (ngSubmit)="onSubmit()">

    <label>Name</label>
    <input type="text" formControlName="name" required />

    <label>Description</label>
    <textarea formControlName="description" required></textarea>

    <label>Age Group</label>
    <input type="text" formControlName="ageGroup" required />

    <label>Class</label>
    <select formControlName="toClass" required>
      @for (class of classes; track $index) {
        <option [value]="class">{{ class }}</option>
      }
    </select>

    <label>Difficulty Level</label>
    <select formControlName="difficultyLevel" required>
      @for (level of difficultyLevels; track $index) {
        <option [value]="level.value">
          {{ level.label }}
        </option>
      }
    </select>

    <label>Upload Image</label>
    <input type="file" (change)="onFileChange($event)" />

    @if (imagePreview) {
      <div>
        <img [src]="imagePreview" alt="Preview" class="preview" />
      </div>
    }

    <button type="submit" [disabled]="learningOutcomeForm.invalid">Save</button>
  </form>
</div>
