import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TableComponent } from '../../../../../components/table/table.component';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

import { DomainsService } from '../../../../../utils/api/nep/domains.service';
import { selectUserObject } from '../../../../../../state/user/user.selector';

@Component({
  selector: 'app-list-domains',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TableComponent],
  templateUrl: './list-domains.component.html',
  styleUrl: './list-domains.component.css',
})
export class ListDomainsComponent {
  private store = inject(Store); // Injecting NgRx store for state management
  private router = inject(Router);
  private domainService = inject(DomainsService);

  /** Form Control for Page Size Selection */
  pageSizeControl = new FormControl(10);

  /** Pagination & Table Data */
  domains: any[] = []; // Stores the paginated student data
  currentPage: number = 1; // Current page number
  pageSize: number = 10; // Number of records per page
  totalPages: number = 0; // Total number of pages
  pageSizes: number[] = [5, 10, 25, 50]; // Available options for page size selection

  /** Calculated Index for Data Display */
  currentIndex: number = (this.currentPage - 1) * this.pageSize;

  /** Table Headers and Column Mapping */
  tableHeaders: string[] = ['Domain Name', 'Description', 'Short Name'];
  columnKeys: string[] = ['name', 'description', 'short_form'];
  componentName: string = 'sp';

  /** User & School Information */
  schoolId: number = 2; // Default school ID
  userName: string = ''; // Logged-in user’s name
  userObject$: any; // Observable for user data from NgRx store

  ngOnInit() {
    this.userObject$ = this.store.select(selectUserObject);
    
        /** Subscribe to user data and update local variables */
        this.userObject$.subscribe({
          next: (data: { firstName: string; lastName: string; school_id: any }) => {
            console.log('User Data:', data);
            this.userName = data.firstName + ' ' + data.lastName; // Set user full name
            this.schoolId = Number(data.school_id); // Convert school ID to a number
          },
          error: (err: any) => {
            console.error('Error fetching user data:', err);
          },
          complete: () => {
            console.log('User data subscription completed.');
          },
        });
    this.loadNepDomains();
  }

  loadNepDomains(): void {
    if (typeof window !== 'undefined' && window.localStorage) {
      // Update filter criteria
      // Call API to fetch service providers
      this.domainService.getDomainsList().subscribe({
        next: (response: any) => {
          console.log('response', response);
          this.domains = response.data;
        },
        error: error => {
          console.error('Error fetching service provider list:', error);
        },
      });
    }
  }

  navigate(data: any) {
    console.log("data",data);
    this.router.navigate(['/superadmin/domains/curriculum_goals',data.id]);
  }

  addDomain() {
    this.router.navigate(['/superadmin/domains/add']);
  }
}
