import { inject, Injectable } from '@angular/core';
import { ApiService } from '../../../../config/api/api.service';
import { ADMIN } from '../../../../config/adminConstant';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeacherService {
  apiService = inject(ApiService);

  constructor() { }

   getTeacherList(schoolID : number , pageNo : number , limit : number) : Observable<object>{
     return this.apiService.get(
        `${ADMIN.GET_TEACHER_LIST.URL}/${schoolID}/${pageNo}/${limit}`
     )
   }
}
