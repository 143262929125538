import { Component, inject } from '@angular/core';
import { FormControl , ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

/** Service And Component Imports */
import { SpService } from '../../../../../utils/api/sp/sp.service';
import { TableComponent } from '../../../../../components/table/table.component';

/** Store Imports */
import { Store } from '@ngrx/store';
import { selectUserObject } from '../../../../../../state/user/user.selector';

/** Interface Imports */
import { learningOutcomefilterData  } from '../../../../../utils/interfaces/common';
import { ActivatedRoute, Router } from '@angular/router';
import { LearningOutcomeService } from '../../../../../utils/api/nep/learning-outcome.service';


@Component({
  selector: 'app-list-learning-outcomes',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TableComponent],
  templateUrl: './list-learning-outcomes.component.html',
  styleUrl: './list-learning-outcomes.component.css'
})
export class ListLearningOutcomesComponent {
 /** Dependency Injection */
 // Injecting service for fetching learning outcomes
  private store = inject(Store); // Injecting NgRx store for state management
  private router = inject(Router);
  private learningOutcomeService = inject(LearningOutcomeService);
  private route  = inject(ActivatedRoute);

  /** Form Control for Page Size Selection */
  pageSizeControl = new FormControl(10);
  compentenceId : any = 0;
  /** Pagination & Table Data */
  learningOutcome: any[] = []; // Stores the paginated student data
  currentPage: number = 1; // Current page number
  pageSize: number = 10; // Number of records per page
  totalPages: number = 0; // Total number of pages
  pageSizes: number[] = [5, 10, 25, 50]; // Available options for page size selection

  /** Calculated Index for Data Display */
  currentIndex: number = (this.currentPage - 1) * this.pageSize;

  /** Table Headers and Column Mapping */
  tableHeaders: string[] = ['Title', 'Description', 'Age Group','Class' , 'Difficulty'];
  columnKeys: string[] = ['name', 'description','age_group', 'to_class', 'difficulty_level'];
  componentName : string = "sp"

  /** User & School Information */
  schoolId: number = 2; // Default school ID
  userName: string = ''; // Logged-in user’s name
  userObject$: any; // Observable for user data from NgRx store

  /** Filter Criteria for API Request */
  filterData: learningOutcomefilterData = {
    page: 1,
    spcount: true,
    filter_data: {
      catId: 0,
      subcatId: 0,
      title : ''
    },
  };

  constructor() {}

  ngOnInit(): void {
    this.compentenceId = this.route.snapshot.paramMap.get('id');

    /** Fetch user details from the NgRx store */
    this.userObject$ = this.store.select(selectUserObject);

    /** Subscribe to user data and update local variables */
    this.userObject$.subscribe({
      next: (data: { firstName: string; lastName: string; school_id: any }) => {
        console.log('User Data:', data);
        this.userName = data.firstName + ' ' + data.lastName; // Set user full name
        this.schoolId = Number(data.school_id); // Convert school ID to a number
      },
      error: (err: any) => {
        console.error('Error fetching user data:', err);
      },
      complete: () => {
        console.log('User data subscription completed.');
      },
    });

    /** Handle page size changes */
    this.pageSizeControl.valueChanges.subscribe((size) => {
      this.pageSize = size || 5; // Default to 5 if size is not set
      this.loadLearningOutcomes(this.currentPage); // Reload data with new page size
    });

    /** Load initial service provider data */
    this.loadLearningOutcomes(this.currentPage);
  }

  /**
   * Get the row clicked data from the table componet
   * and navigate to perticular component
   */
  navigate(data : any){
   console.log("data",data);
   this.router.navigate(['/superadmin/domains/curriculum_goals/compentencies/learning-outcomes/add',data.id]);
  }

  /**
   * Fetches the list of service providers from the API
   * @param pageNo - The page number to fetch
   */
  loadLearningOutcomes(pageNo: number): void {
    if (typeof window !== 'undefined' && window.localStorage) {
      // Update filter criteria
      this.filterData.page = pageNo;
      this.filterData.limit = this.pageSize;

      // Call API to fetch service providers
      this.learningOutcomeService.getLearningOutcomeList(this.compentenceId).subscribe({
        next: (response: any) => {
          // this.currentIndex = (this.currentPage - 1) * this.pageSize; // Update index for display
          console.log('API Response:',response);

          this.learningOutcome = response.data;

          // this.paginatedStudents = response.data.data || []; // Store fetched data
          // this.totalPages = Math.ceil(response.data.spcount / this.pageSize); // Calculate total pages
        },
        error: (error) => {
          console.error('Error fetching service provider list:', error);
        },
      });
    }
  }

  AddLearningOutCome() {
    this.router.navigate(['/superadmin/domains/curriculum_goals/compentencies/learning-outcomes/add',this.compentenceId]);
  }

  /**
   * Handles pagination navigation
   * @param page - The page number to navigate to
   */
  goToPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page; // Update current page
      this.loadLearningOutcomes(page); // Fetch new page data
    }
  }
}
