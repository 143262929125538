import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CurriculumGoalsService } from '../../../../../utils/api/nep/curriculum-goals.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CompentenciesService } from '../../../../../utils/api/nep/compentencies.service';

@Component({
  selector: 'app-add-compentencies',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './add-compentencies.component.html',
  styleUrl: './add-compentencies.component.css'
})
export class AddCompentenciesComponent {
  private fb = inject(FormBuilder);
  private comService = inject(CompentenciesService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);

  cgForm!: FormGroup;
  cgid : any = 0;
  isEditMode = false;
  cid :any;
 

  constructor() {}

  ngOnInit(): void {
    this.cgForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      short_form: [''],
      description: [''],
    });

    this.cgid = this.route.snapshot.paramMap.get('cgid');
    this.cid = this.route.snapshot.paramMap.get('cid');
    if (this.cid) {
      this.isEditMode = true;
      this.comService.getCompentencyById(this.cid).subscribe((data: { [key: string]: any; }) => {
        if (data) {
          this.cgForm.patchValue(data);
        }
      });
    }
  }

  submitForm(): void {
    if (this.cgForm.invalid) return;

    const formData = this.cgForm.value;

    if (this.isEditMode && this.cid) {
      this.comService.updatecompentency(this.cid, formData).subscribe(() => {
        // this.router.navigate(['/superadmin/domains/curriculum_goals',this.cgid]);
        window.history.back()
      });
    } else {
      this.comService.createCompentency(this.cgid , formData).subscribe(() => {
        window.history.back()
      });
    }
  }
}
