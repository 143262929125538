import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
class PermissionsService {
  private router = inject(Router);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);


  constructor() { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (typeof window !== 'undefined' && window.localStorage) {
      if (localStorage.getItem('isLoggedIn') === 'true') {
        return true;
      } else {
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
      }
    } else {
      return false;
    }
  }

  adminCanActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) : boolean {
    if (typeof window !== 'undefined' && window.localStorage) {
      if (localStorage.getItem('isLoggedIn') === 'true') {
        return true;
      } else {
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
      }
    } else {
      return false;
    }
  }

  superAdminCanActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) : boolean {
    if (typeof window !== 'undefined' && window.localStorage) {
      if (localStorage.getItem('isLoggedIn') === 'true' && localStorage.getItem('userRole') === 'super admin') {
        return true;
      } else {
        this.router.navigate(['/admin']);
        return false;
      }
    } else {
      return false;
    }
  }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(PermissionsService).canActivate(next, state);
}

export const adminAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(PermissionsService).adminCanActivate(next, state);
}
export const superAdminAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(PermissionsService).superAdminCanActivate(next, state);
}

