import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../../config/api/api.service';
import { PORTFOLIO, TEACHER } from '../../../../config/constant';

@Injectable({
  providedIn: 'root',
})
export class PortfolioPdfDownloadService {
  private apiService = inject(ApiService);
  assessmentPeriodId: number | null = null;

  constructor(...args: unknown[]);
  constructor() {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.assessmentPeriodId =
        Number(localStorage.getItem('assessmentPeriodId')) || null;
    }
  }

  downloadPortfolio(
    classId: number,
    studentId: number,
    dob_month: number,
    dob_day: number,
    dob_year: number,
    targetedLang: string
  ): Observable<any> {

    return this.apiService.downloadFile(
      `${PORTFOLIO.GET_TEMPLATE.URL}/${classId}/${studentId}/${dob_month}/${dob_day}/${dob_year}/${targetedLang}/${this.assessmentPeriodId}`
    );
  }
  

  getStudentDetails(child_id: number): Observable<any> {
    return this.apiService.get(`${TEACHER.GET_CHILD_DETAILS.URL}/${child_id}`);
  }

  updateStudentDetails(child_id: number, data: object): Observable<object> {
      console.log("child_id in form: ", child_id);
      return this.apiService.put(
        `${TEACHER.PUT_CHILD_DETAILS.URL}/${child_id}`,
        data // 🔹 Send the update data in the request body
      );
    }
    
  }
