import { Component } from '@angular/core';

@Component({
  selector: 'app-add-provider',
  standalone: true,
  imports: [],
  templateUrl: './add-provider.component.html',
  styleUrl: './add-provider.component.css'
})
export class AddProviderComponent {

}
