import { Component, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { LearningOutcomeService } from '../../../../../utils/api/nep/learning-outcome.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-learning-outcomes',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './add-learning-outcomes.component.html',
  styleUrl: './add-learning-outcomes.component.css',
})
export class AddLearningOutcomesComponent {
  private fb = inject(FormBuilder);
  private route = inject(ActivatedRoute);
  private learningOutcomeService = inject(LearningOutcomeService);
  learningOutcomeForm: FormGroup;
  imagePreview: string | ArrayBuffer | null = null;
  compentenceId: any = 1;
  classes = [
    'Playgroup',
    'Nursery',
    'LKG',
    'UKG',
    'Grade 1',
    'Grade 2',
    'Grade 3',
    'Grade 4',
    'Grade 5',
    'Grade 6',
    'Grade 7',
    'Grade 8',
  ];
  difficultyLevels = [
    { label: 'Beginner', value: 1 },
    { label: 'Easy', value: 2 },
    { label: 'Medium', value: 3 },
    { label: 'Hard', value: 4 },
  ];

  constructor() {
    this.compentenceId = this.route.snapshot.paramMap.get('id');
    console.log("compentenceId",this.compentenceId);

    this.learningOutcomeForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      ageGroup: [''],
      toClass: ['', Validators.required],
      difficultyLevel: ['', Validators.required],
      imageUrl: [null],
       imageType: ['']
    });
  }

  onFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => (this.imagePreview = reader.result);
      reader.readAsDataURL(file);
      this.learningOutcomeForm.patchValue({
        imageUrl: file.name,
        imageType: file.type,
      });
    }
  }

  onSubmit() {
    if (this.learningOutcomeForm.valid) {
      this.learningOutcomeService.addLearningOutcome(this.compentenceId ,this.learningOutcomeForm.value).subscribe({
        next: (response: any) => {
          // this.currentIndex = (this.currentPage - 1) * this.pageSize; // Update index for display
          // console.log('API Response:',response);
          // this.paginatedStudents = response.data.data || []; // Store fetched data
          // this.totalPages = Math.ceil(response.data.spcount / this.pageSize); // Calculate total pages
          window.history.back()
        },
        error: (error) => {
          console.error('Error fetching service provider list:', error);
        },
      });
    }
  }
}
