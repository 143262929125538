import { Component } from '@angular/core';

@Component({
  selector: 'app-school',
  standalone: true,
  imports: [],
  templateUrl: './school.component.html',
  styleUrl: './school.component.css'
})
export class SchoolComponent {

}
