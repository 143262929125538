<section id="addorEditTeacher">
    <h2 class="form-title">{{ isEditMode ? 'Edit' : 'Add' }} Teacher</h2>
    <form [formGroup]="teacherForm" (ngSubmit)="onSubmit()" class="form-container">
    
      <div class="form-group">
        <label for="teacher_name">Teacher Name</label>
        <input id="teacher_name" class="form-input" formControlName="teacher_name" placeholder="Teacher Name" />
      </div>
    
      <div class="form-group">
        <label for="teacher_gender">Gender</label>
        <input id="teacher_gender" class="form-input" formControlName="teacher_gender" placeholder="Gender" />
      </div>
    
      <div class="form-group">
        <label for="teacher_code">Code</label>
        <input id="teacher_code" class="form-input" formControlName="teacher_code" placeholder="Code" />
      </div>
    
      <div class="form-group">
        <label for="teacher_mobile">Mobile</label>
        <input id="teacher_mobile" class="form-input" formControlName="teacher_mobile" placeholder="Mobile" type="tel" />
      </div>
    
      <div class="form-group">
        <label for="teacher_email">Email</label>
        <input id="teacher_email" class="form-input" formControlName="teacher_email" placeholder="Email" type="email" />
      </div>
    
      <div class="form-group">
        <label for="password">Password</label>
        <input id="password" class="form-input" formControlName="password" placeholder="Password" type="password" />
      </div>
    
      <div class="form-group">
        <label for="standard">Standard</label>
        <input id="standard" class="form-input" formControlName="standard" placeholder="Standard" />
      </div>
    
      <div class="form-group">
        <label for="division">Division</label>
        <input id="division" class="form-input" formControlName="division" placeholder="Division" />
      </div>
    
      <div class="form-group">
        <label for="class_room">Classroom</label>
        <input id="class_room" class="form-input" formControlName="class_room" placeholder="Classroom" />
      </div>
    
      <button type="submit" class="form-button">
        {{ isEditMode ? 'Update' : 'Add' }}
      </button>
    
    </form>
</section>
