import { AfterViewInit, Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GraphService } from '../../../../../service/graph/graph.service';
import { ResultsService } from '../../../../shared/services/api/results.service';
import { NavbarComponent } from "../../../../shared/component/navbar/navbar.component";
import { NamesectionComponent } from "../../../../shared/component/nameSection/namesection.component";
import { FooterNavbarComponent } from "../../../../shared/component/footer-navbar/footer-navbar.component";
import { PopupComponent } from "../../../../../shared/alerts/popup/popup.component";
import { CommonModule } from '@angular/common';


interface params {
  studentName: string;
  age: string;
  standard: string;
  division: string;
  totalStudents: number;
  teacherName: string;
  cat_name: string;
  dob_month: number;
  dob_date: number;
  dob_year: number;
  profilePhoto: string;
}
@Component({
  selector: 'compentency-result',
  standalone: true,
  imports: [NavbarComponent,CommonModule, NamesectionComponent, FooterNavbarComponent, PopupComponent],
  templateUrl: './compentency-result.component.html',
  styleUrl: './compentency-result.component.css'
})
export class CompentencyResultComponent implements AfterViewInit {
[x: string]: any;
 private nepResultService = inject(ResultsService);
  private route = inject(ActivatedRoute);
  private graphService = inject(GraphService);
  private translate = inject(TranslateService);

  schoolName: string = 'Global International School';
  teacherName: string = 'Mr. John Doe';
  standard: string = 'L.K.G';
  division: string = 'B';
  totalStudents: number = 0;
  studentId: number = 217;
  domainId: number = 8;
  classId: number = 1;
  studentName: string = 'studentName';
  profilePhoto: string = '';
  age: string = '2.8';
  cat_name: string = 'Development';
  loader: boolean = false;
  dob_date: number = 1;
  dob_month: number = 1;
  dob_year: number = 1;

  params: params = {
    studentName: '',
    standard: '',
    division: '',
    totalStudents: 0,
    teacherName: '',
    cat_name: '',
    age: '',
    dob_date: 0,
    dob_month: 0,
    dob_year: 0,
    profilePhoto: '',
  };
  content: boolean = false;

  subcatData: any[] = [];

  targetedLang: string = 'en';
  domainName: string = '';
  compentency: any;
  goalId: number = 0;
  goalName: string = '';

  isDescriptionVisible : boolean = false;
  visibleIndex: number | null = null;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  ngOnInit() {
    this.studentId = Number(this.route.snapshot.paramMap.get('student_id'))!;
    this.classId = Number(this.route.snapshot.paramMap.get('class_id'))!;
    this.domainId = Number(this.route.snapshot.paramMap.get('domain_id'))!;
    this.domainName = this.route.snapshot.paramMap.get('domain_name')!;
    this.goalId = Number(this.route.snapshot.paramMap.get('goal_id'))!;
    this.goalName = this.route.snapshot.paramMap.get('goal_name')!;

    this.params = this.route.snapshot.queryParams as params;

    this.studentName = this.params.studentName;
    this.standard = this.params.standard;
    this.division = this.params.division;
    this.totalStudents = this.params.totalStudents;
    this.teacherName = this.params.teacherName;
    this.cat_name = this.params.cat_name;
    this.age = this.params.age;
    this.profilePhoto = this.params.profilePhoto;

    this.dob_date = Number(this.params.dob_date);
    this.dob_month = Number(this.params.dob_month);
    this.dob_year = Number(this.params.dob_year);

   
  }

  async getResults() {
    this.targetedLang = localStorage.getItem('targetedLang') || 'en';
    this.nepResultService
      .getNepCompentencyResults(this.domainId, this.goalId, this.studentId, this.classId)
      .subscribe((data: any) => {
        if (data.data.length == 0) {
          this.content = true;
        } else {
          this.compentency = data.data;
          console.log('all data : ', this.compentency);

          for (let obj of  this.compentency) {
            let photos: object = {};
            if (obj.images) {
              photos = JSON.parse(obj.images);
              obj.images = photos;
            }
          }

          //  setTimeout(() => {
             this.graphService.createVerticleBarChart(this.compentency);
          //  },0)

        }
      });
  }

  toggleDescription(index: number) {
    this.visibleIndex = this.visibleIndex === index ? null : index;
  }

   ngAfterViewInit(){
    if (typeof window !== 'undefined' && window.localStorage) {
      this.getResults();
    }
   }
}


