<app-navbar [navbarDisplay]="navbarDisplay" [class_id]="class_id" [goBackTo]="goBackTo"></app-navbar>

<div class="parent-container flex-cent flex-col">

    <div class="header-card d-flex align-center just-between">
        <!-- Profile Image -->
        <div class="profile-photo d-flex align-end  just-center">
            <img [src]="profileImageLink + profile_image">
        </div>

        <!-- Name and Age Section -->
        <div class="details flex-cent flex-col">
            <div class="nameAge d-flex just-around">
                <h3 class="name">{{child_name}}</h3>
                <h5 class="age">{{calculateAge(dob_year, dob_month, dob_date)}}</h5>
            </div>
        </div>
    </div>

    <!-- Parent Details in a Separate White Box -->
    <div class="parent-details-container">
        <div class="parent-details">
            <h6><strong>Parents:</strong> {{parent_name}}</h6>
            <h6><strong>Email:</strong> {{parent_email}}</h6>
            <h6><strong>Contact No.:</strong> {{parent_contact}}</h6>
        </div>
    </div>

    <div class="share-with-parents flex-cent flex-col">
        <h5 class="swp-title">{{'parentShare.shareParents' | translate}}</h5>
        <div class="swp flex-cent flex-col">
            <div class="swp-card d-flex just-between align-center">
                <h5>{{'parentShare.results' | translate}}</h5>
                <label class="switch">
                    <input type="checkbox" id="enableResults" [checked]="isEnableResults" #enableResults (change)="toggleResultSharing(enableResults.checked)">
                    <span class="slider round"></span>
                </label>

            </div>
            <div class="swp-card d-flex just-between align-center">
                <h5>{{'parentShare.portfolio' | translate}}</h5>
                <button class="sendBtn" (click)="sendPortfolio()"><img src="../../../../assets/img/sendBtn.png"
                        alt=""></button>
            </div>
        </div>
    </div>


    <div class="message d-flex just-start flex-col">
        <h6 class="message-title">{{'parentShare.sendMsgTo' | translate}}{{child_name}}{{'parentShare.sParents' |
            translate}}</h6>
        <div class="typeAndSend d-flex align-center just-between">
            <textarea class="textArea" name="message" id="message"
                placeholder="{{'parentShare.yourMsgHere' | translate}}"></textarea>


            <button class="sendBtn" (click)="sendPrivateMessage()"><img src="../../../../assets/img/sendBtn.png"
                    alt=""></button>

        </div>
    </div>


    <div class="view-summary flex-cent">
        <button class="summaryBtn" (click)="goToSummaryReport()">
            <h5>{{'parentShare.viewReports' | translate }}</h5>
        </button>
    </div>



    <app-footer-navbar></app-footer-navbar>