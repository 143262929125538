// global-object.reducer.ts
import { createReducer, on } from '@ngrx/store';
import { resetUserObject,setUserObject } from './user.actions';
import { user } from '../../app/shared/interfaces/admin/common';


const initialUserState : user = {
  userId: 0,
  firstName: '',
  middleName: null,
  lastName: null,
  gender: 0,
  email: '',
  roleId: 0,
  roleName: ''
} 

export const UserObjectReducer  = createReducer(
  initialUserState,
  on(setUserObject, (state, action): user => ({ ...state, ...action })),
  on(resetUserObject, (): user => {
    localStorage.removeItem('user');
    return initialUserState;
  })
);



