import { createSelector, createFeatureSelector } from '@ngrx/store';
import { user } from '../../app/shared/interfaces/admin/common';
// First, get the global object feature slice from the state
export const selectGlobalObjectState = createFeatureSelector<user>('userObject');

/** 
 * create a selector to get the global object
 * */
export const selectUserObject = createSelector(
  selectGlobalObjectState,
  (state: user) => state
);
