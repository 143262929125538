import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../../config/api/api.service';
import { MILESTONE, IMAGE } from '../../../../config/constant';
@Injectable({
  providedIn: 'root',
})
export class MilestoneService {
  private apiService = inject(ApiService);

  assessmentPeriodId: number | null = null;
  userObject: any;
  schoolId: any;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.assessmentPeriodId =
        Number(localStorage.getItem('assessmentPeriodId')) || null;

      this.userObject = localStorage.getItem('user');

      if (this.userObject) {
        this.userObject = JSON.parse(this.userObject);
        this.schoolId = this.userObject.school_id.toString();
        console.log('userObject', this.userObject);
      }
    }
  }

  /*
   * This function is used to get all the subcategory for
   *  the child
   */
  getMilestoneBySubCategory(
    cat_id: number,
    subcat_id: number,
    class_id: number,
    student_id: number,
    dob_month: number,
    dob_day: number,
    dob_year: number,
    starnard: string,
    competencyId: number,
    targetedLang: string
  ): Observable<object> {
    return this.apiService.post(
      `${MILESTONE.POST_MILESTONES_SUBCATEGORY.URL}`,
      {
        cat_id: cat_id,
        subcat_id: subcat_id,
        class_id: class_id,
        student_id: student_id,
        dob_month: dob_month,
        dob_day: dob_day,
        dob_year: dob_year,
        standard: starnard,
        competency_id: competencyId,
        assessmentPeriodId: this.assessmentPeriodId,
        targetedLang: targetedLang,
      },
      { 'school-id': this.schoolId }
    );
  }

  /*
   *  this functions is used to get all the category for child according to child_group
   *
   */

  getMilestoneByCategory(
    cat_id: number,
    class_id: number,
    student_id: number,
    dob_month: number,
    dob_day: number,
    dob_year: number,
    targetedLang: string
  ): Observable<object> {
    return this.apiService.post(
      `${MILESTONE.POST_MILESTONES_CATEGORY.URL}`,
      {
        cat_id: cat_id,
        class_id: class_id,
        student_id: student_id,
        dob_month: dob_month,
        dob_day: dob_day,
        dob_year: dob_year,
        assessmentPeriodId: this.assessmentPeriodId,
        targetedLang: targetedLang,
      },
      { 'school-id': this.schoolId }
    );
  }

  getCompentencies(
    cgId: number,
    classId: number,
    studentId: number,
    targetedLang: string
  ): Observable<object> {
    console.log('ids ', cgId, classId, studentId);
    return this.apiService.get(
      `${MILESTONE.GET_COMPENTENCIES.URL}/${cgId}/${classId}/${studentId}/${this.assessmentPeriodId}/${targetedLang}`,
      { 'school-id': this.schoolId }
    );
  }

  saveMilestone(
    cat_id: number,
    subcat_id: number,
    class_id: number,
    student_id: number,
    school_id: number,
    milestone_ids: string,
    result: number,
    remark: string,
    dob_month: number,
    dob_day: number,
    dob_year: number,
    compentencyId: number
  ): Observable<object> {
    return this.apiService.post(
      `${MILESTONE.POST_SAVE_MILESTONE.URL}`,
      {
        cat_id: cat_id,
        subcat_id: subcat_id,
        class_id: class_id,
        student_id: student_id,
        school_id: school_id,
        milestone_ids: milestone_ids,
        result: result,
        remark: remark,
        dob_month: `${dob_month}`,
        dob_day: `${dob_day}`,
        dob_year: `${dob_year}`,
        compentency_id: compentencyId,
        assessmentPeriodId: this.assessmentPeriodId,
      },
      { 'school-id': this.schoolId }
    );
  }

  getSavedMilestone(
    subcatId: number,
    catId: number,
    classId: number,
    studentId: number
  ): Observable<object> {
    return this.apiService.get(
      `${MILESTONE.GET_SAVED_MILESTONE.URL}/${subcatId}/${catId}/${classId}/${studentId}`
    );
  }

  updateSavedMilestone(
    newMilestone: string,
    newScore: number,
    recordId: number,
    remark: string
  ): Observable<object> {
    return this.apiService.put(`${MILESTONE.PUT_UPDATE_SAVED_MILESTONE.URL}`, {
      newMilestone: newMilestone,
      newScore: newScore,
      remark: remark,
      recordId: recordId,
    });
  }

  // api for getting presigned url for each image
  getPresignedUrl(
    images: Array<{ fileName: string; imageType: string }>, // here images means fileNames of the images and not actual images
    catID: number,
    subCatID: number,
    studentID: number,
    dobDate: number,
    dobMonth: number,
    dobYear: number,
    purpose: string,
    compId: number
  ): Observable<object> {
    return this.apiService.post(
      `${IMAGE.POST_PRESIGNED.URL}`,
      {
        purpose: purpose,
        images: images,
        catID: catID,
        subCatID: subCatID,
        studentID: studentID,
        dobDate: dobDate,
        dobMonth: dobMonth,
        dobYear: dobYear,
        assessmentPeriodId: this.assessmentPeriodId,
        compId: compId,
      },
      { 'school-id': this.schoolId }
    );
  }

  // api for updating the database once images are uploaded to server.
  insertImagePath(
    studentID: number,
    catID: number,
    subcatID: number,
    imagesPath: Array<string>,
    dobDate: number,
    dobMonth: number,
    dobYear: number,
    purpose: string,
    compId: number
  ): Observable<object> {
    return this.apiService.post(
      `${IMAGE.POST_INSERT_IMAGE.URL}`,
      {
        purpose: purpose,
        studentID: studentID,
        catID: catID,
        subcatID: subcatID,
        imagesPath: imagesPath,
        dobDate: dobDate,
        dobMonth: dobMonth,
        dobYear: dobYear,
        assessmentPeriodId: this.assessmentPeriodId,
        compId: compId,
      },
      { 'school-id': this.schoolId }
    );
  }

  fetchImages(
    childId: number,
    catId: number,
    subcatId: number,
    dobDate: number,
    dobMonth: number,
    dobYear: number,
    compId: number
  ): Observable<object> {
    return this.apiService.get(
      `${IMAGE.GET_IMAGES.URL}/${childId}/${catId}/${subcatId}/${this.assessmentPeriodId}/${dobDate}/${dobMonth}/${dobYear}/${compId}`,
      { 'school-id': this.schoolId }
    );
  }
}
