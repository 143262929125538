// @ts-ignore
// @ts-nocheck

import { Injectable } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5percent from '@amcharts/amcharts5/percent';

import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

@Injectable({
  providedIn: 'root',
})
export class GraphService {
  private charts: am5.Root[] = [];
  targetedLang: string = 'en';
  constructor() {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.targetedLang = localStorage.getItem('targetedLang') || 'en';
    }
  }

  createBarChart(chartData: any) {
    console.log('chart data for subcat results: ', chartData);
    chartData.forEach((dataPoint: any) => {
      switch (true) {
        case dataPoint.result <= 25:
          dataPoint.color = am5.color('#FBAF55'); // orange
          break;
        case dataPoint.result >= 67:
          dataPoint.color = am5.color('#A9FA68'); // green
          break;
        case dataPoint.result <= 66 && dataPoint.result > 25:
          dataPoint.color = am5.color('#FCF969'); // yellow
          break;
        default:
          break;
      }
    });

    const root = am5.Root.new('chartdiv');
    this.charts.push(root);

    if (root._logo) {
      root._logo.dispose();
    }

    root.setThemes([am5themes_Animated.new(root)]);

    const charts = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        pinchZoomX: true,
        paddingLeft: 0,
      })
    );

    // charts.set("background", am5.Rectangle.new(root, {
    //   fill: am5.color(0xFFFFFF)
    // }));

    const cursor = charts.set('cursor', am5xy.XYCursor.new(root, {}));
    cursor.lineY.set('visible', false);

    const xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 0,
      strokeOpacity: 1,
    });

    let xAxis;
    if (this.targetedLang !== 'en') {
      xAxis = charts.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          maxDeviation: 0.3,
          categoryField: 'cat_name_hi',
          renderer: xRenderer,
        })
      );
    } else {
      xAxis = charts.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          maxDeviation: 0.3,
          categoryField: 'cat_name',
          renderer: xRenderer,
        })
      );
    }

    xRenderer.labels.template.setAll({
      oversizedBehavior: 'truncate',
      textAlign: 'center',
    });

    xAxis.onPrivate('cellWidth', function (cellWidth) {
      xRenderer.labels.template.set('maxWidth', cellWidth);
    });

    const yRenderer = am5xy.AxisRendererY.new(root, { strokeOpacity: 1 });

    // yRenderer.grid.template.setAll({
    //   stroke: am5.color(0xFFFFFF),
    //   strokeWidth: 0.1
    // });

    const yAxis = charts.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0.3,
        min: 0,
        max: 110,
        strictMinMax: true,
        renderer: yRenderer,
        marginBottom: 2,
      })
    );

    let series;

    if (this.targetedLang !== 'en') {
      series = charts.series.push(
        am5xy.ColumnSeries.new(root, {
          name: 'Series 1',
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'result',
          sequencedInterpolation: true,
          categoryXField: 'cat_name_hi',
          tooltip: am5.Tooltip.new(root, {
            labelText: '{cat_name_hi} {valueY}',
          }),
        })
      );
    } else {
      series = charts.series.push(
        am5xy.ColumnSeries.new(root, {
          name: 'Series 1',
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'result',
          sequencedInterpolation: true,
          categoryXField: 'cat_name',
          tooltip: am5.Tooltip.new(root, {
            labelText: '{cat_name} {valueY}',
          }),
        })
      );
    }

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationX: 0.5,
        locationY: 1,
        sprite: am5.Circle.new(root, {
          radius: 15,
          stroke: am5.color('#000'),
          strokeWidth: 1,
          fill: am5.color('#fff'),
          centerX: am5.percent(50),
          centerY: am5.percent(50),
        }),
      });
    });

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationX: 0.5,
        locationY: 1,
        stacked: 'up',

        sprite: am5.Label.new(root, {
          text: '{valueY}',
          centerX: am5.percent(50),
          centerY: am5.percent(50),
          textAlign: 'center',
          populateText: true,
        }),
      });
    });

    series.columns.template.adapters.add('fill', (fill, target) => {
      const dataItem = target.dataItem?.dataContext as {
        color?: { _hex: string };
      };
      if (dataItem?.color && dataItem.color._hex) {
        const hexColor = Number(dataItem.color._hex);
        const red = (hexColor >> 16) & 255;
        const green = (hexColor >> 8) & 255;
        const blue = hexColor & 255;
        return am5.color(`rgb(${red}, ${green}, ${blue})`);
      }
      return undefined;
    });

    series.columns.template.setAll({
      cornerRadiusTL: 8,
      cornerRadiusTR: 8,
      cornerRadiusBR: 8,
      cornerRadiusBL: 8,
      strokeOpacity: 0,
      width: 18,
    });

    xAxis.data.setAll(chartData);
    series.data.setAll(chartData);

    series.appear(1000);
    // chart.appear(1000, 100);
  }

  createVerticleBarChart(chartData: any) {
    let chartDiv = document.getElementById('graphDiv');

    // Assuming 'chartData' is your dataset
    let categoryCount = chartData.length;

    console.log('categoryCount', categoryCount);
    // Calculate height dynamically
    let minHeight = 15; // Minimum height in rem
    let maxHeight = 40; // Maximum height in rem
    let heightPerCategory = 6; // Adjust this to control spacing

    let newHeight = Math.min(
      maxHeight,
      Math.max(minHeight, categoryCount * heightPerCategory)
    );
    console.log('newHeight', newHeight);

    // Apply the height to the container
    chartDiv.style.height = `${newHeight}rem`;

    chartData.forEach((dataPoint: any) => {
      switch (true) {
        case dataPoint.result <= 25:
          dataPoint.color = am5.color('#FBAF55'); // orange
          break;
        case dataPoint.result >= 67:
          dataPoint.color = am5.color('#A9FA68'); // green
          break;
        case dataPoint.result <= 66 && dataPoint.result > 25:
          dataPoint.color = am5.color('#FCF969'); // yellow
          break;
        default:
          break;
      }
    });

    let root = am5.Root.new('chartId');

    if (root._logo) {
      root._logo.dispose();
    }

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        paddingLeft: 0,
        paddingRight: 30,
        wheelX: 'none',
        wheelY: 'none',
      })
    );

    let yRenderer = am5xy.AxisRendererY.new(root, {
      // minGridDistance: 30,
      minGridDistance: 1,
    });

    // Create Y-axis (Category Axis)
    let yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: 'cat_name',
        renderer: yRenderer,
      })
    );

    yRenderer.labels.template.setAll({
      // forceHidden:true,
      oversizedBehavior: 'wrap',
      textAlign: 'center',
      maxWidth: 0,
    });

    // Set category data
    yAxis.data.setAll(chartData);

    // Create X-axis (Value Axis)
    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0.3,
        min: 0,
        max: 100,
        strictMinMax: true,
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 100,
          minorGridEnabled: true,
        }),
      })
    );

    // Create series
    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: 'result',
        categoryYField: 'cat_name',
        sequencedInterpolation: true,
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'horizontal',
          labelText: '{cat_nameY}: {resultX}',
        }),
      })
    );

    series.bullets.push(function (root, series, dataItem) {
      return am5.Bullet.new(root, {
        locationX: 0,
        sprite: am5.Label.new(root, {
          text: dataItem.dataContext.cat_name, // Display category name
          startX: am5.p100,
          centerY: am5.p100,
          dy: -15,
          fontSize: '16px',
          fill: am5.color(0x000000),
        }),
      });
    });

    // Set rounded corners and color mapping
    series.columns.template.setAll({
      cornerRadiusTL: 8,
      cornerRadiusTR: 8,
      cornerRadiusBR: 8,
      cornerRadiusBL: 8,
      strokeOpacity: 0,
      // width: 18,
      height: 40,
    });

    series.columns.template.adapters.add('fill', (fill, target) => {
      const dataItem = target.dataItem?.dataContext as {
        color?: { _hex: string };
      };
      if (dataItem?.color && dataItem.color._hex) {
        const hexColor = Number(dataItem.color._hex);
        const red = (hexColor >> 16) & 255;
        const green = (hexColor >> 8) & 255;
        const blue = hexColor & 255;
        return am5.color(`rgb(${red}, ${green}, ${blue})`);
      }
      return undefined;
    });

    // Set data for the series
    series.data.setAll(chartData);

    // Animation effects
    series.appear(1000);
    chart.appear(1000, 100);
  }

  /**
   * This function is used for creating pie chart
   * @param chartId {  this parameter needs fro the root container}
   * @param result
   * @param color
   */

  createPieChart(chartId: string, result: number, color: number) {
    const root = am5.Root.new(chartId);
    this.charts.push(root);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        // layout: root.verticalLayout,
      })
    );

    root.setThemes([am5themes_Animated.new(root)]);

    if (root._logo) {
      root._logo.dispose();
    }

    // pieChart data
    const data = [
      {
        status: 'Done',
        value: result,
        sliceSettings: {
          fill: am5.color(color),
          stroke: am5.color(0x000000),
        },
      },
      {
        status: 'NotDone',
        value: 100 - result,
        sliceSettings: {
          fill: am5.color(0xffffff), // white
          stroke: am5.color(0x000000),
        },
      },
    ];

    // Create series
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: 'Series',
        valueField: 'value',
        categoryField: 'status',
      })
    );

    // adding colours
    series.slices.template.setAll({
      templateField: 'sliceSettings',
      showTooltipOn: 'click',
    });

    // hiding labels
    series.labels.template.setAll({
      maxWidth: 1,
      oversizedBehavior: 'hide',
    });
    // series.labels.template.setAll("hide");

    series.ticks.template.set('visible', false);

    // annimations
    series.animate({
      key: 'startAngle',
      to: result,
      duration: 3000,
      easing: am5.ease.yoyo(am5.ease.linear),
    });

    series.data.setAll(data);
    series.appear();
    chart.appear();
  }

  createDonutChart(
    chartId: string,
    result: number,
    donutColor: number,
    centerLable
  ) {
    var root = am5.Root.new(chartId);
    this.charts.push(root);

    root.setThemes([am5themes_Animated.new(root)]);

    if (root._logo) {
      root._logo.dispose();
    }

    var chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        radius: am5.percent(90),
        innerRadius: am5.percent(80),
      })
    );

    // Define data
    const data = [
      {
        status: 'result',
        value: result,
        sliceSettings: {
          fill: am5.color(donutColor),
          stroke: am5.color(donutColor),
        },
      },
      {
        status: '',
        value: 100 - result,
        sliceSettings: {
          fill: am5.color('#EDEDED'),
          stroke: am5.color('#EDEDED'),
        },
      },
    ];

    // Create series
    var series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: 'Series',
        valueField: 'value',
        categoryField: 'status',
      })
    );

    // Add colors whit this function
    series.slices.template.setAll({
      templateField: 'sliceSettings',
      showTooltipOn: 'click',
    });

    series.data.setAll(data);
    if (centerLable !== 'noCenterLabel')
      series.children.push(
        am5.Label.new(root, {
          text: `${result}%`,
          centerX: am5.p50,
          centerY: am5.p50,
          fontSize: 20,
          fontWeight: '500',
          fill: am5.color(0x000000),
        })
      );

    // Disabling labels and ticks
    series.labels.template.set('visible', false);
    series.ticks.template.set('visible', false);
  }

  createVerticleBar(chartId: string, chartData: object[]) {
    console.log('verticle bar hit');
    var root = am5.Root.new(chartId);
    this.charts.push(root);

    console.log('chartId', chartId);
    var myTheme = am5.Theme.new(root);

    if (root._logo) {
      root._logo.dispose();
    }

    myTheme.rule('Grid', ['base']).setAll({
      strokeOpacity: 0.1,
    });

    root.setThemes([am5themes_Animated.new(root), myTheme]);

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'none',
        wheelY: 'none',
        paddingLeft: 0,
        minorGridEnabled: true,
      })
    );

    var yRenderer = am5xy.AxisRendererY.new(root, {
      maxWidth: 5,
      oversizedBehavior: 'truncate',
      paddingRight: 5,
    });

    // yRenderer.grid.template.set("location", 1);

    var yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: 'short_cat_name',
        renderer: yRenderer,
      })
    );

    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 10,
        max: 100,
        strictMinMax: true,
        numberFormat: '#',
        step: 10,
        renderer: am5xy.AxisRendererX.new(root, {
          visible: true,
          strokeOpacity: 0.1,
          minGridDistance: 90,
          minorGridEnabled: true,
        }),
      })
    );

    var series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Series 1',
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: 'percentage',
        sequencedInterpolation: true,
        categoryYField: 'short_cat_name',
      })
    );

    chartData.forEach((dataPoint: any) => {
      switch (true) {
        case dataPoint.percentage <= 25:
          dataPoint.color = am5.color('#FBAF55'); // orange
          break;
        case dataPoint.percentage >= 67:
          dataPoint.color = am5.color('#A9FA68'); // green
          break;
        case dataPoint.percentage <= 66 && dataPoint.percentage > 25:
          dataPoint.color = am5.color('#FCF969'); // yellow
          break;
        default:
          break;
      }
    });

    series.columns.template.adapters.add('fill', (fill, target) => {
      const dataItem = target.dataItem?.dataContext as {
        color?: { _hex: string };
      };
      if (dataItem?.color && dataItem.color._hex) {
        const hexColor = Number(dataItem.color._hex);
        const red = (hexColor >> 16) & 255;
        const green = (hexColor >> 8) & 255;
        const blue = hexColor & 255;
        return am5.color(`rgb(${red}, ${green}, ${blue})`);
      }
      return undefined;
    });

    series.columns.template.setAll({
      // cornerRadiusTL: 8,
      cornerRadiusTR: 10,
      cornerRadiusBR: 10,
      // cornerRadiusBL: 8,
      strokeOpacity: 0,
      // width: 18,
    });

    yAxis.data.setAll(chartData);
    series.data.setAll(chartData);

    series.appear(1000);
    chart.appear(1000, 100);
  }

  removeGraphs() {
    this.charts.forEach(root => root.dispose());
    this.charts = [];
  }
}
