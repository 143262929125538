import { Component, inject } from '@angular/core';
import { NavbarComponent } from '../../../shared/component/navbar/navbar.component';
import { FooterNavbarComponent } from '../../../shared/component/footer-navbar/footer-navbar.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HindiNumberPipe } from '../../../shared/pipe/hindi-number.pipe';
import { ParentsService } from '../../../shared/services/api/parents.service';

@Component({
  selector: 'app-parent-share',
  standalone: true,
  imports: [NavbarComponent, FooterNavbarComponent, TranslateModule, HindiNumberPipe],
  providers: [ParentsService],
  templateUrl: './parent-share.component.html',
  styleUrl: './parent-share.component.css',
  
})
export class ParentShareComponent {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private translate = inject(TranslateService);
  private parentService = inject(ParentsService);

  goBackTo: string = 'cwp';
  navbarDisplay: string = 'none';
  params: any;
  class_id: number = 0;

  child_name: string = '';
  child_last_name: string = '';
  child_id: number = 0;
  dob_date: number = 0;
  dob_month: number = 0;
  dob_year: number = 0;
  profile_image : string = '';

  parent_name: string = '';
  parent_email: string = '';
  parent_contact: string = '';
  user_id: number = 0;

  assessment_period_id : number = 0;

  sessionSummaryParams: string = '';

  mode: string = 'summary';
  profileImageLink: string = 'https://bmc-space.blr1.digitaloceanspaces.com/'; 

  isEnableResults: boolean = false;
  resultSharingStatus = {};


  // testing
  language: string = '';

  constructor() {
    console.log("isEnableResults in constructor :",this.isEnableResults);
    this.class_id = Number(this.route.snapshot.paramMap.get('class_id'));

    if (typeof window !== 'undefined' && window.localStorage) {
      this.assessment_period_id =
        Number(localStorage.getItem('assessmentPeriodId'));
    }

    this.sessionSummaryParams = window.sessionStorage.getItem(
      'summaryReportParams'
    )!;
    if (this.sessionSummaryParams) {
      this.params = JSON.parse(this.sessionSummaryParams);
      // console.log('params from session storage in parent share: ', this.params);
    } else {
      this.params = this.route.snapshot.queryParams;
      // console.log("params : ",this.params);
      // setting params in sessionStorage
      window.sessionStorage.setItem(
        'summaryReportParams',
        JSON.stringify(this.params)
      );
    }

    this.child_id = Number(this.params.child_id);
    this.child_name = this.params.child_name;
    this.child_last_name = this.params.child_last_name;
    // console.log("child last name is : ",this.child_last_name);
    if(this.child_last_name || this.child_last_name === 'null'){
      this.child_name = this.child_name + this.child_last_name;
    }
    this.dob_date = Number(this.params.child_born_date);
    this.dob_month = Number(this.params.child_born_month);
    this.dob_year = Number(this.params.child_born_year);
    this.user_id = Number(this.params.parent_id);
  }

  ngOnInit() {
    this.language = window.localStorage.getItem('targetedLang')!;
    // console.log('language is : ', this.language);
    //get class_id from route param

    //     console.log('Parent Name:', this.parent_name);
    // console.log('Child Name:', this.child_name);
    // console.log('dob_year:', this.dob_year);
    // console.log('dob_month:', this.dob_month);
    // console.log('dob_date:', this.dob_year);
    // console.log('Image URL:', this.profileImageLink + this.child_id + '.jpg')


    this.parentService.checkResultSharing(this.child_id , this.user_id, this.class_id, this.assessment_period_id).subscribe({
      next:(data : any) =>{
        this.resultSharingStatus = data.data[0];
        console.log(" this.resultSharingStatus : ",this.resultSharingStatus);
        if(data.data.length !==0 && data.data[0].result_state){
          let status = data.data[0].result_state;
          this.isEnableResults = (status === "enabled")? true : false;
          console.log("updated isEnableResults by api : ",this.isEnableResults);
        }
      },
      error: (error: string) => {
        console.error('Error :', error);
      },
    }); // checkResultSharing end 

    
  }

  toggleResultSharing(shareResults: boolean){
    console.log("shareResults : ",shareResults);
    
    this.isEnableResults = shareResults;
    // console.log("this.isEnableResults : ",this.isEnableResults);

    this.parentService.toggleResultSharing(this.child_id , this.user_id, this.class_id, this.assessment_period_id).subscribe({
      next: (data: any)=>{
        console.log("toggle result sharing api hit%%%%");
        console.log("data from toggleResultSharing: ",data);
      }
    })
  }
  
  goToSummaryReport() {
    window.sessionStorage.clear(); // necessary to remove previous data.
    // console.log(
    //   '------sessionStorage cleared before navigating to summary-report------'
    // );
    this.router.navigate(['summary-report', this.class_id, this.mode], {
      queryParams: this.params,
    });
  }
  sendPrivateMessage() {
    alert('This feature is under development!');
  }

  sendResults() {
    alert('This feature is under development!');
  }

  sendPortfolio() {
    alert('This feature is under development!');
  }

  openCamera() {
    console.log('opening camera component %%');
    this.router.navigate(['/camera']);
  }

  calculateAge(birthYear: number, birthMonth: number, birthDate: number) {
    // For calculaitng  the age we needed the current date and birth date we are getting that from below two lines
    const currentDate = new Date();
    const birthDateObj = new Date(birthYear, birthMonth - 1, birthDate);

    // Thses  variables are stor ing the difference of birthdate and current date
    // We will get the age with the help of these variables
    const yearsDiff = currentDate.getFullYear() - birthDateObj.getFullYear();
    const monthsDiff = currentDate.getMonth() - birthDateObj.getMonth();
    const daysDiff = currentDate.getDate() - birthDateObj.getDate();

    // for storing the age
    let age = '';

    /**
     * If the month difference is less than 0 or have same month
     * Then we need to add 12 in month differen as it shows that the current month is the birthmonth of child
     * And birth date isn't come yet
     */

    if (monthsDiff < 0 || (monthsDiff === 0 && daysDiff < 0)) {
      // as the birthday isn't come yet so wee need to subtract it with 1
      let year = yearsDiff - 1;
      age = `${this.translate.instant(`num.${year}`)}${this.translate.instant('y')} `;
      if (monthsDiff < 0) {
        age += `${this.translate.instant(`num.${12 + monthsDiff}`)}${this.translate.instant('m')}`;
      }
    } else {
      // AS mention above if the birth date is passed in the current year we just need to assign both to age vaiable
      age = `${this.translate.instant(`num.${yearsDiff}`)}${this.translate.instant('y')} `;
      if (monthsDiff > 0) {
        age += ` ${this.translate.instant(`num.${monthsDiff}`)}${this.translate.instant('m')}`;
      }
    }
    return age;
  }
}
