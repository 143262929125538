import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SidebarComponent } from "../../../layout/sidebar/sidebar.component";
import { HorizontalBarComponent } from "../../../layout/horizontal-bar/horizontal-bar.component";


@Component({
  selector: 'super-dashboard',
  standalone: true,
  imports: [],
  templateUrl: './super-dashboard.component.html',
  styleUrl: './super-dashboard.component.css'
})
export class SuperDashboardComponent {
  
}
