
<app-navbar
  [teacherName]="teacherName"
  [standard]="standard"
  [division]="division"
  [navbarDisplay]="'NO'"></app-navbar>
<section id="nepHpc" >
  <div class="container">
    <div class="title-box">HOLISTIC PROGRESS CARD</div>
  
    <div class="info-section">
      <div class="info-row">
        <div class="label-container">
          <p class="label">Name of the Child</p>
        </div>
        <div class="value-container">
          :
          <p class="value">{{userData.child_name}}</p>
        </div>
      </div>
      <div class="info-row">
        <div class="label-container">
          <p class="label">Class</p>
        </div>
        <div class="value-container">
          :
          <p class="value">{{standard}} - {{division}}</p>
        </div>
      </div>
      <div class="info-row">
        <div class="label-container">
          <p class="label">Date of Birth</p>
        </div>
        <div class="value-container">
          :
          <p class="value">{{getMonth(dob_month)}} {{dob_date}}, {{dob_year}}</p>
        </div>
      </div>
    </div>
  
    <div class="image-frame">
      @if (profileImage != '')  {
        <img [src]="profileImage" alt="Profile Photo" />
      }
    </div>
    
    <!-- Development Section -->
    <h2>My Development and Learning Journey</h2>
  
    <!-- <div class="container">
      <div class="image-container">
        <img src="/assets/img/nep1.png" alt="Development Image" />
      </div>
    </div> -->
  
    <!-- All About Me Section starts from here -->
    <div class="title-box">All About Me</div>
  
    <div class="wrapper">
      <div class="info-row1">
        <div class="label-container1">
          <p class="label1">My name is</p>
          <p class="colon">:</p>
        </div>
        <div class="value-container1">
          <p class="value1">{{userData.child_name}}</p>
        </div>
      </div>
      <div class="info-row1">
        <div class="label-container1">
          <p class="label1">My birthday is on</p>
          <p class="colon">:</p>
        </div>
        <div class="value-container1">
          <p class="value1">{{dob_date}} {{getMonth(dob_month)}} {{dob_year}}</p>
        </div>
      </div>
      <div class="info-row1">
        <div class="label-container1">
          <p class="label1">Things I like are</p>
          <p class="colon">:</p>
        </div>
        <div class="value-container1">
          <p class="value1">{{userData.things_i_like || "--"}}</p>
        </div>
      </div>
      <div class="info-row1">
        <div class="label-container1">
          <p class="label1">I live in</p>
          <p class="colon">:</p>
        </div>
        <div class="value-container1">
          <p class="value1">{{userData.correspondance_address || "--"}}</p>
        </div>
      </div>
      <div class="info-row1">
        <div class="label-container1">
          <p class="label1">My friends are</p>
          <p class="colon">:</p>
        </div>
        <div class="value-container1">
          <p class="value1">{{userData.friends || "--"}}</p>
        </div>
      </div>
  
      <h3>My favourite</h3>
      <!-- <div class="photo-container">
        <div class="photo">
          <img
            src="/assets/img/studentProfile.png"
            alt="Girl in a jacket"
            width="500"
            height="600" />
        </div>
      </div> -->
  
      <div class="list-container">
        <ul class="label-lcontainer1">
          <li>
            <div class="info-row1">
              <div class="label-container1">
                <p class="label1">Colour</p>
                <p class="colon">:</p>
              </div>
              <div class="value-container1">
                <p class="value1">{{userData.fav_color || "--"}}</p>
              </div>
            </div>
          </li>
          <li>
            <div class="info-row1">
              <div class="label-container1">
                <p class="label1">Food</p>
                <p class="colon">:</p>
              </div>
              <div class="value-container1">
                <p class="value1">{{userData.fav_food || "--"}}</p>
              </div>
            </div>
          </li>
          <li>
            <div class="info-row1">
              <div class="label-container1">
                <p class="label1">Outdoor game</p>
                <p class="colon">:</p>
              </div>
              <div class="value-container1">
                <p class="value1">{{userData.fav_outdoor_game || "--"}}</p>
              </div>
            </div>
          </li>
          <li>
            <div class="info-row1">
              <div class="label-container1">
                <p class="label1">Indoor game</p>
                <p class="colon">:</p>
              </div>
              <div class="value-container1">
                <p class="value1">{{userData.fav_indoor_game || "--"}}</p>
              </div>
            </div>
          </li>
          <li>
            <div class="info-row1">
              <div class="label-container1">
                <p class="label1">Animal</p>
                <p class="colon">:</p>
              </div>
              <div class="value-container1">
                <p class="value1">{{userData.fav_animal || "--"}}</p>
              </div>
            </div>
          </li>
          <li>
            <div class="info-row1">
              <div class="label-container1">
                <p class="label1">Bird</p>
                <p class="colon">:</p>
              </div>
              <div class="value-container1">
                <p class="value1">{{userData.bird || "--"}}</p>
              </div>
            </div>
          </li>
          <li>
            <div class="info-row1">
              <div class="label-container1">
                <p class="label1">Flower</p>
                <p class="colon">:</p>
              </div>
              <div class="value-container1">
                <p class="value1">{{userData.fav_flower || "--"}} </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
  
      <!-- <div class="terms-section">
        <div class="term-box">
          <b class="term-title">Term 1:</b><br />
          My height is
          <p class="value-underline">{{userData.height}}</p>
          <br />
          My weight is
          <p class="value-underline">{{userData.weight}}</p>
          kgs.
        </div>
        <div class="term-box">
          <b class="term-title">Term 2:</b><br />
          My height is
          <p class="value-underline">{{userData.height}}</p>
          <br />
          My weight is
          <p class="value-underline">{{userData.weight}}</p>
          kgs.
        </div>
      </div> -->
    </div>
  </div>
  <section id="progressDiv">
    <div class="progressDivHeading">
      <span class="ProgessTitle">My Progress</span>
    </div>
    @for (obj of catData; track obj.category_id) {
      <section class="cat-cards" id="cat-cards">
        <div class="cat-name">
          <span>{{ obj.cat_name | translate }}</span>
          <!-- <div class="catImageDiv">
            <img class="catImage" [src]="obj.cat_img" alt="" />
          </div> -->
        </div>
        <div class="responsiveWrap">
          <div class="donutChartDiv">
            <div class="donutChart" [id]="obj.category_id"></div>
            <!-- <span class="overAlll professional-blue-font">{{ obj.result | hindiNumber }}% {{ 'portfolio.overAlll' | translate}}</span> -->
  
            <span class="overAlll professional-blue-font">
              {{ 'portfolio.overAlll' | translate }}
              @if (obj.result > 0) {
                @if (isMasked === 'Yes') {
                  <span
                    class="masked-percentage"
                    [ngClass]="{
                      'low-score': obj.result <= 25,
                      'medium-score': obj.result >= 26 && obj.result <= 65,
                      'high-score': obj.result >= 66 && obj.result <= 100
                    }">
                  </span>
                } @else {
                  <span> {{ obj.result | hindiNumber }}%</span>
                }
              }
            </span>
          </div>
          @for (subcatObj of obj.subcats; track $index) {
            <div class="subcat-card black-font flex-cent flex-col">
              <!-- Subcat Name -->
              <span class="subcat-heading">{{subcatObj['short_name'] + " "}}{{
                subcatObj.hasOwnProperty('subcat_name_hi')
                  ? subcatObj.subcat_name_hi
                  : subcatObj.subcat_name
              }}</span>
              <!-- Progress bar  -->
              @for (compentency of subcatObj.compentencies; track $index) {
                <div class="compentencyDiv">
  
                  <span class="subcat-heading">{{compentency['short_name'] + " "}}{{
                      compentency.hasOwnProperty('subcat_name_hi')
                        ? compentency.comp_name
                        : compentency.comp_name
                    }}</span>
  
                  <div class="progressBarDiv">
                    <div
                      class="progressBar"
                      [style.width.%]="compentency['result']"
                      [ngClass]="{
                        Yellow:
                          compentency['result'] >= 26 &&
                          compentency['result'] <= 65,
                        Red: compentency['result'] <= 25,
                        Green: compentency['result'] >= 66
                      }"></div>
                    @if (compentency['result'] >= 90) {
                      <img
                        class="star"
                        src="../../../../../assets/img/star.png"
                        alt="" />
                    }
                    @if (isMasked !== 'Yes') {
                      <span class="score"
                        >{{ compentency.result | hindiNumber }}%</span
                      >
                    }
                  </div>
  
                  <div class="milestones">
                    <ul class="milestone-list">
                      @for (milestone of compentency.milestone; track $index) {
                        <li class="milestoneElement">
                          <span class="milestone-text">{{
                            milestone.milestone
                          }}</span>
                          <!-- Conditionally display the icon based on the status -->
                          <img
                            [src]="getIconForStatus(milestone.status)"
                            alt="Status icon"
                            class="milestone-icon" />
                        </li>
                      }
                    </ul>
                  </div>
                  <!-- remark section -->
                  @if (compentency.remarks) {
                    <div class="remark">
                      <span>Teacher's Remark</span>
                      <span id="remark" class="teacher-remark">{{
                        compentency.remarks
                      }}</span>
                    </div>
                  } @else {
                    <!-- if the remark is not given to the child -->
                    <div class="remark">
                      <span id="remark1" class="teacher-remark">{{
                        getReMark(compentency.result, compentency.subcat_name)
                      }}</span>
                    </div>
                  }
  
                  @if (compentency.images) {
                    <div class="imageContainer flex-cent flex-col">
                      <span class="movement">{{
                        'portfolio.movement' | translate
                      }}</span>
                      <div class="outerDivImg d-flex align-center just-start">
                        @for (
                          img of getParse(compentency.images);
                          let idx = $index;
                          track idx
                        ) {
                          <div
                            class="ChildimageDiv d-flex align-center just-cent">
                            <img
                              class="childPhoto"
                              [src]="img"
                              alt="childPhoto" />
                          </div>
                        }
                      </div>
                    </div>
                  } @else {
                    <div class="imageContainer">
                      <!-- <span>{{ 'portfolio.notUploaded' | translate}}</span> -->
                    </div>
                  }
                </div>
              }
            </div>
          }
        </div>
      </section>
    }
  </section>
</section>


<app-footer-navbar [class_id]="classId"></app-footer-navbar>