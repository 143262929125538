// @ts-ignore
// @ts-nocheck

import { Injectable } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5percent from '@amcharts/amcharts5/percent';

import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

@Injectable({
  providedIn: 'root',
})
export class StackGraphService {
  constructor() {}

  createStackGraph(graphId: string, graphData: object[], type: string) {
    // Create root element
    let root = am5.Root.new(graphId);

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        paddingLeft: 0,
        layout: root.verticalLayout,
      })
    );

    if (root._logo) {
      root._logo.dispose();
    }
    // Add scrollbar
    chart.set(
      'scrollbarX',
      am5.Scrollbar.new(root, { orientation: 'horizontal' })
    );

    // Fix: Replace 0 values with a small value (0.001)
    // data.forEach(item => {
    //   item.completed = item.completed === 0 ? 0.001 : item.completed;
    //   item.pending = item.pending === 0 ? 0.001 : item.pending;
    //   item.notStarted = item.notStarted === 0 ? 0.001 : item.notStarted;
    // });

    // Create X-axis
    let xRenderer = am5xy.AxisRendererX.new(root, {
      minorGridEnabled: true,
      oversizedBehavior: 'truncate',
    });
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: 'category',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );
    xRenderer.grid.template.setAll({ location: 1 });

    xAxis.get('renderer').labels.template.setAll({
      oversizedBehavior: 'wrap',
      maxWidth: 150,
      textAlign: 'center',
    });

    xAxis.data.setAll(graphData);

    // Create Y-axis (Fix: Ensure zero values are plotted)
    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        strictMinMax: false, // Allow auto-scaling
        numberFormat: '#',
        renderer: am5xy.AxisRendererY.new(root, { strokeOpacity: 0.1 }),
      })
    );

    // Add legend
    var legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );

    // Function to create series (for actual counts)
    function makeSeries(name: string, fieldName: string, color: string) {
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          stacked: true,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: 'category',
        })
      );

      series.columns.template.setAll({
        fill: am5.color(color),
        tooltipText:
          '{name}, {categoryX}: {valueY} subcategories\nTotal: {total_subcats}',
        tooltipY: am5.percent(10),
      });

      series.data.setAll(graphData);

      series.appear();

      series.bullets.push(function (root, series, dataItem) {
        if (dataItem.dataContext[fieldName] > 1) {
          return am5.Bullet.new(root, {
            sprite: am5.Label.new(root, {
              text: '{valueY}',
              fill: root.interfaceColors.get('alternativeText'),
              centerY: am5.p50,
              centerX: am5.p50,
              populateText: true,
              visible: true,
            }),
          });
        }
      });

      legend.data.push(series);
    }

    // Create series for completion statuses (actual counts)
    makeSeries('Completed','completed', '#19f105'); // Green
    makeSeries('Pending', 'pending', '#f7be06'); // Yellow
    makeSeries('Not Started', 'notStarted', '#ccc7b1'); // Red

    // Make chart animate on load 
    chart.appear(1000, 100);
  }
}
