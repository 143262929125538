// @ts-ignore
// @ts-nocheck

import { Injectable } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5percent from '@amcharts/amcharts5/percent';

import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

@Injectable({
  providedIn: 'root',
})
export class PieChartService {
  constructor() {}

  createPieChart(
    chartId: string,
    percentageData: { [key: string]: number | string },
    type: string,
    radius: number = 0
  ) {
    console.log('percentageData', percentageData);

    let root = am5.Root.new(chartId);

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    if (root._logo) {
      root._logo.dispose();
    }
    // Create chart
    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        innerRadius: type === 'donut' && radius ? am5.percent(radius) : 0, // Apply radius only for "donut"
      })
    );

    // Create series
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: 'value',
        categoryField: 'category',
        alignLabels: false,
      })
    );

    series.get('colors')!.set(
      'colors',
      percentageData.map(d => d.color)
    );

    series.labels.template.adapters.add('visible', (visible, target) => {
      return target.dataItem?.get('value') > 0;
    });

    series.labels.template.setAll({
      textType: 'circular',
      text: '{value}%',
      oversizedBehavior: 'truncate',
      centerX: 0,
      centerY: 0,
    });

    // Set dynamic data
    series.data.setAll(percentageData);

    // Create legend
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        marginTop: 15,
        marginBottom: 15,
        legendValueText: "{value.formatNumber('#')}%"
      })
    );

    legend.data.setAll(series.dataItems);

    // Play initial series animation
    series.appear(1000, 100);
  }
}
