import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../config/api/api.service';
import { ADMIN } from '../../../config/adminConstant';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  apiService = inject(ApiService);

  constructor() { }

  getOverViewData(schoolID : number): Observable<object> {
    return this.apiService.get(
      `${ADMIN.GET_OVERVIEW_DATA.URL}/${schoolID}`
    );
  }

  getClassRooms(schoolID : number , pageNo : number): Observable<object>{
    return this.apiService.get(
       `${ADMIN.GET_CLASSROOMS.URL}/${schoolID}/${pageNo}`
    );
  }

}
