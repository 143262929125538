import { Component } from '@angular/core';

@Component({
  selector: 'app-list-classrooms',
  standalone: true,
  imports: [],
  templateUrl: './list-classrooms.component.html',
  styleUrl: './list-classrooms.component.css'
})
export class ListClassroomsComponent {

}
