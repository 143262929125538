import { Component, inject } from '@angular/core';
import { TableComponent } from '../../../components/table/table.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { StudentService } from '../../../utils/api/users/student.service';
import { Store } from '@ngrx/store';
import { selectUserObject } from '../../../../state/user/user.selector';

@Component({
  selector: 'admin-students',
  standalone: true,
  imports: [TableComponent,ReactiveFormsModule],
  templateUrl: './studetns.component.html',
  styleUrl: './studetns.component.css'
})
export class StudetnsComponent {
  studentService = inject(StudentService);
  store = inject(Store);

  pageSizeControl = new FormControl(10); // Default value
  paginatedStudents: any[] = [];
  currentPage: number = 1;
  pageSize: number = 10;
  totalPages: number = 0;
  pageSizes: number[] = [5, 10, 25, 50];

  currentIndex : number = (this.currentPage - 1) * this.pageSize;
  tableHeaders: string[] = [
    'Student Name',
    'Gender',
    'Birth Date',
    'Age',
    'Parent Name',
    'Parent Email',
    'Parent Mobile',
    'Standard',
    'Division',
  ];
  columnKeys: string[] = [
    'child_name',
    'child_gender',
    'birthDate',
    'age',
    'parents_name',
    'user_email',
    'user_mobile',
    'standard',
    'division'
  ]
  schoolId: number = 2;
  userName: string = '';
  userObject$: any;
  constructor() {}

  ngOnInit(): void {
    this.userObject$ = this.store.select(selectUserObject);
    
        this.userObject$.subscribe({
          next: (data: { firstName: string; lastName: string , school_id :any }) => {
            console.log('userData', data);
            this.userName = data.firstName + ' ' + data.lastName;
            this.schoolId = Number(data.school_id);

          },
          error: (err: any) => {
            console.error('Error:', err);
          },
          complete: () => {
            console.log('Subscription completed');
          },
        });

    this.pageSizeControl.valueChanges.subscribe(size => {
      this.pageSize = size || 5;
      this.loadTeacherData(this.currentPage);
    });

    this.loadTeacherData(this.currentPage);
  }

  loadTeacherData(pageNo: number): void {
    if (typeof window !== 'undefined' && window.localStorage) {

    this.studentService
      .getStudentList(this.schoolId, pageNo, this.pageSize)
      .subscribe({
        next: (response: any) => {
          this.currentIndex = (this.currentPage - 1) * this.pageSize;

          this.paginatedStudents = response.data.records || [];
          this.totalPages = Math.ceil(response.data.total / this.pageSize);
        },
        error: error => {
          console.error('Error fetching teacher list:', error);
        },
      });
    }
  }

  goToPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.loadTeacherData(page);
    }
  }
}
