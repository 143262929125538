import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,

  inject,
  OnDestroy,
} from '@angular/core';
import { DashboardService } from '../../../utils/api/dashboard.service';
import { Store } from '@ngrx/store';
import { setUserObject } from '../../../../state/user/user.actions';
import { selectUserObject } from '../../../../state/user/user.selector';
import { user } from '../../../../app/shared/interfaces/admin/common';
import { Observable } from 'rxjs';
import { GraphService } from '../../../../service/graph/graph.service';
import { StackGraphService } from '../../../../service/graph/stack-graph.service';
import { PieChartService } from '../../../../service/graph/pie-chart.service';
import { CommonModule } from '@angular/common';
import { utilityService } from '../../../utils/common/common.service';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'admin-dashboard',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css',
})
export class AdminDashboardComponent implements AfterViewInit, OnDestroy {
  dahboardService = inject(DashboardService);
  store = inject(Store);
  graphService = inject(GraphService);
  private cdRef = inject(ChangeDetectorRef);
  utils = inject(utilityService);
  stackGraph = inject(StackGraphService);
  pieChart = inject(PieChartService);



  userName: string = 'Ms. Payal Patil';
  userObject$: Observable<user> | undefined;
  classes: any = [];
  schoolId: number = 0;
  totalClasses: number = 0;
  totalStudents: number = 0;
  assessmentPeriodName: string = 'Term';
  assessmentPeriodEndDate: string | null | undefined;
  assessmentPeriodStartDate: string | null | undefined;
  assessmentPeriodStatus: string = 'In Progress';
  overAllResult: number = 0;
  showOverAllResult : boolean = false;
  catPercentageArr: object[] = [];
  showPercentageGraph: boolean = true;
  imageAlt: string = 'NA';
  stackData : object[] = [];
  pieChartData: any;

  constructor() {
   
  }

  ngOnInit() {
    console.log("initilze");
    this.userObject$ = this.store.select(selectUserObject);

    this.userObject$.subscribe({
      next: data => {
        console.log('userData', data);
        this.userName = data.firstName + " " + data.lastName;
        this.schoolId = Number(data.school_id);
      },
      error: err => {
        console.error('Error:', err);
      },
      complete: () => {
        console.log('Subscription completed');
      },
    });
  }

  ngAfterViewInit() {
    if (typeof window !== 'undefined' && window.localStorage) {

    this.dahboardService.getOverViewData(this.schoolId).subscribe({
      next: (res: any) => {
        let data = res.data;
        this.classes = data.classes;


        this.totalClasses = data.totalClasses;
        this.totalStudents = data.totalStudents;
        this.stackData = data.classRoomWiseCatAndSubCatStats.catDeta;
        this.pieChartData = data.percentage;
        this.catPercentageArr = data.catWisePercentage;
        this.showPercentageGraph =
          this.catPercentageArr.length === 0 ? false : true;
       

        if (data.schoolAssessmentPeriodId[0].assessment_period_id) {
          
          
          this.assessmentPeriodEndDate = new Date(data.schoolAssessmentPeriodId[0].end_date).toISOString().split('T')[0];
          this.assessmentPeriodStartDate = new Date(data.schoolAssessmentPeriodId[0].start_date).toISOString().split('T')[0];

          this.assessmentPeriodName =   data.schoolAssessmentPeriodId[0].period_name;
           this.assessmentPeriodStatus = 'In Progress';
        } else {
        
          this.assessmentPeriodEndDate = new Date(data.schoolAssessmentPeriodId[0].previous_end_date).toISOString().split('T')[0];
          this.assessmentPeriodStartDate = new Date(data.schoolAssessmentPeriodId[0].previous_start_date).toISOString().split('T')[0];

          this.assessmentPeriodName = data.schoolAssessmentPeriodId[0].previous_period_name;
          this.assessmentPeriodStatus = 'Completed';
        }

        if(data.overAllSchoolResult !== 0 ){
          this.overAllResult = data.overAllSchoolResult;
          this.showOverAllResult = true;
        }
       
        this.cdRef.detectChanges();


        setTimeout(() => {
          try {
            let donutColor : number = 0;
            if(data.overAllSchoolResult !== 0 ){
              this.overAllResult = data.overAllSchoolResult;
              this.showOverAllResult = true;
              
    
              if (this.overAllResult  >= 0 && this.overAllResult  <= 25) {
                donutColor = 0xFBAF55; // light orange
              } else if (this.overAllResult  >= 26 && this.overAllResult  <= 65) {
                donutColor = 0xFCF969; // yellow
              } else if (this.overAllResult  >= 66 && this.overAllResult  <= 100) {
                donutColor = 0xA9FA68; // green
              }
    
              this.graphService.createPieChart('chartID',this.overAllResult, donutColor)
            }

            this.graphService.createVerticleBar(
              'percentageCatBar',
              this.catPercentageArr
            );

            this.stackGraph.createStackGraph("subcatCountGraph",this.stackData,'nothing');


            this.pieChart.createPieChart("totalPercentagePiechartStatus",this.pieChartData,'overAllStatus', 0)
            
            // for (const item of this.classes) {
            //   let donutColor: number = 0;
    
            //   if (item.overAllResult >= 0 && item.overAllResult <= 25) {
            //     donutColor = 0xfbaf55;
            //   } else if (item.overAllResult >= 26 && item.overAllResult <= 65) {
            //     donutColor = 0xfcf969;
            //   } else if (item.overAllResult >= 66 && item.overAllResult <= 100) {
            //     donutColor = 0xa9fa68;
            //   } else {
            //     console.warn(
            //       `Invalid overAllResult value for class ${item.class_id}:`,
            //       item.overAllResult
            //     );
            //     continue;
            //   }
    
         
            //   const chartElement = document.getElementById(`chart${item.class_id}`);
            //   if (chartElement) {
            //     this.graphService.createDonutChart(
            //       `chart${item.class_id}`,
            //       item.overAllResult,
            //       donutColor,
            //       'centerLogo'
            //     );
            //   } else {
            //     console.error(`Element not found for id: chart${item.class_id}`);
            //   }
            // }
            
          } catch (error) {
            console.error("error",error);
          }
        }, 0);

      },
      error: (error: string) => {
        console.error('Error:', error);
      },
    });
  }
  }

  ngOnDestroy() {
    // this.graphService.removeGraphs();
  }
  
  imageAltFunction(teacherName : string ) : string{
    return this.utils.getInitials(teacherName);
   }
}
