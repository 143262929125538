<div id="classRoom">
  <section id="componentHeader d-flex just-between align-center">
    <div class="d-flex just-start align-center">
      <div id="componentTitle">
        <span>CLASSROOMS</span>
      </div>
      <div id="teacherName">
        <span>Welcome, </span><span>{{ userName }}</span>
      </div>
    </div>
  </section>

  <section class="classRoomsHeader">
    <div class="bestPerforming commonCssForBorder">
      <span class="cardsHeadings">Best Performing Class</span>
      <div class="classNameDiv d-flex align-center just-around">
        <span class="bestClass">{{standard}}</span>
        <span class="bestClass">- {{division}}</span>
      </div>
    </div>
    <div class="OverAll commonCssForBorder">
      <span class="cardsHeadings"
        >Overall Classes Assessments Completion Status</span
      >
      <div class="overAllStats">
        <div class="buttonDiv">
          <span class="comButton buttonSpan">Completed</span>
          <span> {{completed}}%</span>
        </div>
        <div class="buttonDiv">
          <span class="inButton buttonSpan">Pending</span>
          <span>{{pending}}%</span>
        </div>
        <div class="buttonDiv">
          <span class="penButton buttonSpan">Not Started</span>
          <span>{{inProgress}}%</span>
        </div>
      </div>
    </div>
    <div class="ReamrkClassTeachersDiv commonCssForBorder">
      <span class="cardsHeadings">Remark for all Class Teachers</span>
      <div class="textareaBox d-flex just-center align-center">
        <textarea
          name="textarea"
          class="textAreaField"
          placeholder="Your remark here ..."
          id=""></textarea>
        <button class="sendButton">
          <img src="../../../assets/img/Ellipse.svg" alt="" />
        </button>
      </div>
    </div>
  </section>

  <section id="activeClasses">
    <div class="activeClassDivHeader">
      <div><span class="activeClassesLabel">Active Classes</span></div>
      <!-- <div class="filter">
        <select name="" id="status-select" (change)="onFilterSelect($event)">
          <option value="Active" selected>Active</option>
          <option value="In Active">In Active</option>
        </select>        
      </div> -->
    </div>
    <div class="classesSpace">
      @for (item of classes; track $index) {
        <div class="classDiv">
          <div class="classheader">
            <span class="className"
              >{{ item.standard }} - {{ item.division }}</span
            >
            <span class="tacherName">{{ item.teacher_name }}</span>
            <div class="profileImgae">
              @if (item.teacher_image) {
                <img src="" srcset="" />
              } @else {
                <span>{{ imageAltFunction(item.teacher_name) }}</span>
              }
            </div>
          </div>
          <div class="classBody">
            <div class="avgResult">
              <span>Results Avg.</span>
              @if (item.overAllResult === 0) {
                <img
                  src="../../../assets/img/incomplete_category.png"
                  class="noResult"
                  alt="" />
              } @else {
                <div class="charts" [id]="'classCharts' + item.class_id"></div>
              }
            </div>

            <div class="statusDiv">
              <span class="statusLabel">Assessments Status</span>
              <div class="labelDiv d-flex">
                <div class="greenLabel"></div>
                @if (item.percentages.completed !== 0) {
                  <span>{{ item.percentages.completed }}%</span>
                } @else {
                  <span> - </span>
                }
              </div>

              <div class="labelDiv d-flex">
                <div class="orangeLabel"></div>
                @if (item.percentages.pending !== 0) {
                  <span>{{ item.percentages.pending }}%</span>
                } @else {
                  <span> - </span>
                }
              </div>

              <div class="labelDiv d-flex">
                <div class="grayLabel"></div>
                @if (item.percentages.notStarted !== 0) {
                  <span>{{ item.percentages.notStarted }}%</span>
                } @else {
                  <span> - </span>
                }
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  </section>
</div>
