import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { RouterModule,Router } from '@angular/router';
import { AuthService } from '../../../service/auth/auth.service';
import { Roles } from '../../../service/interfaces';
/** TYPE INTERFACES IMPORT  */
import { MenuItem } from '../interfaces';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.css',
})
export class SidebarComponent {
  private authService = inject(AuthService)
  private router = inject(Router);

  @Input() isSidebarCollapsed = false;
  @Output() sidebarToggle = new EventEmitter<void>();
  @Output() changeComponent = new EventEmitter<string>();
  selectedRoute: string = 'Dashboard';
  menuItems: MenuItem[] = [
    {
      icon: '../../../assets/img/dashboard-24.png',
      label: 'Dashboard',
      route: '/admin/dashboard',
      roles: [Roles.Admin, Roles.Manager, Roles.Teacher],
    },
    {
      icon: '../../../assets/img/classroom-teacher-24.png',
      label: 'Classrooms',
      route: '/admin/classroom',
      roles: [Roles.Admin, Roles.Manager],
    },
    {
      icon: '../../../assets/img/icons8-talent-24.png',
      label: 'Milestones',
      route: '/admin/milestone',
      roles: [Roles.Admin, Roles.Teacher],
    },
    {
      icon: '../../../assets/img/classroom-teacher-24.png',
      label: 'Teachers',
      route: '/admin/teacher',
      roles: [Roles.Admin],
    },
    {
      icon: '../../../assets/img/students-24.png',
      label: 'Students',
      route: '/admin/student',
      roles: [Roles.Admin, Roles.Manager],
    },
    {
      icon: '../../../assets/img/reminder-24.png',
      label: 'Reminders',
      route: '/admin/reminder',
      roles: [Roles.Admin, Roles.Teacher],
    },
    // {
    //   icon: '../../../assets/img/settings-24.png',
    //   label: 'Settings',
    //   route: '/admin/settings',
    //   roles: [Roles.Admin], 
    // },
    // {
    //   icon: '../../../assets/img/dashboard-24.png',
    //   label: 'Dashboard',
    //   route: '/superadmin/dashboard',
    //   roles: [Roles.SA], 
    // },
    {
      icon: '../../../assets/img/classroom-teacher-24.png',
      label: 'Providers',
      route: '/superadmin/service-providers',
      roles: [Roles.SA], 

    },
    {
      icon: '../../../assets/img/icons8-talent-24.png',
      label: 'Milestones',
      route: '/superadmin/milestone',
      roles: [Roles.SA],
    },
   {
      icon: '../../../assets/img/classroom-teacher-24.png',
      label: 'Teachers',
      route: '/superadmin/teacher',
      roles: [Roles.SA], 
    }
  ];

  filteredMenuItems: MenuItem[] = [];

  

  ngOnInit() {
    if (typeof window !== 'undefined' && window.localStorage) {
      const role = this.authService.getRole();
      this.filteredMenuItems = this.menuItems.filter(item => item.roles.includes(role));
      // this.selectedRoute = sessionStorage.getItem('selectedTab') || 'Dashboard';
    }
  }

  toggleSidebar() {
    this.sidebarToggle.emit();
  }

  selectComponent(componentLabel: string) {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.selectedRoute = componentLabel;
      sessionStorage.setItem('selectedTab', this.selectedRoute);
      this.changeComponent.emit(componentLabel);
      // window.location.hash = componentLabel.toLowerCase();
    }
  }

  logout(): void {
    sessionStorage.clear();
    this.router.navigate(['/login'])
  }

  toggleMenuItem(item: MenuItem) {
    // Only toggle if sidebar is not collapsed and item has children
    if (!this .isSidebarCollapsed) {
      item.isOpen = !item.isOpen;
    }
  }
}
