import { Component } from '@angular/core';

@Component({
  selector: 'app-list-schools',
  standalone: true,
  imports: [],
  templateUrl: './list-schools.component.html',
  styleUrl: './list-schools.component.css'
})
export class ListSchoolsComponent {

}
