import { inject, Injectable } from '@angular/core';
import { ApiService } from '../../../../config/api/api.service';
import { SUPERADMIN } from '../../../../config/adminConstant';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LearningOutcomeService {
  private apiService = inject(ApiService);

  constructor() { }


  getLearningOutcomeList(id : any) : Observable<object> {
    return this.apiService.get(`${SUPERADMIN.GET_LEARNING_OUTCOMES_BY_COMPENTENCY_ID.URL}/${id}`);
  } 

  addLearningOutcome(id : any , data : any) : Observable<object>{
    data.competencyId = id;
    return this.apiService.post(`${SUPERADMIN.LEARNING_OUTCOME.URL}`,data);
  }
}
