<div id="adminDashboard">
  <section id="componentHeader" class="d-flex align-center just-start">
      <div id="componentTitle">
        <div>DASHBOARD</div>
      </div>
      <div id="teacherName">
        <span>Welcome, </span><span>{{ userName }}</span>
      </div>
   
  </section>

  <section id="statDiv">
    <div class="firstDiv">
      <div class="topRow d-flex">
        <div class="statCard just-start">
          <span class="headingCard">Total Classes</span>
          <span class="centerValue">{{ totalClasses }}</span>
        </div>
        <div class="statCard just-start">
          <span class="headingCard">Total Students</span>
          <span class="centerValue">{{ totalStudents }}</span>
        </div>
      </div>
      <div class="bottomRow d-flex">
        <div class="statCard just-start">
          <span class="headingCard">Assessment Period</span>
          <div
            class="centerValue d-flex align-center just-center flex-col assessmeentperiod">
            <span class="term"
              >{{ assessmentPeriodName || 'Term' }}
              <span class="assessmeentperiod"
              [ngClass]="{
                'status-completed': assessmentPeriodStatus === 'Completed',
                'status-in-progress': assessmentPeriodStatus === 'In Progress'
              }"
              >
                {{ assessmentPeriodStatus }}</span
              >
            </span>
            <span class="termDates"
              >{{ assessmentPeriodStartDate }} To {{ assessmentPeriodEndDate }}</span>
          </div>
        </div>
        <div class="statCard overAllResultCard just-start">
          <span class="headingCard">Grand School Result</span>
          @if (overAllResult !== 0) {
            <div class="classOverAllResult">
              <div id="chartID"></div>
              <span class="resultPercentage">{{overAllResult}}%</span>
            </div>
          } @else {
            <span class="centerValue"> - </span>
          }
        </div>
      </div>
    </div>
    <div class="secondGraphDiv">
      <span class="graphHeading">Overall School Category-wise Result</span>
      @if (showPercentageGraph) {
        <div id="percentageCatBar"></div>
      } @else {
        <span>No Data is aviable yet</span>
      }
    </div>
  </section>
  <section id="activeClasses">
    <div class="activeClassDivHeader">
      <p class="graphHeading">Total Milestones Category-wise Count</p>
      <div id="subcatCountGraph" class="stackGraph"></div>
    </div>

    <div class="totalpercentageGraphDiv">
      <p class="graphHeading">Total Milestones Sub-Category-wise Status</p>

      <div id="totalPercentagePiechartStatus"></div>

    </div>
  </section>
</div>
