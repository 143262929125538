<app-navbar
  [teacherName]="teacherName"
  [noBack]="true"
  [navbarDisplay]="navbarDisplay"></app-navbar>
<div class="parent-container black-font">
  <div class="container flex-cent flex-col">
    <div class="header-card ">
      <h2>{{ 'dashboard.title' | translate }}</h2>
    </div>

    <!-- classroom option-->
    <div
      class="containerOptions d-flex just-between align-center"
      (click)="goToClassroom()"
      role="presentation">
      <span>{{ standard }} - {{ division }}</span>
      <img
        class="dashboard-icons"
        src="../../../../assets/img/yourClassroom.png"
        alt="" />
    </div>

    <!-- other classrooms option -->
    <div class="containerOptions d-flex just-between align-center">
      <!-- <label>Other Classsrooms</label> -->
      <select id="select" class="other-classroom form-select form-select-lg">
        <option selected disabled="">
          {{ 'dashboard.otherClassroom' | translate }}
        </option>
        @for (otherClass of otherClassrooms; track otherClass; let i = $index) {
          <option [value]="otherClass.class_id">
            {{ otherClassrooms[i].standard }}-{{ otherClassrooms[i].division }}
          </option>
        }
      </select>
      <!-- <img class="dashboard-icons" src="../../../../assets/img/otherClassrooms.svg" alt=""> -->
    </div>

    <!-- connect with parents option -->
    <div
      class="containerOptions d-flex just-between align-center"
      (click)="goToConnectWithParents()"
      role="presentation">
      <span>{{ 'dashboard.connectWithParent' | translate }}</span>
      <img
        class="dashboard-icons"
        src="../../../../assets/img/parents.png"
        loading="lazy"
        alt="" />
    </div>

    <!-- overall class results on dashboard -->
    <div class="results-container flex-col">
      <div id="termText" class="d-flex just-between">
        <div class="d-flex just-between flex-col">
          <span class="term-name">{{ term || previous_period_name }}</span>
          @if (term && term != '') {
            <span class="date-range">{{ startDate }} - {{ endDate }}</span>
          }
        </div>
        <div id="progess">
          <span class="">{{  termProgress | translate}}</span>
        </div>
      </div>
      <!-- <span class="results-head ">{{'dashboard.classroomResult' | translate}}</span> -->

      <div class="chart-content d-flex just-around align-center">
        <!-- view gray chart if no assessments taken  -->

        @if (NoResults === true) {
          <div class="noResults">
            <img
              calss="noResultsImg"
              src="../../../../assets/img/incomplete_category.png"
              alt="NOT Started" />
          </div>
        }

        <!-- view peichart as per the average of the assessments completed of whole class -->
        @if (NoResults === false) {
          <div class="overallResultPie">
            <div class="peichart" id="chartdiv"></div>
          </div>
        }
        @if (classResult > 0) {
        @if (isMasked === 'Yes') {
          <div class="maskImage" 
               [ngClass]="{
                  'low-score': classResult <= 25,
                  'medium-score': classResult >=26 && classResult <=65,
                  'high-score': classResult >= 66 && classResult <=100
               }">
          </div>
            <!-- <img src="../../../../assets/img/buterfly.png" alt=""> -->

        
        }@else {
          <div class="average flex-cent flex-col">
          <p>{{ 'dashboard.currnetAverage' | translate }}</p>
          <h3 class="avg professional-blue-font">{{ classResult | hindiNumber }}%</h3>
        </div>
        }
        }
        
      </div>
    </div>
  </div>
  <app-footer-navbar [class_id]="class_id"></app-footer-navbar>
</div>
