export interface StudentData {
  studentName: string;
  age: string;
  catId: number;
  catName: string;
  classId: number;
  studentId: number;
  dobYear: number;
  dobMonth: number;
  dobDate: number;
  schoolName: string;
  totalStudents: number;
  standard: string;
  division: string;
  teacherName: string;
  shortCatName: string;
}

export const defaultStudentData: StudentData = {
  studentName: '',
  age: '',
  catId: 0,
  catName: '',
  classId: 0,
  studentId: 0,
  dobYear: 0,
  dobMonth: 0,
  dobDate: 0,
  schoolName: '',
  totalStudents: 0,
  standard: '',
  division: '',
  teacherName: '',
  shortCatName: 'string',
};

export interface StudentState {
  studentData: StudentData;
}

export const initialState: StudentState = {
  studentData: defaultStudentData,
};
