import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class utilityService {

  constructor() { }

  getInitials(name : string) : string {
    // Split the name into parts and removing the spaces from start and end
    const parts = name.trim().split(/\s+/);
  
    // Assume surname is the last word and first name is the second word after a title (if present)
    const surnameInitial = parts.length >= 2 ? parts[parts.length - 1][0].toUpperCase() : '';
    const firstNameInitial = parts.length >= 2 ? parts[1][0].toUpperCase() : '';
  
    return `${firstNameInitial}${surnameInitial}`;
  }  
}
