<app-navbar [navbarDisplay]="navbarDisplay" [class_id]="class_id" [mode]="mode" [goBackTo]="goBackTo"></app-navbar>

<div class="parent-container flex-cent flex-col">
  <div class="header-container black-font"
    [ngClass]="{'lightGreen' : averageResult >= 66 , 'lightRed' : averageResult <= 25 , 'lightYellow' : averageResult >= 26 && averageResult <= 65 , 'basicProfile' : catName === 'Basic'}">
    <div class="heading">
      <h3 class="textBold">{{catName | translate}}</h3>
    </div>
    <div class="piechart">
      <div id="chartDiv"></div>
      <div class="score">
        <!-- <h6>Avg.</h6> -->
        @if (isMasked === "Yes") {
          <span class="masked-percentage"
                [ngClass]="{
                  'low-score': averageResult <= 25,
                  'medium-score': averageResult  >= 26 && averageResult  <= 65,
                  'high-score': averageResult  >= 66 && averageResult  <= 100
                }">
                </span>
              }
          @else{
        <h4 >{{averageResult | hindiNumber}}%</h4>}
      </div> 
    </div>
  </div>

  <div class="subcat-container black-font flex-cent flex-col"
    [ngClass]="{'lightGreen' : averageResult >= 66 , 'lightRed' : averageResult <= 25 , 'lightYellow' : averageResult >= 26 && averageResult <= 65 , 'basicProfile' : catName === 'Basic'}">
    <div class="heading">
      <h5 class="textBold">{{'subcatCollated.developmentAreas' | translate}}</h5>
    </div>
    @for(obj of collatedResults; track obj){
      <div class="subcategory-card professional-blue-font flex-cent flex-col">
        <div class="subcatName">
          @if(obj.subcatName === 'Speaking'){
            <h5>{{'subcatCollated.speaking' | translate}}</h5>
          }@else{
            <h5>{{obj.subcatName}}</h5>
          }
         

        </div>
        <div class="subCat-data flex-col">
          <!-- parent-result -->
          <!-- <div class="result flex-cent">
            <img src="../../../../../assets/img/collated_parents.png" alt="">
            <div class="progress-section flex-cent flex-col">
                <h6 class="label">{{'subcatCollated.parent' | translate}}</h6>
              <div class="progress-bar flex-cent flex-row just-between">
                <div class="progress" [style.width.%]="obj.parentResult" [ngClass]="{'Yellow': obj.parentResult >= 26 && obj.parentResult <= 65 , 'Red': obj.parentResult <= 25 , 'Green': obj.parentResult >= 66}">
                  
                </div>
                @if(obj.parentResult === 0){
                  <div class="average">
                    <h6>{{'subcatCollated.pending' | translate}}</h6>
                  </div>
                } @else{
                  <div class="average">
                    @if (isMasked !== 'Yes') {
                      <h6>{{ obj.parentResult | hindiNumber }}%</h6>
                    }
                    
                  </div>
                }
              </div>
            </div>
          </div> -->
  <!-- parent-result -->
<div class="result flex-cent">
  <img src="../../../../../assets/img/collated_parents.png" alt="">
  <div class="progress-section flex-cent flex-col">
    <h6 class="label">{{'subcatCollated.parent' | translate}}</h6>
    <div class="progress-bar flex-cent flex-row just-between">
      <div class="progress" [style.width.%]="obj.parentResult" 
           [ngClass]="{
             'Yellow': obj.parentResult >= 26 && obj.parentResult <= 65,
             'Red': obj.parentResult <= 25,
             'Green': obj.parentResult >= 66
           }">
      </div>

      <!-- REPLACE this section with the updated version -->
      <div class="average">
        @if (isMasked === 'Yes' && obj.parentResult > 0) {
          <span class="masked-percentage1"
                [ngClass]="{
                  'low-score': obj.parentResult <= 25,
                  'medium-score': obj.parentResult >= 26 && obj.parentResult <= 65,
                  'high-score': obj.parentResult >= 66 && obj.parentResult <= 90,
                  'highest-score': obj.parentResult >= 91 && obj.parentResult <= 100
                }">
            @if (obj.parentResult <= 25) { {{ 'summaryReport.Emerging' | translate }} }
            @else if (obj.parentResult >= 26 && obj.parentResult <= 65) { {{ 'summaryReport.Developing' | translate }} }
            @else if (obj.parentResult >= 66 && obj.parentResult <= 89) { {{ 'summaryReport.Proficient' | translate }} }
            @else if (obj.parentResult >= 90) { {{ 'summaryReport.Mastery' | translate }} }
          </span>
        }
        @else {
          <h6>{{ obj.parentResult | hindiNumber }}%</h6>
        }
      </div>
    </div>
  </div>
</div>

          <!-- teacher-result -->
          
          <div class="result flex-cent">
            <img src="../../../../../assets/img/teacher.png" alt="">
            <div class="progress-section flex-cent flex-col">
              <h6 class="label">Teacher</h6>
              <div class="progress-bar flex-cent flex-row just-between">
                <div class="progress" [style.width.%]="obj.teacherResult" 
                     [ngClass]="{
                       'Yellow': obj.teacherResult >= 26 && obj.teacherResult <= 65, 
                       'Red': obj.teacherResult <= 25, 
                       'Green': obj.teacherResult >= 66
                     }">
                </div>
          
                <div class="average">
                  @if (obj.teacherResult === 0) {
                    <h6>{{ 'subcatCollated.pending' | translate }}</h6>
                  } 
                  @else {
                    @if (isMasked === 'Yes') {
                      <span class="masked-percentage1"
                            [ngClass]="{
                              'low-score': obj.teacherResult <= 25,
                              'medium-score': obj.teacherResult >= 26 && obj.teacherResult <= 65,
                              'high-score': obj.teacherResult >= 66 && obj.teacherResult <= 90,
                              'highest-score': obj.teacherResult >= 91 && obj.teacherResult <= 100
                            }"> 
                        @if (obj.teacherResult <= 25) { {{ 'summaryReport.Emerging' | translate }} }
                        @else if (obj.teacherResult >= 26 && obj.teacherResult <= 65) { {{ 'summaryReport.Developing' | translate }} }
                        @else if (obj.teacherResult >= 66 && obj.teacherResult <= 89) { {{ 'summaryReport.Proficient' | translate }} }
                        @else if (obj.teacherResult >= 90) { {{ 'summaryReport.Mastery' | translate }} }  
                      </span>
                    }
                    @else {
                      <h6>{{ obj.teacherResult | hindiNumber }}%</h6>
                    }
                  }
                </div>
              </div>
            </div>
          </div>
          
          
  
          <!-- average-result -->
          <div class="result flex-cent">
            <img src="../../../../../assets/img/average.png" alt="">
            <div class="progress-section flex-cent flex-col">
              <h6 class="label">Average</h6>
              <div class="progress-bar flex-cent flex-row just-between">
                <div class="progress colAvgColor" [style.width.%]="obj.average"></div>
                
                <div class="average">
                  @if (obj.average === 0) {
                    <h6>{{ 'subcatCollated.pending' | translate }}</h6>
                  } 
                  @else {
                    @if (isMasked === 'Yes') {
                      <span class="masked-percentage1"
                            [ngClass]="{
                              'low-score': obj.average <= 25,
                              'medium-score': obj.average >= 26 && obj.average <= 65,
                              'high-score': obj.average >= 66 && obj.average <= 90,
                              'highest-score': obj.average >= 91 && obj.average <= 100
                            }"> 
                        @if (obj.average <= 25) { {{ 'summaryReport.Emerging' | translate }}}
                        @else if (obj.average >= 26 && obj.average <= 65) { {{ 'summaryReport.Developing' | translate }}}
                        @else if (obj.average >= 66 && obj.average <= 89) { {{ 'summaryReport.Proficient' | translate }} }
                        @else if (obj.average >= 90) { {{ 'summaryReport.Mastery' | translate }} }  
                      </span>
                    }
                    @else {
                      <h6>{{ obj.average | hindiNumber }}%</h6>
                    }
                  }
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    }

  </div>

</div>

<app-footer-navbar [class_id]="class_id"></app-footer-navbar>