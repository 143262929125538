import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../../../../config/api/api.service';
import { SUPERADMIN } from '../../../../config/adminConstant';

@Injectable({
  providedIn: 'root',
})
export class CurriculumGoalsService {
  private apiService = inject(ApiService);
  constructor() {}

  getCurriculumGoalsBYDomainId(id: any): Observable<object> {
    return this.apiService.get(`${SUPERADMIN.GET_CURRICULUM_GOALS.URL}/${id}`);
  }

  getCurriculumById(id: any): Observable<object> {
    return this.apiService.get(
      `${SUPERADMIN.GET_CURRICULUM_GOALS_BY_ID.URL}/${id}`
    );
  }

  updateCurriculumGoal(id: any, data: any): Observable<object> {
    return this.apiService.put(
      `${SUPERADMIN.POST_CURRICULUM_GOALS.URL}/${id}`,
      data
    );
  }

  createCurriculumGoal(domainId : number , data: any): Observable<object> {
    data.domainId = domainId;
    return this.apiService.post(
      `${SUPERADMIN.POST_CURRICULUM_GOALS.URL}`,
      data
    );
  }
}
