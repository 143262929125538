import { Component, EventEmitter, inject, Input, Output, output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'table-component',
  standalone: true,
  imports: [],
  templateUrl: './table.component.html',
  styleUrl: './table.component.css',
})
export class TableComponent {
  private router = inject(Router);

  @Input() parentComponent: string = '';
  @Input() columns: string[] = [];
  @Input() data: any[] = [];
  @Input() headerNames: any[] = [];
  @Input() addPageSize: number = 10;

  @Output() rowClick = new EventEmitter<any>();

  constructor() {}

  onRowClick(row: any) {

    this.rowClick.emit(row);
      // switch (this.parentComponent) {
      //   case 'sp':
      //     this.router.navigate(['/superadmin/service-providers/details']);
      //     break;
      //   case 'student':
      //     this.router.navigate(['/student-dashboard']);
      //     break;
      //   case 'teacher':
      //     this.router.navigate(['/teacher-dashboard']);
      //     break;
      //   default:
      //     this.router.navigate(['/not-found']); 
      //     break;
      // }
  }
}
