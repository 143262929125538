<div id="serviceProviderList">
  <!-- Header Section with Component Title and Logged-in User's Name -->
  <section id="componentHeader" class="d-flex align-center just-start">
    <div id="componentTitle">
      <div>NEP Domains</div>
      <!-- Displays the section title -->
    </div>
    <div id="teacherName">
      <span>Welcome, </span><span>{{ userName }}</span>
      <!-- Displays the logged-in user's name -->
    </div>
  </section>

  <!-- Table Component to Display Paginated Data -->
  <table-component
    [parentComponent]="componentName"
    [columns]="columnKeys"
    [headerNames]="tableHeaders"
    [data]="domains"
    [addPageSize]="currentIndex"
    (rowClick)="navigate($event)"></table-component>

  <!-- Add Domain Button Div -->
  <div class="buttonDiv">
    <button type="button" (click)="addDomain()">+ Add Domain</button>
  </div>
</div>
