import { Component, EventEmitter, HostListener, inject, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { user } from '../../../app/shared/interfaces/admin/common';
import { selectUserObject } from '../../../state/user/user.selector';
import { Router } from '@angular/router';
@Component({
  selector: 'horizontal-bar',
  standalone: true,
  imports: [],
  templateUrl: './horizontal-bar.component.html',
  styleUrl: './horizontal-bar.component.css',
})
export class HorizontalBarComponent {
  store = inject(Store);
  private router = inject(Router);
  @Output() changeComponent = new EventEmitter<string>();
  @Output() clickHamburgerMenu = new EventEmitter<boolean>();

  userObject$: Observable<user> | undefined;
  schoolName : string | undefined;
  showProfileMenu = false;
  showHamburgerMenu = false;
  selectedRoute: string = '';

  constructor() {
    /** Get the user Information here */
    this.userObject$ = this.store.select(selectUserObject);

    this.userObject$.subscribe({
      next: data => {
        this.schoolName = data.school_Name;

        console.log("school name",this.schoolName);
      },
      error: err => {
        console.error('Error:', err);
      },
      complete: () => {
        console.log('Subscription completed');
      },
    });
  }

  toggleProfileMenu(): void {
    this.showProfileMenu = !this.showProfileMenu;
    this.showHamburgerMenu = false;
  }

  toggleHamburgerMenu(): void {
    this.showHamburgerMenu = !this.showHamburgerMenu;
    this.clickHamburgerMenu.emit(this.showHamburgerMenu);
  }

  navigateTo(route: string): void {
    // console.log(`Navigating to ${route}`);
    if (typeof window !== 'undefined' && window.localStorage) {
      this.selectedRoute = route;
      this.changeComponent.emit(route);
      window.location.hash = route.toLowerCase();
    }
  }

  logout(): void {
    console.log('Logging out...');
    sessionStorage.clear();
    this.router.navigate(['/login'])
  }


  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event): void {
    const target = event.target as HTMLElement;
    if (!target.closest('#navEnd') && !target.closest('#HambergerMenu')) {
      this.showProfileMenu = false;
      this.showHamburgerMenu = false;
    }
  }
}
