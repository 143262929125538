<div id="serviceProviderList">
    <!-- Header Section with Component Title and Logged-in User's Name -->
    <section id="componentHeader" class="d-flex align-center just-start">
      <div id="componentTitle">
        <div>Competencies</div>
        <!-- Displays the section title -->
      </div>
      <div id="teacherName">
        <span>Welcome, </span><span>{{ userName }}</span>
        <!-- Displays the logged-in user's name -->
      </div>
    </section>
  
    <!-- Table Component to Display Paginated Data -->
    <table-component
      [parentComponent]="componentName"
      [columns]="columnKeys"
      [headerNames]="tableHeaders"
      [data]="compentencies"
      [addPageSize]="currentIndex"
      (rowClick)="navigate($event)"></table-component>
  
    <!-- Pagination Controls -->
    <div class="buttonDiv">
      <button type="button" (click)="addCompetencies()">+ Add Competency</button>
    </div>
  </div>
  